import gql from "graphql-tag";

export default gql`
  query roles($shopId: ID!) {
    roles(shopId: $shopId) {
      nodes {
        name
      }
    }
  }
`;
