import discountCodesQuery from "../graphql/queries/discountCodes";
import deleteDiscountCodeMutation from "../graphql/mutations/deleteDiscountCode";
import CRUDer from "../../../package/src/CRUDer";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";
import { constants } from "../constants.js";

const { discountMethods } = constants;

const CALCULATION_METHODS = {
  credit: "flat",
  discount: "percentage",
};

const useStyles = makeStyles(theme => ({
  declineAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
}));

/**
 * @name RatesTable
 * @returns {React.Component} A React component
 */
function RatesTable({ shopId, history }) {
  const classes = useStyles();

  const fields = [
    {
      type: "string",
      name: "label",
      label: "Label",
      isSortable: true,
    },
    {
      type: "string",
      name: "discount",
      label: "Discount Amount",
      isSortable: true,
    },
    {
      type: "string",
      name: "calculationMethod",
      label: "Calculation Method",
      isSortable: true,
    },
    {
      type: "dateRange",
      name: "validityPeriod",
      label: "Validity Period",
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CRUDer
        crud={{ state: "display" }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`sale/edit/${shopId}/${id}`);
          },
          goToCreatePage: () => {
            history.push(`sale/create/${shopId}`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Sale",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter by label",
          },
        }}
        fields={{
          display: fields,
        }}
        apis={{
          readAll: {
            gql: discountCodesQuery,
            formatInput: (filter, dateRange, sorting) => ({
              shopId,
              filters: {
                searchField: filter,
                discountMethod: discountMethods.rate,
                genericFilters: {
                  dateRange: {
                    gte: dateRange.startDate,
                    lte: dateRange.endDate,
                    field: "createdAt",
                  },
                },
                sortOrder: sorting?.sortOrder,
                sortBy: sorting?.sortBy,
              },
            }),
            formatOutput: output => ({
              nodes: output.discountCodes.nodes.map(node => {
                const startDate = node?.conditions?.order?.startDate;
                const endDate = node?.conditions?.order?.endDate;

                return {
                  ...node,
                  calculationMethod: CALCULATION_METHODS[node.calculation.method],
                  validityPeriod: {
                    startDate: startDate && new Date(startDate),
                    endDate: endDate && new Date(endDate),
                  },
                };
              }),
              totalCount: output.discountCodes.totalCount,
            }),
          },
          actions: [
            {
              label: "Delete",
              gql: deleteDiscountCodeMutation,
              formatInput: id => ({ input: { discountCodeId: id, shopId } }),
              formatOutput: output => output?.discountCode,
              shouldAppearInTable: true,
              IconComponent: DeclineIcon,
              className: classes.declineAction,
            },
          ],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
}

export default RatesTable;
