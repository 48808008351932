import React, { useState } from "react";
import { merchantRequestsQuery } from "../graphql/queries";
import { handleMerchantRequestMutation } from "../graphql/mutations";
import Displayer from "../../../package/src/CRUDer/Displayer";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import ApproveIcon from "mdi-material-ui/CheckCircleOutline";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../../../constants";
import Chip from "@material-ui/core/Chip";

const LOCAL_STORAGE_KEY = "merchant-requests-selected-states";

const REQUEST_MESSAGE = {
  START_E_COMMERCE_HOSTING: `Start hosting eCommerce on ${constants.APP_NAME}`,
  STOP_E_COMMERCE_HOSTING: `Stop hosting eCommerce on ${constants.APP_NAME}`,
  CHANGE_SHOP_CATEGORY: (oldCategory, newCategory) =>
    `Change Shop Category from [${oldCategory}] to [${newCategory}]`,
};

const useStyles = makeStyles(theme => ({
  approveAction: {
    color: theme.palette.colors.forestGreen300,
    width: "100%", // centers it in the cell
  },
  declineAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
  declinedChip: {
    backgroundColor: theme.palette.colors.red300,
    color: "white",
  },
  pendingChip: {
    backgroundColor: theme.palette.colors.reactionBlue300,
    color: "white",
  },
  approvedChip: {
    backgroundColor: theme.palette.colors.forestGreen300,
    color: "white",
  },
}));

const MerchantRequestsPage = props => {
  const { viewer } = props;
  const classes = useStyles();

  // Get the user selections from localStorage if they exist.
  // Default to "pending" otherwise.
  const selectedStatesInStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [
    constants.MERCHANT_REQUESTS.STATE.PENDING,
  ];
  const [selectedRequestStates, setSelectedRequestStates] = useState(selectedStatesInStorage);

  if (!viewer) return null;

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Merchant Requests" />
      <SelectAllAutocomplete
        items={[
          {
            label: constants.MERCHANT_REQUESTS.STATE.PENDING,
            value: constants.MERCHANT_REQUESTS.STATE.PENDING,
            selected: selectedRequestStates.includes(constants.MERCHANT_REQUESTS.STATE.PENDING),
          },
          {
            label: constants.MERCHANT_REQUESTS.STATE.APPROVED,
            value: constants.MERCHANT_REQUESTS.STATE.APPROVED,
            selected: selectedRequestStates.includes(constants.MERCHANT_REQUESTS.STATE.APPROVED),
          },
          {
            label: constants.MERCHANT_REQUESTS.STATE.DECLINED,
            value: constants.MERCHANT_REQUESTS.STATE.DECLINED,
            selected: selectedRequestStates.includes(constants.MERCHANT_REQUESTS.STATE.DECLINED),
          },
        ]}
        label="Select Status(es)"
        placeholder=""
        selectAllLabel="All Statuses"
        onChange={userSelections => {
          const selection = userSelections.map(selection => selection.value);
          // Changes are also saved in localStorage for better UI/UX (to save
          // the user's choices) but also because successful `actions` will
          // trigger a re-render
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selection));
          setSelectedRequestStates(selection);
        }}
      />
      <br />
      <Displayer
        strings={{ filterPlaceholder: "Filter Requests by Type, Shop ID or Account ID " }}
        fields={[
          { type: "dateTime", name: "createdAt", label: "Date", isSortable: true },
          { type: "string", name: "type", label: "Request Type", isSortable: true },
          { type: "string", name: "account", label: "Account", isSortable: "accountId" },
          { type: "string", name: "request", label: "Would like to" },
          { type: "string", name: "shop", label: "For Shop", isSortable: "shopId" },
          { type: "textarea", name: "reason", label: "Reason", isSortable: true },
          { type: "string", name: "shopId", label: "Shop ID", isSortable: true },
          { type: "string", name: "accountId", label: "Account ID", isSortable: true },
          {
            type: "component",
            name: "state",
            label: "Status",
            Component: ({ value }) => (
              <Chip
                label={value}
                className={
                  value === constants.MERCHANT_REQUESTS.STATE.PENDING
                    ? classes.pendingChip
                    : value === constants.MERCHANT_REQUESTS.STATE.APPROVED
                    ? classes.approvedChip
                    : classes.declinedChip
                }
                color="primary"
              />
            ),
            isSortable: true,
          },
        ]}
        readAll={{
          gql: merchantRequestsQuery,
          formatInput: () => ({ states: selectedRequestStates }),
          formatOutput: output => ({
            nodes: output.merchantRequests.nodes.map(node => {
              let account = "";
              if (node?.account.length) {
                account = `${node.account[0].primaryEmailAddress} - ${node.account[0].name}`;
              }

              let request = "";
              if (node.type === constants.MERCHANT_REQUESTS.TYPE.E_COMMERCE_ENABLED) {
                // yes, changeTo and changeFrom are strings
                request =
                  node.changeTo === "true"
                    ? REQUEST_MESSAGE.START_E_COMMERCE_HOSTING
                    : REQUEST_MESSAGE.STOP_E_COMMERCE_HOSTING;
              } else {
                // SHOP_CATEGORY
                request = REQUEST_MESSAGE.CHANGE_SHOP_CATEGORY(
                  node.fromShopCategory[0].name,
                  node.toShopCategory[0].name
                );
              }

              return {
                ...node,
                account,
                request,
                shop: node?.shop?.length ? node.shop[0].name : "",
              };
            }),
            totalCount: output.merchantRequests.totalCount,
          }),
        }}
        actions={[
          {
            label: "Approve",
            gql: handleMerchantRequestMutation,
            formatInput: id => ({
              id,
              state: constants.MERCHANT_REQUESTS.STATE.APPROVED,
            }),
            formatOutput: output => output.handleMerchantRequest,
            onSuccess: viewer.hydrate,
            shouldAppearInTable: true,
            IconComponent: ApproveIcon,
            className: classes.approveAction,
          },
          {
            label: "Decline",
            gql: handleMerchantRequestMutation,
            formatInput: id => ({
              id,
              state: constants.MERCHANT_REQUESTS.STATE.DECLINED,
            }),
            formatOutput: output => output.handleMerchantRequest,
            onSuccess: viewer.hydrate,
            shouldAppearInTable: true,
            IconComponent: DeclineIcon,
            className: classes.declineAction,
          },
        ]}
      />
    </MuiThemeProvider>
  );
};

export default MerchantRequestsPage;
