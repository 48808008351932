import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@reactioncommerce/catalyst/Button";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import CloseIcon from "mdi-material-ui/Close";
import {
  Box,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ProductSelectorWithData from "../../../../package/src/ProductSelectorWithData/ProductSelectorWithData";
import { updateRelatedProducts } from "./mutations.js";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
}));

/**
 * BrandsAndCategoriesSelectorDialog component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function RelatedProductsSelectorDialog(props) {
  const { productId, isOpen, onClose, shopId, initialRelatedProducts } = props;

  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState(
    initialRelatedProducts.map(product => ({
      label: product.title,
      value: product._id,
    }))
  );

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [pdateRelatedProducts] = useMutation(updateRelatedProducts, {
    onCompleted() {
      enqueueSnackbar("Successfully added!", { variant: "success" });
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  async function save() {
    await pdateRelatedProducts({
      variables: {
        relatedProductIds: selectedRelatedProducts.map(product => product.value),
        shopId,
        productId: productId,
      },
    });
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ root: classes.cardRoot }}
      fullWidth
      maxWidth="sm"
    >
      <CardHeader
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={"Add product"}
      />
      <React.Fragment>
        <CardContent>
          <ProductSelectorWithData
            shopIds={shopId}
            excludeIds={[productId]}
            shouldShowShopName={false}
            value={selectedRelatedProducts}
            onSelection={selections => setSelectedRelatedProducts(selections ?? [])}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Box>
            <Button variant="contained" color="primary" onClick={save}>
              Save
            </Button>
          </Box>
        </CardActions>
      </React.Fragment>
    </Dialog>
  );
}

RelatedProductsSelectorDialog.propTypes = {
  productId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialRelatedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  shopId: PropTypes.string.isRequired,
};

export default RelatedProductsSelectorDialog;
