import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@reactioncommerce/catalyst/Button";

import CloseIcon from "mdi-material-ui/Close";

import {
  Box,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import BrandsAndCategoriesMultiSelect from "../../../../package/src/BrandsAndCategoriesMultiSelect.js";

import {
  allProductCategoriesQuery,
  allProductBrandsQuery,
  productWithBrandsQuery,
  productWithCategoriesQuery,
} from "./queries.js";
import {
  updateProductBrandsMutation,
  updateProductCategoriesMutation,
  addProductBrandMutation,
  addProductCategoryMutation,
} from "./mutations.js";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
}));

/**
 * BrandsAndCategoriesSelectorDialog component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function BrandsAndCategoriesSelectorDialog(props) {
  const { type, title, isOpen, onClose, shopIds, productIds, onSuccess } = props;

  const classes = useStyles();

  const brandsAndCategories = {
    brands: {
      shopId: shopIds[0],
      values: {
        header:
          "Please select 1 or more brands for this product. This allows your product to be easily located by customers looking for a specific brand.",
        description:
          "To add a brand, simply start typing the brand's name then select it from the available options. If it does not exist, create it in the create section below.",
        apis: {
          selected: {
            gql: productWithBrandsQuery,
            formatInput: shopId => ({
              shopId: shopIds[0],
              productId: productIds[0],
            }),
            formatOutput: output => output?.product?.productBrands || [],
          },
          all: {
            gql: allProductBrandsQuery,
            formatInput: () => ({
              productBrandIds: [],
            }),
            formatOutput: output => output?.productBrands?.nodes || [],
          },
          update: {
            gql: updateProductBrandsMutation,
            formatInput: (shopId, productBrandIds) => ({
              productId: productIds[0],
              shopId: shopIds[0],
              productBrandIds,
            }),
            formatOutput: () => {},
          },
        },
      },
      onCreate: {
        button: "Create",
        description:
          "If you can't find the product brand in the above options, you can always add a new one by using the input field below and clicking on 'Create'.",
        api: {
          gql: addProductBrandMutation,
          formatInput: name => ({ name }),
          formatOutput: () => {},
        },
      },
    },
    categories: {
      shopId: shopIds[0],
      values: {
        header:
          "Please select 1 or more categories for this product. This allows your shop to be easily located by customers looking for a specific category.",
        description:
          "To add a category, simply start typing the category's name then select it from the available options. If it does not exist, create it in the create section below.",
        apis: {
          selected: {
            gql: productWithCategoriesQuery,
            formatInput: shopId => ({
              shopId: shopIds[0],
              productId: productIds[0],
            }),
            formatOutput: output => output?.product?.productCategories || [],
          },
          all: {
            gql: allProductCategoriesQuery,
            formatInput: () => ({
              productCategoryIds: [],
            }),
            formatOutput: output => output?.productCategories?.nodes || [],
          },
          update: {
            gql: updateProductCategoriesMutation,
            formatInput: (shopId, productCategoryIds) => ({
              productId: productIds[0],
              shopId: shopIds[0],
              productCategoryIds,
            }),
            formatOutput: () => {},
          },
        },
      },
      onCreate: {
        button: "Create",
        description:
          "If you can't find the product brand in the above options, you can always add a new one by using the input field below and clicking on 'Create'.",
        api: {
          gql: addProductCategoryMutation,
          formatInput: name => ({ name }),
          formatOutput: () => {},
        },
      },
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ root: classes.cardRoot }}
      fullWidth
      maxWidth="sm"
    >
      <CardHeader
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={title}
      />
      {shopIds.length > 1 ? (
        <CardContent>You have multiple shops selected. Please select a single one.</CardContent>
      ) : (
        <React.Fragment>
          <CardContent>
            <BrandsAndCategoriesMultiSelect
              onSuccess={onSuccess}
              shopId={brandsAndCategories[type].shopId}
              values={brandsAndCategories[type].values}
              onCreate={brandsAndCategories[type].onCreate}
            />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Box>
              <Button variant="contained" color="primary" onClick={onClose}>
                Save
              </Button>
            </Box>
          </CardActions>
        </React.Fragment>
      )}
    </Dialog>
  );
}

BrandsAndCategoriesSelectorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  shopIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BrandsAndCategoriesSelectorDialog;
