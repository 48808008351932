export const Shopper = `
_id
shoppedFromShops
name
firstName
lastName
emailRecords {
  address
}
phoneNumber
createdAt
shippingAddress {
  firstName
  lastName
  address1
  address2
  country
  region
  postal
  region
  phone
  email
  city
}
billingAddress {
  firstName
  lastName
  address1
  address2
  country
  region
  postal
  region
  phone
  email
  city
}
`;

export const ShopperInvitation = `
_id
firstName
lastName
email
phoneNumber
createdAt
`;