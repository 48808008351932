import React, { useState } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useConfirmDialog } from "@reactioncommerce/catalyst";
import { Button } from "@reactioncommerce/catalyst";
import Logger from "../../../package/src/utils/logger";
import { useSnackbar } from "notistack";
import useProduct from "../hooks/useProduct";
import ProductMediaItem from "./ProductMediaItem";
import MediaUploader from "./MediaUploader";
import MediaGallerySelectorDialog from "./MediaGallerySelector/MediaGallerySelectorDialog";

const archiveMediaRecordMutation = gql`
  mutation ArchiveMediaRecord($input: ArchiveMediaRecordInput!) {
    archiveMediaRecord(input: $input) {
      mediaRecord {
        _id
      }
    }
  }
`;

const updateMediaRecordPriorityMutation = gql`
  mutation UpdateMediaRecordPriorityMutation($input: UpdateMediaRecordPriorityInput!) {
    updateMediaRecordPriority(input: $input) {
      mediaRecord {
        _id
      }
    }
  }
`;

/**
 * ProductMediaGallery
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ProductMediaGallery(props) {
  const { editable, media, productId, shopIds, variantId, refreshToolbar } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { refetchProduct, product } = useProduct();
  const [mediaItemToRemove, setMediaItemToRemove] = useState(null);
  const [isMediaGallerySelectorOpen, setMediaGallerySelectorOpen] = useState(false);
  const [archiveMediaRecord] = useMutation(archiveMediaRecordMutation, {
    ignoreResults: true,
  });
  const [updateMediaRecordPriority] = useMutation(updateMediaRecordPriorityMutation, {
    ignoreResults: true,
    onCompleted() {
      enqueueSnackbar("Successfully updated image order!", {
        variant: "success",
      });
      refetchProduct();
      refreshToolbar();
    },
    onError(error) {
      enqueueSnackbar(`Could not update image order!`, { variant: "error" });
    },
  });
  const { t } = useTranslation();

  const handleRemoveMedia = async () => {
    await archiveMediaRecord({
      variables: {
        input: {
          mediaRecordId: mediaItemToRemove._id,
          shopId: shopIds[0],
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        archiveMediaRecord: {
          id: mediaItemToRemove._id,
          __typename: "MediaRecord",
          mediaRecord: null,
        },
      },
      onError(error) {
        Logger.error(error);
        enqueueSnackbar("Unable to remove media", { variant: "error" });
      },
    });

    // Re-fetch product data
    refetchProduct();
    refreshToolbar();
  };

  const {
    openDialog: openRemoveMediaDialog,
    ConfirmDialog: RemoveMediaConfirmDialog,
  } = useConfirmDialog({
    title: "Remove Media",
    message: "Are you sure you want to remove this media item?",
    onConfirm: () => {
      handleRemoveMedia();
    },
  });

  const confirmRemoveMediaItem = mediaItem => {
    setMediaItemToRemove(mediaItem);
    openRemoveMediaDialog();
  };

  const handleSetMediaPriority = async (mediaRecord, priority) => {
    updateMediaRecordPriority({
      variables: {
        input: {
          mediaRecordId: mediaRecord._id,
          priority,
          shopId: shopIds[0],
        },
      },
      onError(error) {
        Logger.error(error);
        enqueueSnackbar("Unable to update media priority", {
          variant: "error",
        });
      },
    });
  };

  let count = (Array.isArray(media) && media.length) || 0;
  const hasMedia = count > 0;

  // Determine the lowest rank (highest prio) inputted by user
  let valueForHighestPriority = +Infinity;
  (media || []).forEach(m => {
    // Don't take variants into account. This is the Product Media Gallery (this was changed)
    // if (m.variantId) return;

    valueForHighestPriority =
      m.priority < valueForHighestPriority ? m.priority : valueForHighestPriority;
  });

  // Determine how many images have the lowest rank (highest prio)
  let numberOfImagesWithHighestPriority = 0;
  (media || []).forEach(m => {
    // Don't take variants into account. This is the Product Media Gallery (this was changed)
    // if (m.variantId) return;

    m.priority === valueForHighestPriority && numberOfImagesWithHighestPriority++;
  });

  const orderedMedia = (media || []).sort((a, b) => {
    if (a.priority < b.priority) return -1;
    if (a.priority > b.priority) return 1;
    return 0;
  });

  const getFileMetadata = () => {
    return {
      productId,
      variantId,
      priority: product?.media ? product.media.length + 1 : 1,
    };
  };

  const onUploadError = error => {
    enqueueSnackbar(error.reason || error.message, { variant: "error" });
  };

  return (
    <div className="rui media-gallery">
      <div>
        {numberOfImagesWithHighestPriority > 1 && (
          <>
            <br />
            <span>
              <b>Note:</b> If more than 1 image have the highest priority, there won't be a featured
              image.
            </span>
          </>
        )}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("admin.productTable.header.order")}</TableCell>
            <TableCell>{"Media"}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!!hasMedia &&
            orderedMedia.map((mediaItem, index) => {
              // Be sure not to render Variant images in the Product Gallery (this was changed)
              // if (mediaItem.variantId) return null;

              return (
                <ProductMediaItem
                  valueForHighestPriority={valueForHighestPriority}
                  numberOfImagesWithHighestPriority={numberOfImagesWithHighestPriority}
                  editable={editable}
                  key={mediaItem._id}
                  onRemoveMedia={confirmRemoveMediaItem}
                  onSetMediaPriority={handleSetMediaPriority}
                  size="small"
                  source={mediaItem}
                  isVariant={!!variantId}
                />
              );
            })}
          {editable && (
            <TableRow>
              <TableCell colSpan={3}>
                <MediaUploader
                  canUploadMultiple
                  metadata={getFileMetadata}
                  onError={onUploadError}
                  refetchProduct={refetchProduct}
                  shopIds={shopIds}
                />
                <br />
                <Button
                  onClick={() => setMediaGallerySelectorOpen(true)}
                  fullWidth
                  size="large"
                  variant="outlined"
                >
                  Select image from Media Gallery
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <RemoveMediaConfirmDialog />

      <MediaGallerySelectorDialog
        title="Select Image(s)"
        isOpen={isMediaGallerySelectorOpen}
        onSuccess={refetchProduct}
        onClose={() => setMediaGallerySelectorOpen(false)}
        shopId={shopIds[0]}
        productId={productId}
        variantId={variantId}
      />
    </div>
  );
}

ProductMediaGallery.propTypes = {
  editable: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  media: PropTypes.arrayOf(PropTypes.object),
  onSetMediaPriority: PropTypes.func,
  productId: PropTypes.string,
  shopIds: PropTypes.arrayOf(PropTypes.string),
  variantId: PropTypes.string,
};

export default ProductMediaGallery;
