import gql from "graphql-tag";

export const getShopCategoriesQuery = gql`
  query getShopCategories(
    $shopCategoryIds: [ID]
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $filter: String
  ) {
    getShopCategories(
      shopCategoryIds: $shopCategoryIds
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        name
        description
        slug
        shopIds
        createdAt
        updatedAt
      }
    }
  }
`;
