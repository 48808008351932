import React, { useState } from "react";
import Button from "@reactioncommerce/catalyst/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TransactionsForm from "./TransactionsForm.js";
import { transactionsQuery } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";
import { constants } from "../../../constants";

const Transactions = ({ dateRange, companyIds, viewer, history, onChange }) => {
  let initialCrudState = null;
  const viewerType = viewer.type;
  const isMerchant = viewerType !== "godmode";

  const pathname = history?.location?.pathname;

  if (pathname.includes("edit")) {
    initialCrudState = "update";
  } else if (pathname.includes("create")) {
    initialCrudState = "create";
  } else {
    // get all
    initialCrudState = "display";
  }

  const [crudState, setCrudState] = useState(initialCrudState);
  const [updateId, setUpdateId] = useState(null);

  const displayFields = [
    {
      type: "string",
      name: "_id",
      label: "Transaction ID",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
    {
      type: "string",
      name: "from",
      label: "From",
      isSortable: "companyId", // not very accurate since sometimes `from` is oBuy
    },
    {
      type: "string",
      name: "to",
      label: "To",
      isSortable: "companyId", // not very accurate since sometimes `to` is oBuy
    },
    {
      type: "string",
      name: "amount",
      label: "Amount (USD)",
      isSortable: true,
    },
    {
      type: "string",
      name: "paymentMethod",
      label: "Payment",
      isSortable: true,
    },
    {
      type: "string",
      name: "type",
      label: "Type",
      isSortable: true,
    },
    {
      type: "string",
      name: "subscriptionId",
      label: "Subscription Record ID",
      isSortable: true,
    },
    {
      type: "textarea",
      name: "notes",
      label: "Payment Notes",
      isSortable: true,
    },
  ];

  const shouldfetchTransactions = Array.isArray(companyIds) && companyIds.length > 0;
  const handlers = {
    ...(isMerchant
      ? {}
      : {
          goToUpdatePage: id => {
            setUpdateId(id);
            setCrudState("update");
          },
        }),
    goToCreatePage: () => {
      setUpdateId(null);
      setCrudState("create");
    },
    goToDisplayPage: () => {
      setCrudState("display");
    },
  };

  return (
    <>
      {crudState === "display" ? (
        <CRUDer
          showDateRangeFilter={false}
          crud={{ state: "display" }}
          handlers={handlers}
          strings={{
            displayer: {
              filterPlaceholder: "Filter Transactions by ID, Merchant or Subscription Record ID",
              createButton: "Create Transaction",
            },
          }}
          fields={{
            display: displayFields,
          }}
          apis={{
            readAll: shouldfetchTransactions
              ? {
                  gql: transactionsQuery,
                  formatInput: () => ({
                    companyIds,
                    fromDate: dateRange?.startDate?.toISOString() || null,
                    toDate: dateRange?.endDate?.toISOString() || null,
                  }),
                  formatOutput: output => ({
                    nodes: output.transactionsBetweenMerchantAndGodmode.nodes.map(node => ({
                      ...node,
                      from: node.recipient === "merchant" ? constants.APP_NAME : node.companyName,
                      to: node.recipient === "merchant" ? node.companyName : constants.APP_NAME,
                    })),
                    totalCount: output.transactionsBetweenMerchantAndGodmode.totalCount,
                  }),
                }
              : undefined,
            actions: [],
          }}
        ></CRUDer>
      ) : (
        <>
          <Button
            variant="outlined"
            style={{ marginBottom: "15px" }}
            onClick={() => setCrudState("display")}
          >
            <ArrowBackIcon style={{ paddingRight: "5px" }} /> Back
          </Button>
          <TransactionsForm
            transactionId={updateId}
            viewer={viewer}
            onFinish={() => setCrudState("display")}
          />
        </>
      )}
    </>
  );
};

export default Transactions;
