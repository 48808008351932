import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import styled from "styled-components";
import { Form as ReactoFormo } from "reacto-form";
import { Query, Mutation } from "react-apollo";
import { withSnackbar } from "notistack";

// Reaction Commerce
import { getRequiredValidator } from "@reactioncommerce/components/utils";
import Button from "@reactioncommerce/catalyst/Button";
import ErrorsBlock from "@reactioncommerce/components/ErrorsBlock/v1";
import Field from "@reactioncommerce/components/Field/v1";
import InlineAlert from "@reactioncommerce/components/InlineAlert/v1";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Checkbox from "@reactioncommerce/components/Checkbox/v1";
import Select from "@reactioncommerce/components/Select/v1";

// Material UI
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import MUICardActions from "@material-ui/core/CardActions";

// Components
import Toolbar from "../../Toolbar";
import DateRangeSelector from "../../DateRangeSelector";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const PaddedField = styled(Field)`
  margin-bottom: 30px !important;
`;

const ContentGroup = styled.div`
  margin-bottom: 30px;
`;

// eslint-disable-next-line react/no-multi-comp
class Form extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    // onRemove: PropTypes.func,
  };

  static defaultProps = {
    onCancel() {},
    onCreate() {},
    onUpdate() {},
    // onRemove() {},
    idToEdit: null,
  };

  // Note that not all formData is contained within state.formData because
  // reactoform has internal mechanics that store all changes inside the form.
  // But when reactoform doesn't work with some input types
  // (like DateRangeSelector), we use `state.formData`.
  state = {
    currentTab: 0,
    formData: [],
    isSubmittingForm: false,
  };

  formValue = [];
  uniqueInstanceIdentifier = uniqueId("URLRedirectEditForm");

  async handleSubmit(reactoFormData, mutation) {
    this.setState(prevState => ({
      ...prevState,
      isSubmittingForm: true,
    }));

    // Remove what apollo returns with the query
    if (reactoFormData?.__typename) {
      delete reactoFormData.__typename;
    }

    // `data` contains all fields handled by `reactoform` but not the ones
    // handled externally inside `state.formData` --> add them
    const data = {
      ...reactoFormData,
      ...this.state.formData,
    };

    this.setState({ error: null });

    if (this.props.idToEdit === null) {
      try {
        await mutation({
          variables: this.props.queries.create.formatInput(data),
        });
      } catch (error) {
        this.props.enqueueSnackbar(error.toString().replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      if (typeof this.props.queries.create?.onSuccess === "function") {
        this.props.queries.create.onSuccess();
      }

      this.props.onCreate();
    } else {
      try {
        await mutation({
          variables: this.props.queries.update.formatInput(this.props.idToEdit, data),
        });
      } catch (error) {
        this.props.enqueueSnackbar(error.toString().replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      if (typeof this.props.queries.update?.onSuccess === "function") {
        this.props.queries.update.onSuccess();
      }

      this.props.onUpdate();
    }

    this.setState(prevState => ({
      ...prevState,
      isSubmittingForm: false,
    }));
  }

  reset() {
    this.formValue = [];
  }

  handleSubmitForm = () => {
    this.form.submit();
  };

  handleFormChange = value => {
    this.formValue = value;
  };

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };

  initDefaultFormData = () => {
    const defautFormData = [];

    for (const field of this.props.fields) {
      // Since dateRange is always optional, it should be initialized to
      // startOfTime till endOfTime
      if (field.type === "dateRange") {
        defautFormData[field.name] = { startDate: null, endDate: null };
      }
    }

    return defautFormData;
  };

  reactoForm = (value, mutationFunc) => {
    // Create the inputIds of all the fields so we can use them in the html in
    // the render
    const inputIds = this.props.fields.map(
      field => `${field.name}_${this.uniqueInstanceIdentifier}`
    );

    // Get the names of the fields so we can pass them to getRequiredValidator()
    // since it takes an infinite number of arguments of all the names of the
    // fields. Be sure to ignore `optional` fields.
    const names = this.props.fields
      .filter(field => !(field?.optional === true) && field.type !== "dateRange")
      .map(field => field.name);

    // If we are editing, formData will be populated with a Query.
    // However if we are creating, there is no data to load --> initialize
    // formData to default values
    if (!this.props.idToEdit) {
      value = this.initDefaultFormData();
    }

    return (
      <ReactoFormo
        ref={formRef => {
          this.form = formRef;
        }}
        onChange={this.handleFormChange}
        onSubmit={data => this.handleSubmit(data, mutationFunc)}
        validator={getRequiredValidator(...names)}
        value={value}
      >
        <ContentGroup>
          <Tabs value={this.state.currentTab} onChange={this.handleTabChange}>
            <Tab label={this.props.mainTabLabel} key={this.props.mainTabLabel} />
            {this.props.tabs &&
              this.props.tabs.map((tab, index) => <Tab label={tab.label} key={index} />)}
          </Tabs>
          <Divider />
        </ContentGroup>

        <Card style={{ overflow: "visible" }}>
          <CardContent>
            {this.state.currentTab === 0 && (
              <>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    {this.props.fields.map((field, index) => (
                      <PaddedField
                        key={index}
                        helpText={field.description}
                        name={field.name}
                        label={
                          field.type !== "boolean"
                            ? `${field.label}${!(field?.optional === true) ? "*" : ""}`
                            : ""
                        }
                        labelFor={inputIds[index]}
                        isRequired
                      >
                        {field.type === "string" ? (
                          <TextInput
                            id={inputIds[index]}
                            name={field.name}
                            placeholder={field.placeholder}
                          />
                        ) : (
                          ""
                        )}

                        {field.type === "number" ? (
                          <TextInput
                            id={inputIds[index]}
                            name={field.name}
                            placeholder={field.placeholder}
                            type="number"
                          />
                        ) : (
                          ""
                        )}

                        {field.type === "email" ? (
                          <TextInput
                            id={inputIds[index]}
                            name={field.name}
                            placeholder={field.placeholder}
                            type="email"
                          />
                        ) : (
                          ""
                        )}

                        {field.type === "boolean" ? (
                          <Checkbox id={inputIds[index]} name={field.name} label={field.label} />
                        ) : (
                          ""
                        )}

                        {field.type === "dropdown" ? (
                          <Select
                            name={field.name}
                            options={field.dropdownValues}
                            placeholder={field.placeholder}
                          />
                        ) : (
                          ""
                        )}

                        {/* For now make it similar to string */}
                        {field.type === "dateTime" ? (
                          <TextInput
                            id={inputIds[index]}
                            name={field.name}
                            placeholder={field.placeholder}
                          />
                        ) : (
                          ""
                        )}

                        {/* `reactoform` works with Material UI Components so we have to store this ones's state in state.formData */}
                        {field.type === "dateRange" ? (
                          <DateRangeSelector
                            dateRange={value && value[field.name]}
                            onChange={dateRange => {
                              this.setState(oldState => {
                                const formData = oldState.formData;
                                formData[field.name] = dateRange;

                                return {
                                  ...oldState,
                                  formData,
                                };
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {field.type === "textarea" ? (
                          <TextInput
                            id={inputIds[index]}
                            type="text"
                            shouldAllowLineBreaks
                            name={field.name}
                            placeholder={field.placeholder}
                          />
                        ) : (
                          ""
                        )}

                        <ErrorsBlock names={[field.name]} />
                      </PaddedField>
                    ))}
                  </Grid>
                </Grid>

                <CardActions disableSpacing>
                  <Button
                    isWaiting={this.state.isSubmittingForm}
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmitForm}
                  >
                    {this.props.buttonText}
                  </Button>
                </CardActions>
              </>
            )}

            {/* All other tabs passed by caller */}
            {this.props.tabs &&
              this.props.tabs.map(
                (tab, index) =>
                  this.state.currentTab === index + 1 && <div key={index}>{tab.Component}</div>
              )}
          </CardContent>
        </Card>
      </ReactoFormo>
    );
  };

  render() {
    return (
      <Fragment>
        <Toolbar
          title={this.props.formTitle}
          onDelete={() => {}}
          onCancel={() => this.props.onCancel()}
          /* onSave={this.handleSubmitForm} */
        />

        {this.state.error && <InlineAlert alertType="error" message={this.state.error.message} />}

        {!this.state.error && (
          <Mutation
            mutation={
              this.props.idToEdit === null
                ? this.props.queries.create.gql
                : this.props.queries.update.gql
            }
          >
            {mutationFunc => {
              if (this.props.idToEdit !== null && this.props.queries?.readOne) {
                return (
                  <Query
                    query={this.props.queries.readOne.gql}
                    variables={this.props.queries.readOne.formatInput(this.props.idToEdit)}
                    fetchPolicy="network-only"
                  >
                    {({ data, error, fetchMore }) => {
                      // Convert the values coming from input type "number" from
                      // String to Number. Once input type "number" is fixed to
                      // return a number, we can remove this
                      data =
                        data &&
                        Object.fromEntries(
                          Object.entries(
                            this.props.queries.readOne.formatOutput(data)
                          ).map(([name, value]) =>
                            typeof value === "number"
                              ? [name, Number(value).toString()]
                              : [name, value]
                          )
                        );

                      return this.reactoForm(data, mutationFunc);
                    }}
                  </Query>
                );
              } else {
                return this.reactoForm(this.formValue, mutationFunc);
              }
            }}
          </Mutation>
        )}
      </Fragment>
    );
  }
}

export default withSnackbar(Form);
