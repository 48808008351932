import StoreIcon from "mdi-material-ui/Store";
import ShopsPage from "./pages/ShopsPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 7,
    path: "/shops",
    MainComponent: ShopsPage,
    IconComponent: StoreIcon,
    navigationItemLabel: "Shop List",
    showShopSelector: false,
    collapse: {
      title: "Shops",
      IconComponent: StoreIcon, // icon to use for the collapse itself
    },
  });

  registerRoute({
    path: "/shops/create",
    MainComponent: ShopsPage,
    showShopSelector: false,
  });

  registerRoute({
    path: "/shops/edit/:shopId",
    MainComponent: ShopsPage,
    showShopSelector: false,
  });
}
