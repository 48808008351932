import React, { useState } from "react";
import DiscountCodeForm from "../components/DiscountCodeForm";
import DiscountCodeCampaign from "../components/DiscountCodeCampaign";
import Toolbar from "../../../package/src/Toolbar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { constants } from "../constants.js";

const { createCouponCodePageTitle, localGenerationTabLabel, emailCampaignTabLabel } = constants;

export default function CreateDiscountCodePage(props) {
  const shopId = props?.match?.params?.shopId;

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Toolbar
        title={createCouponCodePageTitle}
        onCancel={() => props.history.push("/discounts")}
      />

      <Tabs
        value={currentTab}
        style={{ margin: "15px" }}
        indicatorColor="primary"
        onChange={(event, value) => {
          setCurrentTab(value);
        }}
      >
        <Tab label={localGenerationTabLabel} />
        <Tab label={emailCampaignTabLabel} />
      </Tabs>

      {currentTab === 0 && <DiscountCodeForm {...props} shopId={shopId} />}
      {currentTab === 1 && <DiscountCodeCampaign {...props} shopId={shopId} />}
    </>
  );
}
