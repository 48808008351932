import gql from "graphql-tag";

export const allProductBrandsQuery = gql`
  query productBrands($productBrandIds: [ID]!, $first: ConnectionLimitInt, $last: ConnectionLimitInt) {
    productBrands(productBrandIds: $productBrandIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const allProductCategoriesQuery = gql`
  query productCategories($productCategoryIds: [ID]!, $first: ConnectionLimitInt, $last: ConnectionLimitInt) {
    productCategories(productCategoryIds: $productCategoryIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const productWithBrandsQuery = gql`
  query product($productId: ID!, $shopId: ID!) {
    product(productId: $productId, shopId: $shopId) {
      _id
      productBrands {
        _id
        name
      }
    }
  }
`;
export const productWithCategoriesQuery = gql`
  query product($productId: ID!, $shopId: ID!) {
    product(productId: $productId, shopId: $shopId) {
      _id
      productCategories {
        _id
        name
      }
    }
  }
`;
