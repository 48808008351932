import AccountIcon from "mdi-material-ui/AccountMultiple";
import Accounts from "./components/Accounts";
import Profile from "./components/Profile";
import { constants } from "../../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  registerRoute({
    group: "navigation",
    path: "/accounts",
    priority: 65,
    MainComponent: Accounts,
    IconComponent: AccountIcon,
    navigationItemLabel: "Staff",
    showSingleShopSelector: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
    ],
  });

  registerRoute({
    path: "/profile",
    MainComponent: Profile,
  });
}
