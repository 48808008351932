import {
  shopsQuery,
  shopQuery,
  shopWithProductBrandsQuery,
  shopWithProductCategoriesQuery,
  shopCategoriesQuery,
  allProductBrandsQuery,
  allProductCategoriesQuery,
  additionalRateForNewShopQuery,
  allCompanies,
} from "../graphql/queries";
import {
  createShopMutation,
  updateShopMutation,
  removeShopMutation,
  addProductBrandMutation,
  addProductCategoryMutation,
} from "../graphql/mutations";
import CRUDer from "../../../package/src/CRUDer";
import ChangeECommerce from "../components/ChangeECommerce";
import ChangeShopCategory from "../components/ChangeShopCategory";
import OverrideShopSubscription from "../components/OverrideShopSubscription";
import BrandsAndCategoriesMultiSelect from "../../../package/src/BrandsAndCategoriesMultiSelect.js";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import { useQuery } from "@apollo/react-hooks";
import { constants } from "../../../constants";
import Toolbar from "../../../package/src/Toolbar";

const inferMerchantOptions = allCompanyResults => {
  // const uniqueCompanyIds = [];
  // const uniqueCompanies = [];

  // for (let shop of adminUIShops) {
  //   if (!uniqueCompanyIds.includes(shop.company._id)) {
  //     // this is a new company (not a dupe) --> add it to uniqueCompanies
  //     uniqueCompanies.push({
  //       _id: shop.company._id,
  //       label: shop.company.name,
  //       value: shop.company._id,
  //     });
  //     uniqueCompanyIds.push(shop.company._id);
  //   }
  // }

  // return uniqueCompanies;

  return (
    allCompanyResults?.companies?.nodes?.map(node => ({
      _id: node._id,
      label: node.name,
      value: node._id,
    })) || []
  );
};

const ShopsPage = props => {
  const { match, history, viewer } = props;

  if (!viewer) return null;

  const godmode = viewer?.type === "godmode";
  const shopId = match?.params?.shopId || null;
  const pathname = history?.location?.pathname;

  let crudState = null;
  let dropdownValues = null;
  let brandsAndCategories = {
    brands: {
      shopId,
      label: "Product Brands",
      values: {
        header:
          "Please add all the product brands that you sell in this shop. This allows your shop to be easily located by customers looking for a specific brand.",
        description:
          "To add a brand, simply start typing the brand's name then select it from the available options. If it does not exist, create it in the create section below.",
        apis: {
          selected: {
            gql: shopWithProductBrandsQuery,
            formatInput: shopId => ({ id: shopId }),
            formatOutput: output => output?.shop?.productBrands || [],
          },
          all: {
            gql: allProductBrandsQuery,
            formatInput: () => ({
              productBrandIds: [],
            }),
            formatOutput: output => output?.productBrands?.nodes || [],
          },
          update: {
            gql: updateShopMutation,
            formatInput: (shopId, productBrandIds) => ({
              input: {
                shopId,
                productBrandIds,
              },
            }),
            formatOutput: () => {},
          },
        },
      },
      onCreate: {
        button: "Create",
        description:
          "If you can't find the product brand in the above options, you can always add a new one by using the input field below and clicking on 'Create'.",
        api: {
          gql: addProductBrandMutation,
          formatInput: name => ({ name }),
          formatOutput: () => {},
        },
      },
    },
    categories: {
      shopId,
      label: "Product Categories",
      values: {
        header:
          "Please add all the product categories that you sell in this shop. This allows your shop to be easily located by customers looking for a specific product category.",
        description:
          "To add a category, simply start typing the category's name then select it from the available options. If it does not exist, create it in the create section below.",
        apis: {
          selected: {
            gql: shopWithProductCategoriesQuery,
            formatInput: shopId => ({ id: shopId }),
            formatOutput: output => output?.shop?.productCategories || [],
          },
          all: {
            gql: allProductCategoriesQuery,
            formatInput: () => ({
              productCategoryIds: [],
            }),
            formatOutput: output => output?.productCategories?.nodes || [],
          },
          update: {
            gql: updateShopMutation,
            formatInput: (shopId, productCategoryIds) => ({
              input: {
                shopId,
                productCategoryIds,
              },
            }),
            formatOutput: () => {},
          },
        },
      },
      onCreate: {
        button: "Create",
        description:
          "If you can't find the product category in the above options, you can always add a new one by using the input field below and clicking on 'Create'.",
        api: {
          gql: addProductCategoryMutation,
          formatInput: name => ({ name }),
          formatOutput: () => {},
        },
      },
    },
  };

  // Fetch Shop Categories for dropdowns in `edit` pages
  const { data } = useQuery(shopCategoriesQuery, {
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  dropdownValues = data?.getShopCategories?.nodes.map(category => ({
    id: category._id,
    label: category.name,
    value: category._id,
  }));

  const { data: allCompanyResults } = useQuery(allCompanies, {
    fetchPolicy: "network-only",
    onError(error) {
      console.error("error while fetching companies: ", error);
    },
  });

  const merchantOptions = godmode ? inferMerchantOptions(allCompanyResults) : [];

  const productSortingOptions = [
    { id: "newest", label: "Newest", value: "newest" },
    { id: "oldest", label: "Oldest", value: "oldest" },
    { id: "mostViewed", label: "Most Viewed", value: "mostViewed" },
    { id: "mostPurchased", label: "Most Purchased", value: "mostPurchased" },
    { id: "mostExpensive", label: "Most Expensive", value: "mostExpensive" },
    { id: "leastExpensive", label: "Least Expensive", value: "leastExpensive" },
  ];

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  // Fetch rate of additional shop to display it as notice (only in `Create` page)
  const { data: additionalRateData } = useQuery(additionalRateForNewShopQuery, {
    variables: {
      companyId: viewer?.companyId,
    },
    skip: crudState !== "create",
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  const additionalRate = additionalRateData?.additionalRateForNewShop?.additionalRate;
  const billingPeriodInDays = additionalRateData?.additionalRateForNewShop?.billingPeriodInDays;
  const shopCreationNoticeMessage =
    additionalRate > 0 &&
    `NOTE: This shop will add charges of $${additionalRate} per ${billingPeriodInDays} days`;

  const displayFields = [
    { type: "string", name: "_id", label: "ID" },
    { type: "string", name: "name", label: "Name", isSortable: true },
    { type: "string", name: "category", label: "Category", isSortable: "shopCategoryId" },
    { type: "boolean", name: "eCommerceEnabled", label: `eCommerce`, isSortable: true },
    ...(godmode
      ? [
          { type: "string", name: "companyName", label: "Merchant", isSortable: "companyId" },
          { type: "number", name: "ratePreOverride", label: "Rate Pre Override (USD)" },
          { type: "number", name: "ratePostOverride", label: "Rate Post Override (USD)" },
          { type: "number", name: "commissionPreOverride", label: "Commission Pre Override (%)" },
          { type: "number", name: "commissionPostOverride", label: "Commission Post Override (%)" },
        ]
      : [
          { type: "number", name: "ratePostOverride", label: "Rate (USD)" },
          { type: "number", name: "commissionPostOverride", label: "Commission (%)" },
        ]),
    { type: "number", name: "billingPeriodInDays", label: "Billing Period (days)" },
    { type: "boolean", name: "active", label: "Appears for shoppers" },
    { type: "dateTime", name: "createdAt", label: "Created On", isSortable: true },
  ];

  const updateFields = [
    { type: "string", name: "name", label: "Name", placeholder: "e.g. My Awesome Shop" },
    { type: "string", name: "emails", label: "Email" },
    {
      type: "dropdown",
      name: "defaultCatalogSorting",
      label: "Sort Products By",
      dropdownValues: productSortingOptions,
    },
    { type: "string", name: "phoneNumbers", label: "Phone Number", optional: true },
    { type: "string", name: "facebook", label: "Facebook", optional: true },
    { type: "string", name: "instagram", label: "Instagram", optional: true },
    { type: "string", name: "twitter", label: "Twitter", optional: true },
    {
      type: "string",
      name: "linkToExternalWebsite",
      label: "Link to External Website",
      description: `Leave empty if your eCommerce is hosted on ${constants.APP_NAME}`,
      optional: true,
    },
  ];

  // Only godmode is allowed to edit shopCategoryId and eCommerceEnabled without
  // placing a request
  if (godmode) {
    updateFields.push(
      { type: "boolean", name: "active", label: "Appears for shoppers" },
      {
        type: "dropdown",
        name: "shopCategoryId",
        label: "Category",
        description: "This shop will only appear in streets of that category",
        dropdownValues,
      },
      {
        type: "boolean",
        name: "eCommerceEnabled",
        label: `eCommerce Hosted on ${constants.APP_NAME}`,
      }
    );
  }

  const createFields = [
    ...(godmode
      ? [
          {
            type: "dropdown",
            name: "companyId",
            label: "Merchant",
            dropdownValues: merchantOptions,
            optional: godmode, // we should allow godmode not to pass any companyId because the FIRST shop ever (that seeds the DB) will be created before any merchant is created
          },
        ]
      : []),
    { type: "string", name: "name", label: "Name", placeholder: "e.g. My Awesome Shop" },
    {
      type: "dropdown",
      name: "shopCategoryId",
      label: "Category",
      description: "This shop will only appear in streets of that category",
      dropdownValues,
      optional: godmode, // we should allow godmode not to pass any shopCategoryId because the FIRST shop ever (that seeds the DB) will be created before any category is created
    },
    { type: "string", name: "emails", label: "Email" },
    {
      type: "dropdown",
      name: "defaultCatalogSorting",
      label: "Sort Products By",
      dropdownValues: productSortingOptions,
    },
    { type: "string", name: "phoneNumbers", label: "Phone Number", optional: true },
    { type: "string", name: "facebook", label: "Facebook", optional: true },
    { type: "string", name: "instagram", label: "Instagram", optional: true },
    { type: "string", name: "twitter", label: "Twitter", optional: true },
    {
      type: "boolean",
      name: "eCommerceEnabled",
      label: `eCommerce Hosted on ${constants.APP_NAME}`,
      optional: true,
    },
    {
      type: "string",
      name: "linkToExternalWebsite",
      label: "Link to External eCommerce Website",
      description: `Leave empty if your eCommerce is hosted on ${constants.APP_NAME}`,
      optional: true,
    },
  ];

  const additionalTabs = [
    {
      label: brandsAndCategories.brands.label,
      Component: (
        <BrandsAndCategoriesMultiSelect
          shopId={brandsAndCategories.brands.shopId}
          values={brandsAndCategories.brands.values}
          onCreate={brandsAndCategories.brands.onCreate}
        />
      ),
    },
    {
      label: brandsAndCategories.categories.label,
      Component: (
        <BrandsAndCategoriesMultiSelect
          shopId={brandsAndCategories.categories.shopId}
          values={brandsAndCategories.categories.values}
          onCreate={brandsAndCategories.categories.onCreate}
        />
      ),
    },
  ];

  // There's no point in showing tabs that have to do with placing merchant
  // requests if the user is godmode, since he/she can edit directly.
  if (!godmode) {
    additionalTabs.push(
      {
        label: "eCommerce Hosting",
        Component: <ChangeECommerce shopId={shopId} />,
      },
      {
        label: "Shop Category",
        Component: <ChangeShopCategory shopId={shopId} options={dropdownValues} />,
      }
    );
  }

  if (godmode) {
    additionalTabs.push({
      label: "Overrides",
      Component: <OverrideShopSubscription shopId={shopId} />,
    });
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Shops" />
      {crudState === "create" && shopCreationNoticeMessage && <>{shopCreationNoticeMessage}</>}
      <CRUDer
        crud={{ state: crudState, id: shopId }}
        handlers={{
          goToUpdatePage: id => history.push(`/shops/edit/${id}`),
          goToCreatePage: () => history.push(`/shops/create`),
          goToDisplayPage: () => history.goBack(), // this way even if we reach the edit Shop page from other pages (e.g. Merchants), the Cancel works as expected
        }}
        strings={{
          displayer: {
            createButton: "Create Shop",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Shops by name",
          },
          creator: {
            formTitle: "Create Shop",
            buttonText: "Add Shop",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Shop",
            buttonText: "Save Shop",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: updateFields,
          create: createFields,
          display: displayFields,
        }}
        apis={{
          create: {
            gql: createShopMutation,
            formatInput: input => ({
              input: {
                ...input,
                type: "merchant",
                companyId: godmode ? input.companyId : viewer?.companyId, // godmode picks the merchant from a dropdown. merchant can only create shops for himself
                emails: [{ address: input.emails }],
                phoneNumbers: [input.phoneNumbers],
              },
            }),
            formatOutput: output => output?.shop,
            onSuccess: viewer.hydrate, // refetch viewer and pass it to all routes
          },
          readOne: {
            gql: shopQuery,
            formatInput: id => ({ id }),
            formatOutput: output => ({
              ...output?.shop,
              shopCategoryId: output?.shop?.getShopCategory?._id,
              emails: output?.shop?.emails
                ? output.shop.emails.map(email => email.address).join(", ")
                : "",
              phoneNumbers: output?.shop?.phoneNumbers ? output.shop.phoneNumbers.join(", ") : "",
              companyName: output?.shop?.company?.name || "",
            }),
          },
          readAll: {
            gql: shopsQuery,
            formatInput: () => ({
              shopIds:
                (viewer?.adminUIShops &&
                  viewer.adminUIShops.length &&
                  viewer.adminUIShops.map(shop => shop._id)) ||
                [],
            }),
            formatOutput: output => ({
              nodes: output.shopsWithFilters.nodes.map(node => ({
                ...node,
                category: node?.getShopCategory?.name,
                companyName: node?.company?.name || "",
                ratePreOverride: node?.subscription.preOverride.rate,
                ratePostOverride: node?.subscription.postOverride.rate,
                commissionPreOverride: node?.subscription.preOverride.commissionPercentage,
                commissionPostOverride: node?.subscription.postOverride.commissionPercentage,
                billingPeriodInDays: node?.subscription.billingPeriodInDays,
              })),
              totalCount: output.shopsWithFilters.totalCount,
            }),
          },
          update: {
            gql: updateShopMutation,
            formatInput: (id, input) => {
              const {
                name,
                description,
                facebook,
                instagram,
                twitter,
                active,
                linkToExternalWebsite,
                shopCategoryId,
                eCommerceEnabled,
                defaultCatalogSorting,
              } = input;

              return {
                input: {
                  shopId: id,
                  name,
                  active,
                  emails: [{ address: input.emails }],
                  phoneNumbers: [input.phoneNumbers],
                  description,
                  facebook,
                  instagram,
                  twitter,
                  linkToExternalWebsite,
                  defaultCatalogSorting,
                  // Only godmode can update the following fields without placing
                  // separate Merchant Request
                  ...(godmode
                    ? {
                        shopCategoryId,
                        eCommerceEnabled,
                      }
                    : {}),
                },
              };
            },
            formatOutput: output => output?.shop,
            onSuccess: viewer.hydrate, // refetch viewer and pass it to all routes
          },
          actions: [
            // TODO: this mutation doesn't exist yet
            // {
            //   label: "Delete",
            //   gql: removeShopMutation,
            //   formatInput: id => ({ id }),
            //   formatOutput: output => output?.shop,
            // },
          ],
        }}
        additionalTabs={additionalTabs}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

export default ShopsPage;
