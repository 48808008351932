import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Displayer from "./Displayer";
import Creator from "./Creator";
import Updater from "./Updater";

/**
 * @name CRUDer
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
const CRUDer = (
  {
    fields,
    apis,
    crud,
    handlers,
    strings,
    additionalTabs,
    showDateRangeFilter = true,
    showExportButton = true,
  },
  ref
) => {
  return (
    <>
      {crud.state === "display" ? (
        <Displayer
          ref={ref}
          fields={fields.display}
          readAll={apis.readAll}
          actions={apis.actions}
          goToCreatePage={handlers?.goToCreatePage}
          goToUpdatePage={handlers?.goToUpdatePage}
          onSelectionChange={handlers?.onSelectionChange}
          strings={strings?.displayer}
          showDateRangeFilter={showDateRangeFilter}
          showExportButton={showExportButton}
        />
      ) : (
        ""
      )}
      {crud.state === "create" ? (
        <Creator
          fields={fields.create}
          create={apis.create}
          goToDisplayPage={handlers?.goToDisplayPage}
          strings={strings?.creator}
          additionalTabs={additionalTabs}
        />
      ) : (
        ""
      )}
      {crud.state === "update" ? (
        <Updater
          id={crud.id}
          fields={fields.update}
          update={apis.update}
          readOne={apis.readOne}
          goToDisplayPage={handlers?.goToDisplayPage}
          strings={strings?.updater}
          additionalTabs={additionalTabs}
        />
      ) : (
        ""
      )}
    </>
  );
};

const fieldsArrayDef = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.oneOf([
      "string",
      "number",
      "boolean",
      "dropdown",
      "dateTime",
      "image",
      "dateRange",
      "textarea",
      "component",
    ]).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    placeholder: PropTypes.string,
    description: PropTypes.string,

    // For type `dropdown` only
    dropdownValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),

    // For type `image` only
    imageWidth: PropTypes.integer,
    imageHeight: PropTypes.integer,
  })
);

const apiDef = PropTypes.shape({
  gql: PropTypes.object.isRequired,
  formatInput: PropTypes.func.isRequired,
  formatOutput: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
});

const apiDefActions = PropTypes.shape({
  label: PropTypes.string.isRequired, // label of every Action on UI
  gql: PropTypes.object.isRequired,
  formatInput: PropTypes.func.isRequired,
  formatOutput: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  shouldAppearInTable: PropTypes.bool, // whether or not this Action should also appear in the Table rows (to the end but before edit buttons)
  IconComponent: PropTypes.object, // only used if shouldAppearInTable is `true`. React Component. Material UI Icon to display in the table
  className: PropTypes.string, // only used if shouldAppearInTable is `true`. className for the above icon (@material-ui/core/styles)
}).isRequired;

CRUDer.propTypes = {
  crud: PropTypes.shape({
    state: PropTypes.oneOf(["display", "create", "update"]).isRequired,
    id: PropTypes.string,
  }).isRequired,
  handlers: PropTypes.shape({
    goToDisplayPage: PropTypes.func,
    goToCreatePage: PropTypes.func,
    goToUpdatePage: PropTypes.func,
    onSelectionChange: PropTypes.func, // Called back when user selects/deselects an item in Displayer (with IDs of selected rows)
  }),
  strings: PropTypes.shape({
    creator: PropTypes.object,
    displayer: PropTypes.object,
    updater: PropTypes.object,
  }),
  fields: PropTypes.shape({
    display: fieldsArrayDef,
    create: fieldsArrayDef,
    update: fieldsArrayDef,
  }).isRequired,
  apis: PropTypes.shape({
    create: apiDef,
    readOne: apiDef,
    readAll: apiDef,
    update: apiDef,
    actions: PropTypes.arrayOf(apiDefActions),
  }).isRequired,
  additionalTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      Component: PropTypes.object.isRequired,
    })
  ),
  showDateRangeFilter: PropTypes.bool,
  showExportButton: PropTypes.bool,
};

export default forwardRef(CRUDer);
