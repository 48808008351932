import React, { useState, useEffect } from "react";

import { financialHistoryRecordsGQL } from "../graphql/queries.js";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";

import CRUDer from "../../../package/src/CRUDer";

import Toolbar from "../../../package/src/Toolbar";

function FinancialHistoryRecordsPage({ viewer }) {
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: [],
    selectedShopIds: [],
  });

  return (
    <>
      <Toolbar title="Financial History Records" />

      <MerchantSelectorWithData
        shouldShowShopName
        size={32}
        viewer={viewer}
        selectedCompanyIds={selectedMerchants.selectedCompanyIds}
        onMerchantSelectionChange={setSelectedMerchants}
      />
      <br />
      <CRUDer
        crud={{ state: "display" }}
        fields={{
          display: [
            {
              type: "dateTime",
              name: "createdAt",
              label: "Created On",
            },
            {
              type: "string",
              name: "companyName",
              label: "Merchant",
            },
            {
              type: "string",
              name: "numberOfReminderEmailsSent",
              label: "# Reminder Emails Sent",
            },
            {
              type: "string",
              name: "numberOfDaysInTheNegative",
              label: "# Days w/ Negative Balance",
            },
            {
              type: "string",
              name: "ordersTotal",
              label: "Orders (USD)",
            },
            {
              type: "string",
              name: "subscriptionsTotal",
              label: "Subscriptions (USD)",
            },
            {
              type: "string",
              name: "transactionsTotal",
              label: "Transactions (USD)",
            },
            {
              type: "string",
              name: "total",
              label: "Total (USD)",
            },
          ],
        }}
        apis={{
          readAll: {
            gql: financialHistoryRecordsGQL,
            formatInput: filter => ({ companyIds: selectedMerchants.selectedCompanyIds }),
            formatOutput: output => ({
              nodes: output.financialHistoryRecords.nodes.map(node => ({
                ...node,
                companyName: node.company.name,
                ordersTotal: node.balances.orders,
                subscriptionsTotal: node.balances.subscriptions,
                transactionsTotal: node.balances.transactions,
                total: node.balances.total,
              })),
              totalCount: output.financialHistoryRecords.totalCount,
            }),
          },
          actions: [],
        }}
      />
    </>
  );
}

export default FinancialHistoryRecordsPage;
