import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import SimpleSchema from "simpl-schema";
import styled from "styled-components";
import _ from "lodash";
import { Button, TextField } from "@reactioncommerce/catalyst";
import {
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Card,
  CardContent,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import muiOptions from "reacto-form/cjs/muiOptions";
import useReactoForm from "reacto-form/cjs/useReactoForm";

// GraphQL
import couponTriggerGQL from "../graphql/queries/couponTrigger.js";
import updateCouponTriggerGQL from "../graphql/mutations/updateCouponTrigger.js";

// Components
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import ShopperSelectorWithData from "../../../package/src/ShopperSelectorWithData";
import DateRangeSelector from "../../../package/src/DateRangeSelector";

// Constants
import { constants } from "../constants.js";

const {
  conditionMessage,
  productSelectionLabel,
  shopperSelectionLabel,
  dateRangeSelectionLabel,
  discountAmountLabel,
  discountAmountPlaceholder,
  checkboxLabel,
  generateButtonLabel,
  discountIdLabel,
  discountIdPlaceholder,
  calculationMethods,
  couponTriggerProductSelectionLabel,
  couponTriggerShopperSelectionLabel,
  couponTriggerCheckboxLabel,
  couponTriggerDiscountIdLabel,
  couponTriggerDiscountIdPlaceholder,
  couponTriggerDiscountAmountPlaceholder,
  couponCodeLabel,
} = constants;

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const useStyles = makeStyles(theme => ({
  deleteButton: {
    marginRight: "auto",
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  legend: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize * 0.9,
    lineHeight: 2,
  },
  gridLowerThanDropdown: {
    zIndex: 0, // otherwise dropdowns will appear beneath the grids
  },
}));

const couponCodeSchema = new SimpleSchema({
  calculation: Object,
  "calculation.method": {
    type: String,
  },
  conditions: {
    type: Object,
    optional: true,
  },
  "conditions.enabled": {
    type: Boolean,
    optional: true,
    defaultValue: true,
  },
  "conditions.accountLimit": {
    type: Number,
    optional: true,
  },
  "conditions.redemptionLimit": {
    type: Number,
    optional: true,
  },
  "conditions.order": {
    type: Object,
    defaultValue: {},
  },
  "conditions.order.startDate": {
    type: Date,
    optional: true,
  },
  "conditions.order.endDate": {
    type: Date,
    optional: true,
  },
  "conditions.audience": {
    type: Array,
    optional: true,
  },
  "conditions.audience.$": {
    type: String,
    optional: true,
  },
  "conditions.products": {
    type: Array,
    optional: true,
  },
  "conditions.products.$": {
    type: String,
    optional: true,
  },
  discount: {
    type: Number,
    optional: true,
  },
  discountId: {
    type: String,
    optional: true,
  },
  consumed: {
    type: Boolean,
    optional: true,
  },
  code: {
    type: String,
    optional: true,
  },
});
const validator = couponCodeSchema.getFormValidator();

/**
 * @summary React component that renders the form for adding, updating, or deleting
 *   a discount code record.
 * @param {Object} props React props
 * @return {React.Node} React node
 */
export default function CouponTriggerForm(props) {
  const { couponTriggerId, shopId, history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // These are not handled by reactoform so we create separate states for them
  const [productSelection, setProductSelection] = useState([]);
  const [shopperSelection, setShopperSelection] = useState([]);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: null, endDate: null });
  const [isConsumed, setIsConsumed] = useState(false);

  const onSuccess = () => {
    setIsSubmitting(false);
    history.push("/coupon-triggers");
  };

  const onFailure = () => {
    setIsSubmitting(false);
    enqueueSnackbar(i18next.t("admin.discountCode.failure"), { variant: "warning" });
  };

  const { data: couponTriggerData } = useQuery(couponTriggerGQL, {
    variables: {
      id: couponTriggerId,
    },
    skip: !couponTriggerId,
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  const couponTrigger = couponTriggerData?.couponTrigger;

  // Fill the extra formData with `couponTriggerData` fetched from server
  useEffect(() => {
    if (!couponTriggerId) return;

    setProductSelection(
      couponTrigger?.conditions?.productDetails?.map(product => ({
        value: product._id,
        label: `${product?.title} - ${product.shop.name}`,
      })) || []
    );

    setShopperSelection(
      couponTrigger?.conditions?.shopperDetails?.map(shopper => ({
        value: shopper._id,
        label: `${shopper?.name} - ${shopper.primaryEmailAddress}`,
      })) || []
    );

    const startDate = couponTrigger?.conditions?.order?.startDate;
    const endDate = couponTrigger?.conditions?.order?.endDate;

    setDateRangeSelection({
      startDate: startDate && new Date(startDate),
      endDate: endDate && new Date(endDate),
    });

    setIsConsumed(couponTrigger?.consumed || false);
  }, [couponTrigger]);

  const [updateCouponTrigger] = useMutation(updateCouponTriggerGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar(i18next.t("admin.discountCode.updateSuccess"), { variant: "success" });
    },
    onError() {
      onFailure();
    },
  });

  const { getFirstErrorMessage, getInputProps, isDirty, hasErrors, submitForm } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      // formData coming from reactoform does not contain everything inside  it.
      // We have separate states as well --> add them
      // shopId: ID
      // discount: String
      // calculation: DiscountCalculationInput
      // conditions: DiscountConditionsInput
      // discountId: ID
      // consumed: Boolean

      if (!formData?.conditions) formData.conditions = {};

      formData.conditions.audience = Array.isArray(shopperSelection)
        ? shopperSelection.map(shopper => shopper.value)
        : null;

      formData.conditions.products = Array.isArray(productSelection)
        ? productSelection.map(product => product.value)
        : null;

      formData.consumed = isConsumed;

      if (dateRangeSelection?.startDate || dateRangeSelection?.endDate) {
        formData.conditions.order = {};

        if (dateRangeSelection?.startDate)
          formData.conditions.order.startDate = dateRangeSelection.startDate;

        if (dateRangeSelection?.endDate)
          formData.conditions.order.endDate = dateRangeSelection.endDate;
      }

      const couponTriggerInput = couponCodeSchema.clean(formData);
      if (couponTriggerInput.conditions) {
        // Set order minimum to 0, this will allow a discount to be
        // Redeemed infinitely on any number of orders.
        _.set(couponTriggerInput, "conditions.order.min", 0);
      }

      await updateCouponTrigger({
        variables: {
          id: couponTriggerId,
          input: couponTriggerInput,
        },
      });
    },
    validator(formData) {
      return validator(couponCodeSchema.clean(formData));
    },
    value: couponTrigger,
    logErrorsOnSubmit: true,
  });

  let calculationMethodField;
  if (Array.isArray(calculationMethods) && calculationMethods.length) {
    const options = calculationMethods.map(({ value, label }) => ({ label, value }));
    calculationMethodField = (
      <TextField
        error={hasErrors(["calculation.method"])}
        fullWidth
        helperText={getFirstErrorMessage(["calculation.method"])}
        label={`${i18next.t("admin.discountCode.form.calculationMethod")} *`}
        onKeyPress={event => {
          if (event.key === "Enter") submitForm();
        }}
        select
        {...getInputProps("calculation.method", muiOptions)}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  const classes = useStyles();

  return (
    <Card style={{ overflow: "visible" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <TextField
              error={hasErrors(["code"])}
              fullWidth
              helperText={getFirstErrorMessage(["code"])}
              label={couponCodeLabel}
              placeholder={couponTriggerDiscountAmountPlaceholder}
              {...getInputProps("code", muiOptions)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="label" classes={{ root: classes.label }}>
              {couponTriggerShopperSelectionLabel}
            </FormLabel>
            <ShopperSelectorWithData
              shouldShowEmail
              shopIds={[shopId]}
              value={shopperSelection}
              onSelection={setShopperSelection}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="label" classes={{ root: classes.label }}>
              {couponTriggerProductSelectionLabel}
            </FormLabel>
            <ProductSelectorWithData
              shouldShowShopName
              shopIds={[shopId]}
              value={productSelection}
              onSelection={setProductSelection}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <TextField
              type="number"
              error={hasErrors(["discount"])}
              fullWidth
              helperText={getFirstErrorMessage(["discount"])}
              label={discountAmountLabel}
              placeholder={discountAmountPlaceholder}
              {...getInputProps("discount", muiOptions)}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            {calculationMethodField}
          </Grid>
        </Grid>

        <br />
        <Divider />
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <FormLabel component="legend" classes={{ root: classes.legend }}>
              Conditions
            </FormLabel>
            {conditionMessage}
          </Grid>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <TextField
              error={hasErrors(["conditions.accountLimit"])}
              fullWidth
              helperText={getFirstErrorMessage(["conditions.accountLimit"])}
              label={i18next.t("admin.discountCode.form.accountLimit")}
              placeholder={i18next.t("admin.discountCode.form.accountLimitPlaceholder")}
              {...getInputProps("conditions.accountLimit", muiOptions)}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <TextField
              error={hasErrors(["conditions.redemptionLimit"])}
              fullWidth
              helperText={getFirstErrorMessage(["conditions.redemptionLimit"])}
              label={i18next.t("admin.discountCode.form.redemptionLimit")}
              onKeyPress={event => {
                if (event.key === "Enter") submitForm();
              }}
              placeholder={i18next.t("admin.discountCode.form.redemptionLimitPlaceholder")}
              {...getInputProps("conditions.redemptionLimit", muiOptions)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridLowerThanDropdown}>
            <FormLabel component="label" classes={{ root: classes.label }}>
              {dateRangeSelectionLabel}
            </FormLabel>
            <DateRangeSelector dateRange={dateRangeSelection} onChange={setDateRangeSelection} />
          </Grid>
        </Grid>

        <br />

        <CardActions disableSpacing>
          <Button
            disabled={isSubmitting /*  || !isDirty */}
            onClick={submitForm}
            variant="contained"
            color="primary"
          >
            {couponTriggerId ? i18next.t("app.save") : generateButtonLabel}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
