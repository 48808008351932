import RateForm from "../components/RateForm";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../constants.js";

const { editRatePageTitle } = constants;

export default function EditRatePage(props) {
  const shopId = props?.match?.params?.shopId;
  const discountCodeId = props?.match?.params?.discountCodeId;

  return (
    <>
      <Toolbar title={editRatePageTitle} onCancel={() => props.history.push("/sale")} />
      <RateForm {...props} shopId={shopId} discountCodeId={discountCodeId} />
    </>
  );
}
