import { useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { setAccessToken } from "../lib/graphql/initApollo";
import { viewerQuery } from "../graphql/queries/viewer.js";
import { Auth } from "aws-amplify";
import { SingleShopSelectorContext } from "../context/SingleShopSelectorContext";
import { MultiShopSelectorContext } from "../context/MultiShopSelectorContext";
import { MerchantSelectorContext } from "../context/MerchantSelectorContext";

/**
 * Hook to get user permissions for the App component
 * @return {Object} Permissions
 */
export default function useAuth() {
  const { localStorageKey: localStorageSingleShopKey } = useContext(SingleShopSelectorContext);
  const { localStorageKey: localStorageMultiShopKey } = useContext(MultiShopSelectorContext);
  const { localStorageKey: localStorageMerchantKey } = useContext(MerchantSelectorContext);

  let jwtToken = "";

  const [getViewer, { data: viewerData }] = useLazyQuery(viewerQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      // Can't find any more reliable way to check the status code from within this hook
      if (typeof error.message === "string" && error.message.includes("Received status code 401")) {
        // Token is expired or user was deleted from database
        Auth.signOut();
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  useEffect(() => {
    const getJwtToken = async () => {
      jwtToken = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      setAccessToken(jwtToken);
      if (jwtToken) getViewer();
    };

    getJwtToken();
  }, [jwtToken, getViewer]);

  const logout = () => {
    // Clear the selected shops from localStorage. Important, otherwise next
    // account to login might have saved shops that aren't even his/hers
    localStorage.removeItem(localStorageSingleShopKey);
    localStorage.removeItem(localStorageMultiShopKey);
    localStorage.removeItem(localStorageMerchantKey);
    Auth.signOut();
  };

  const payload = viewerData ? viewerData.viewer : null;

  return {
    logout,
    viewer: {
      ...payload,
      hydrate: getViewer, // function that refetches viewer and passes it down to all plugins
    },
  };
}
