import gql from "graphql-tag";
import { Shopper, ShopperInvitation } from "./fragments";

export const all = gql`
query shoppers($shopIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: ShopperSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput) {
  shoppers(shopIds: $shopIds, first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ${Shopper}
    }
    totalCount
  }
}
`;

export const one = gql`
  query shopper($id: ID!) {
    shoppers(shopperIds: [$id]) {
      nodes {
        ${Shopper}
      }  
    }
  }
`;

export const shopperInvitations = gql`
query shopperInvitations($first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: ShopperInvitationSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput) {
  shopperInvitations(first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ${ShopperInvitation}
    }
    totalCount
  }
}
`;

export const purchases = gql`
query purchases($id: ID!, $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: ShopperInvitationSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput) {
  ordersByShopper(accountId: $id, first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      _id
      status
      payments {
        amount {
          amount
        }
      }
      shop {
        _id
        name
        company {
          _id
          name
        }
      }
      createdAt
      referenceId
    }
    totalCount
  }
}
`;