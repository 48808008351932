import gql from "graphql-tag";
import { Shop, ShopAfterCreation } from "./fragments";

export const createShopMutation = gql`
  mutation createShop($input: CreateShopInput!) {
    createShop(input: $input) {
      shop {
        ${ShopAfterCreation}
      }
    }
  }
`;

export const updateShopMutation = gql`
  mutation updateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      shop {
        ${ShopAfterCreation}
      }
    }
  }
`;

export const removeShopMutation = gql`
  mutation removeShop($id: ID!) {
    removeShop(id: $id) {
      shop {
        ${Shop}
      }
    }
  }
`;

export const addMerchantRequestMutation = gql`
  mutation addMerchantRequest($input: AddMerchantRequestInput!) {
    addMerchantRequest(input: $input) {
      _id
    }
  }
`;

export const removeMerchantRequestMutation = gql`
  mutation removeMerchantRequest($id: ID!) {
    removeMerchantRequest(id: $id) {
      _id
    }
  }
`;

export const addProductCategoryMutation = gql`
  mutation AddProductCategory($name: String!) {
    addProductCategory(name: $name) {
      _id
      name
    }
  }
`;

export const addProductBrandMutation = gql`
  mutation AddProductBrand($name: String!) {
    addProductBrand(name: $name) {
      _id
      name
    }
  }
`;

export const setShopSubscriptionOverrides = gql`
  mutation setShopSubscriptionOverrides(
    $id: ID!,
    $rateOverride: OverrideByPercentageInput,
    $commissionOverride: OverrideByNewValueInput,
  ) {
    setShopSubscriptionOverrides(
      id: $id,
      rateOverride: $rateOverride,
      commissionOverride: $commissionOverride
    ) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
      }
      commission {
        newValue
        startDate
        endDate
        disabled
      }
    }
  }
`;
