import { subscriptionHistoryRecords } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";

const Subscriptions = ({ dateRange, companyIds, viewerType }) => {
  const shouldfetchSubscriptions = Array.isArray(companyIds) && companyIds.length;

  const fields = [
    {
      type: "string",
      name: "_id",
      label: "Record ID",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "date",
      label: "Created On",
      isSortable: true,
    },
    ...(viewerType === "godmode"
      ? [
          {
            type: "string",
            name: "companyName",
            label: "Merchant",
            isSortable: "companyId",
          },
        ]
      : []),
    {
      type: "string",
      name: "type",
      label: "Change or Renewal",
      isSortable: true,
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      isSortable: true,
    },
    {
      type: "string",
      name: "paymentStatus",
      label: "Status",
      isSortable: true,
    },
    {
      type: "string",
      name: "paymentAmount",
      label: "Amount (USD)",
      isSortable: true,
    },
  ];

  return (
    <CRUDer
      showDateRangeFilter={false}
      crud={{ state: "display" }}
      handlers={{}}
      strings={{
        displayer: {
          filterPlaceholder: "Filter Subscription Records by ID",
        },
      }}
      fields={{ display: fields }}
      apis={{
        readAll: shouldfetchSubscriptions
          ? {
              gql: subscriptionHistoryRecords,
              formatInput: () => ({
                companyIds,
                fromDate: dateRange?.startDate?.toISOString() || null,
                toDate: dateRange?.endDate?.toISOString() || null,
              }),
              formatOutput: output => ({
                ...output?.subscriptionHistoryRecords,
                nodes: output?.subscriptionHistoryRecords.nodes.map(record => ({
                  ...record,
                  companyName: record.company.name,
                })),
              }),
            }
          : undefined,
        actions: [],
      }}
    ></CRUDer>
  );
};

export default Subscriptions;
