import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { Card, CardContent, makeStyles, Button } from "@material-ui/core";
import DataTable, { useDataTable } from "@reactioncommerce/catalyst/DataTable";
import { getAccountAvatar } from "../helpers/helpers";

import { useSnackbar } from "notistack";

import EmailIcon from "@material-ui/icons/Email";
import CancelIcon from "@material-ui/icons/Cancel";

import invitationsQuery from "../graphql/queries/invitations";
import resendEmailMutation from "../graphql/mutations/resendEmailMutation";
import cancelInvitationMutation from "../graphql/mutations/cancelInvitationMutation";

const useStyles = makeStyles(() => ({
  card: {
    marginTop: "1rem",
  },
}));

/**
 * @summary Main invitations view
 * @name Invitations
 * @returns {React.Component} A React component
 */
function Invitations({ shopIds }) {
  const apolloClient = useApolloClient();

  // React-Table state
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [tableData, setTableData] = useState([]);

  const { t } = useTranslation();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // Create and memoize the column data
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: account => getAccountAvatar(account),
        id: "profilePicture",
      },
      {
        Header: t("admin.invitationsTable.header.email"),
        accessor: row => row.email,
        id: "email",
      },
      {
        Header: t("admin.invitationsTable.header.invitedBy"),
        accessor: row => row.invitedBy.emailRecords[0].address,
        id: "invitedBy",
      },
      {
        Header: t("admin.invitationsTable.header.shop"),
        accessor: row => row.shop?.name,
        id: "shop",
      },
      {
        Header: t("admin.invitationsTable.header.groups"),
        accessor: row =>
          row.groups.reduce((allGroups, group) => `${allGroups}, ${group.name}`, "").substring(2),
        id: "groups",
      },
      {
        Header: "Resend Email",
        accessor: row => (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await apolloClient.mutate({
                mutation: resendEmailMutation,
                variables: {
                  invitationId: row._id,
                },
              });

              enqueueSnackbar("Resent email!", { variant: "success" });
            }}
          >
            <EmailIcon />
          </Button>
        ),
        id: `resendEmailButton`,
      },
      {
        Header: "Cancel Invitation",
        accessor: row => (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await apolloClient.mutate({
                mutation: cancelInvitationMutation,
                variables: {
                  invitationId: row._id,
                },
              });

              enqueueSnackbar("Cancelled Invitation!", { variant: "success" });
            }}
          >
            <CancelIcon />
          </Button>
        ),
        id: `cancelInvitationButton`,
      },
    ],
    []
  );

  const onFetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      // Wait for shop id to be available before fetching products.
      setIsLoading(true);

      const { data } = await apolloClient.query({
        query: invitationsQuery,
        variables: {
          shopIds: shopIds,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
        },
        fetchPolicy: "network-only",
      });

      // Update the state with the fetched data as an array of objects and the calculated page count
      setTableData(data.invitations.nodes);
      setPageCount(Math.ceil(data.invitations.totalCount / pageSize));

      setIsLoading(false);
    },
    [apolloClient, shopIds]
  );

  const dataTableProps = useDataTable({
    columns,
    data: tableData,
    isFilterable: false,
    pageCount,
    onFetchData,
    getRowId: row => row._id,
  });

  return (
    <Card className={classes.card}>
      <CardContent>
        <DataTable {...dataTableProps} isLoading={isLoading} />
      </CardContent>
    </Card>
  );
}

Invitations.propTypes = {
  shopIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Invitations;
