import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { gdprUpdateTermsMutation, gdprDistortMutation } from "../graphql/mutations.js";
import { gdprQuery } from "../graphql/queries.js";
import { Divider, Typography } from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import { TextField, Button } from "@reactioncommerce/catalyst";
import styled from "styled-components";
import ShopperSelectorWithData from "../../../package/src/ShopperSelectorWithData";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

function GdprSettingsPage({ viewer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [isDistortingGlobally, setIsDistortingGlobally] = useState(false);
  const [isDistortingSpecificShoppers, setIsDistortingSpecificShoppers] = useState(false);
  const [terms, setTerms] = useState("");
  const [shopperSelection, setShopperSelection] = useState([]);

  const shopIds =
    (viewer?.adminUIShops &&
      viewer.adminUIShops.length &&
      viewer.adminUIShops.map(shop => shop._id)) ||
    [];

  const { loading, data, refetch } = useQuery(gdprQuery, {
    variables: {},
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    setTerms(data?.gdpr?.terms || "");
  }, [data]);

  const [gdprUpdateTerms] = useMutation(gdprUpdateTermsMutation, {
    onCompleted(message) {
      enqueueSnackbar("Successfully updated GDPR Terms", { variant: "success" });

      // We should refetch the text so `terms` state gets updated and Save button
      // gets disabled again
      refetch();
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  const [gdprDistort] = useMutation(gdprDistortMutation, {
    onCompleted({ gdprDistort }) {
      if (!gdprDistort) return;
      enqueueSnackbar(gdprDistort, { variant: "success" });
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  const save = async () => {
    setIsSaving(true);
    await gdprUpdateTerms({
      variables: {
        input: { terms },
      },
    });
    setIsSaving(false);
  };

  const distortSpecificShoppers = async () => {
    let variables = {};

    if (shopperSelection) {
      variables = {
        accountIds: shopperSelection.map(shopper => shopper.value),
      };
    }

    setIsDistortingSpecificShoppers(true);
    await gdprDistort({ variables });
    setIsDistortingSpecificShoppers(false);
  };

  const distortGlobally = async () => {
    setIsDistortingGlobally(true);
    await gdprDistort({ variables: {} });
    setIsDistortingGlobally(false);
  };

  if (loading) return "Loading...";

  return (
    <div style={{ paddingBottom: "40px" }}>
      <Typography variant="h3" style={{ paddingBottom: "10px" }}>
        GDPR
      </Typography>
      <TextField
        value={terms}
        label="Terms and Conditions"
        placeholder="Terms and Conditions"
        multiline
        rows={15}
        onChange={e => setTerms(e.target.value)}
      />
      <CardActions disableSpacing>
        <Button
          disabled={data?.gdpr?.terms === terms}
          isWaiting={isSaving}
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </CardActions>

      <br />
      <Divider />
      <br />

      <Typography variant="h3" style={{ paddingBottom: "10px" }}>
        GDPR Shopper Distortion
      </Typography>
      <Typography variant="body1" style={{ paddingBottom: "5px" }}>
        Distort the data of specific Shoppers.
      </Typography>
      <CardActions style={{ paddingLeft: "0px" }}>
        <ShopperSelectorWithData
          shouldShowEmail
          shopIds={shopIds}
          value={shopperSelection}
          onSelection={setShopperSelection}
        />
        <Button
          isWaiting={isDistortingSpecificShoppers}
          variant="contained"
          color="primary"
          onClick={distortSpecificShoppers}
        >
          Distort
        </Button>
      </CardActions>

      <br />
      <Divider />
      <br />

      <Typography variant="h3" style={{ paddingBottom: "10px" }}>
        GDPR Global Distortion
      </Typography>
      <Typography variant="body1" style={{ paddingBottom: "5px" }}>
        Click the below button in order to distort the data of ALL inactive users. A user is deemed
        inactive when we don't hear from them for at least 30 days straight.
      </Typography>
      <CardActions disableSpacing>
        <Button
          isWaiting={isDistortingGlobally}
          variant="contained"
          color="primary"
          onClick={distortGlobally}
        >
          Distort
        </Button>
      </CardActions>
    </div>
  );
}

export default GdprSettingsPage;
