import gql from "graphql-tag";
import discountCodeFragment from "../Fragments/DiscountCodeCommon";

export default gql`
  query discountCodeQuery($id: ID!) {
    discountCode(id: $id) {
      _id
      code
      discount
      label
      calculation {
        method
      }
      conditions {
        enabled
        accountLimit
        redemptionLimit
        audience
        shopperDetails {
          _id
          name
          primaryEmailAddress
        }
        products
        productDetails {
          _id
          title
          shop {
            _id
            name
          }
        }
        order {
          endDate
          startDate
        }
      }
      description
      discountMethod
    }
  }
`;
