import React, { useState } from "react";
import { Card, CardHeader, CardContent, Box, IconButton } from "@material-ui/core";
import Pencil from "mdi-material-ui/Pencil";
import Chip from "@reactioncommerce/catalyst/Chip";
import useProduct from "../hooks/useProduct";
import RelatedProductsSelectorDialog from "../components/RelatedProductsSelector/RelatedProductsSelectorDialog";

export default function() {
  const { product, refetchProduct } = useProduct();
  const [isRelatedProductsDialogOpen, setRelatedProductsDialogOpen] = useState(false);

  let content;

  if (product && Array.isArray(product.relatedProducts)) {
    content = product.relatedProducts.map(relatedProduct => (
      <Box style={{ marginRight: 4 }} key={relatedProduct._id}>
        <Chip variant="outlined" color="primary" label={relatedProduct.title} />
      </Box>
    ));
  }

  return (
    <Card style={{ overflow: "visible" }}>
      <CardHeader
        action={
          <IconButton
            onClick={() => setRelatedProductsDialogOpen(true)}
            title="Add related products"
          >
            <Pencil />
          </IconButton>
        }
        title={"Related Products"}
      />
      <CardContent>
        <Box display="flex">{content}</Box>
      </CardContent>
      {product && (
        <RelatedProductsSelectorDialog
          productId={product._id}
          isOpen={isRelatedProductsDialogOpen}
          onClose={() => {
            refetchProduct();
            setRelatedProductsDialogOpen(false);
          }}
          initialRelatedProducts={product.relatedProducts}
          shopId={product.shop._id}
        />
      )}
    </Card>
  );
}
