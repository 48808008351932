import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import SaleIcon from "mdi-material-ui/Sale";
import DiscountCodesPage from "./pages/DiscountCodesPage.js";
import CreateDiscountCodePage from "./pages/CreateDiscountCodePage.js";
import EditDiscountPage from "./pages/EditDiscountPage.js";
import CouponTriggersPage from "./pages/CouponTriggersPage.js";
import CreateCouponTriggerPage from "./pages/CreateCouponTriggerPage.js";
import EditCouponTriggerPage from "./pages/EditCouponTriggerPage.js";
import RatesPage from "./pages/RatesPage.js";
import CreateRatePage from "./pages/CreateRatePage.js";
import EditRatePage from "./pages/EditRatePage.js";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Coupon Codes
  registerRoute({
    group: "navigation",
    path: "/discounts",
    priority: 67,
    MainComponent: DiscountCodesPage,
    IconComponent: LoyaltyIcon,
    navigationItemLabel: "Coupons",
    showSingleShopSelector: true,
    collapse: {
      title: "Discounts",
      IconComponent: MoneyOffIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/discounts/create/:shopId",
    MainComponent: CreateDiscountCodePage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/discounts/edit/:shopId/:discountCodeId",
    MainComponent: EditDiscountPage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  // Coupon Triggers
  registerRoute({
    group: "navigation",
    path: "/coupon-triggers",
    priority: 67,
    MainComponent: CouponTriggersPage,
    IconComponent: AccountBalanceWalletIcon,
    navigationItemLabel: "Coupon Triggers",
    showSingleShopSelector: true,
    collapse: {
      title: "Discounts",
      IconComponent: MoneyOffIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/coupon-triggers/create/:shopId",
    MainComponent: CreateCouponTriggerPage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/coupon-triggers/edit/:shopId/:couponTriggerId",
    MainComponent: EditCouponTriggerPage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  // Sale
  registerRoute({
    group: "navigation",
    path: "/sale",
    priority: 67,
    MainComponent: RatesPage,
    IconComponent: SaleIcon,
    navigationItemLabel: "Sale",
    showSingleShopSelector: true,
    collapse: {
      title: "Discounts",
      IconComponent: MoneyOffIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/sale/create/:shopId",
    MainComponent: CreateRatePage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/sale/edit/:shopId/:discountCodeId",
    MainComponent: EditRatePage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });
}
