import ProductBrandIcon from "mdi-material-ui/Medal";
import FolderMultipleImageIcon from "mdi-material-ui/FolderMultipleImage";
import MediaGalleryPage from "./pages/MediaGalleryPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 40,
    path: "/media-gallery",
    MainComponent: MediaGalleryPage,
    IconComponent: FolderMultipleImageIcon,
    navigationItemLabel: "Media Gallery",
    showSingleShopSelector: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });
}
