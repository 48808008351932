import React from "react";
import PropTypes from "prop-types";

// GraphQL
import { subscriptionTypes, subscriptionType } from "../graphql/queries";
import {
  addSubscriptionType,
  updateSubscriptionType,
  removeSubscriptionType,
} from "../graphql/mutations";

// Components
import CRUDer from "../../../package/src/CRUDer";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";

import Toolbar from "../../../package/src/Toolbar";

// Component
const SubscriptionTypesPage = props => {
  // Props
  const { match, history } = props;

  const subscriptionTypeId = match?.params?.subscriptionTypeId || null;
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  const fields = [
    {
      type: "string",
      name: "name",
      label: "Name",
      placeholder: "e.g. Standard",
      isSortable: true,
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "This is a description",
      optional: true,
      isSortable: true,
    },
  ];

  const displayFields = [
    ...fields,
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Subscription Types" />
      <CRUDer
        crud={{ state: crudState, id: subscriptionTypeId }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`/subscription-types/edit/${id}`);
          },
          goToCreatePage: () => {
            history.push(`/subscription-types/create`);
          },
          goToDisplayPage: () => {
            history.push(`/subscription-types`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Subscription Type",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Subscription Types by name",
          },
          creator: {
            formTitle: "Create Subscription Type",
            buttonText: "Add Subscription Type",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Subscription Type",
            buttonText: "Save Subscription Type",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: fields,
          create: fields,
          display: displayFields,
        }}
        apis={{
          create: {
            gql: addSubscriptionType,
            formatInput: input => ({
              input,
            }),
            formatOutput: output => output?.addSubscriptionType,
          },
          readOne: {
            gql: subscriptionType,
            formatInput: id => {
              return { id };
            },
            formatOutput: output => output?.subscriptionType,
          },
          readAll: {
            gql: subscriptionTypes,
            formatInput: () => {},
            formatOutput: output => output?.subscriptionTypes,
          },
          update: {
            gql: updateSubscriptionType,
            formatInput: (id, input) => ({
              id,
              input: {
                name: input.name,
                description: input.description,
              },
            }),
            formatOutput: output => output?.updateSubscriptionType,
          },
          actions: [
            {
              label: "Delete",
              gql: removeSubscriptionType,
              formatInput: id => ({ id }),
              formatOutput: output => output?.removeSubscriptionType,
            },
          ],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

SubscriptionTypesPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionTypeId: PropTypes.string,
    }),
  }),
  subscriptionTypeId: PropTypes.string,
};

export default SubscriptionTypesPage;
