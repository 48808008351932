import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { currentSubscriptionPackage, subscriptionPackages } from "../graphql/queries";
import { subscribe } from "../graphql/mutations";
import { useConfirmDialog } from "@reactioncommerce/catalyst";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Box,
  Collapse,
  Chip,
  Divider,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import Button from "@reactioncommerce/catalyst/Button";
import Toolbar from "../../../package/src/Toolbar";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
  },
  type: {
    fontSize: 13,
    marginBottom: 12,
  },
  description: {
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12,
    lineHeight: "130%",
    height: 40,
    overflow: "hidden",
  },
  rate: {
    fontSize: 23,
  },
  additionalInfo: {
    marginTop: 22,
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child": {
      marginRight: "5em",
    },
  },
  subsectionTitle: {
    fontSize: 14,
    marginBottom: 12,
  },
  pricingField: {
    display: "flex",
    justifyContent: "space-between",
  },
  actions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  footer: {
    margin: "10 10 10 10",
  },
});

const PackageCard = props => {
  const {
    info: {
      _id,
      title,
      description,
      type,
      rate,
      duration,
      additionalStandardRate,
      additionalPremiumRate,
      commissionStandard,
      commissionPremium,
      isSubscribedTo,
      onSubscriptionChange,
    },
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const [expanded, setExpanded] = useState(true);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const toggleExpansionState = () => {
    setExpanded(prev => !prev);
  };

  async function subscribeTo() {
    openConfirmationDialog();
  }

  const {
    openDialog: openConfirmationDialog,
    ConfirmDialog: ActionConfirmationDialog,
  } = useConfirmDialog({
    title: "Switch Subscription Package",
    message:
      "Are you sure you want to switch your subscription package? Note that this will terminate your current package without a refund. If you have an active Credit Card, it will be billed automatically.",
    onConfirm: async () => {
      setIsSubscribing(true);
      const { errors: subscribeErrors } = await apolloClient.mutate({
        mutation: subscribe,
        variables: {
          id: _id,
        },
        errorPolicy: "all",
      });
      setIsSubscribing(false);

      if (subscribeErrors && subscribeErrors.length) {
        return enqueueSnackbar(subscribeErrors[0].message, {
          variant: "error",
        });
      }

      if (onSubscriptionChange) onSubscriptionChange();
    },
  });

  return (
    <Card>
      <ActionConfirmationDialog />
      <CardContent>
        <Box className={classes.header}>
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          {isSubscribedTo ? (
            <Chip color="primary" variant="outlined" size="small" label="Subscribed" />
          ) : null}
        </Box>
        <Typography className={classes.type} color="textSecondary">
          {type}
        </Typography>

        <Divider />

        <Typography className={classes.description} component="p">
          {description}
        </Typography>
        <Typography className={classes.rate} color="textPrimary" component="p">
          ${rate}/{duration} days
        </Typography>
        <Collapse in={expanded}>
          <Box className={classes.additionalInfo}>
            <Box>
              <Typography className={classes.subsectionTitle} color="textSecondary" component="p">
                Additional Rate for Shop *
              </Typography>
              <Box className={classes.pricingField}>
                <span>Standard</span>
                <span>${additionalStandardRate}</span>
              </Box>
              <Box className={classes.pricingField}>
                <span>Premium</span>
                <span>${additionalPremiumRate}</span>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.subsectionTitle} color="textSecondary" component="p">
                Commission Per Shop
              </Typography>
              <Box className={classes.pricingField}>
                <span>Standard</span>
                <span>{commissionStandard}%</span>
              </Box>
              <Box className={classes.pricingField}>
                <span>Premium</span>
                <span>{commissionPremium}%</span>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </CardContent>

      <CardActions>
        <Box className={classes.actions}>
          {/* <Button onClick={toggleExpansionState} variant="text" size="small">
            Show {expanded ? "less" : "more"}
          </Button> */}
          <div></div>
          <Button
            isWaiting={isSubscribing}
            onClick={subscribeTo}
            disabled={isSubscribedTo}
            variant="contained"
            color="primary"
            size="small"
          >
            Subscribe
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

const SubscriptionsPage = props => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();

  const [packages, setPackages] = useState([]);
  const [currentPackageID, setCurrentPackageID] = useState([]);

  useEffect(() => {
    async function execute() {
      const { data: packagesData, errors: packagesFetchErrors } = await apolloClient.query({
        query: subscriptionPackages,
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      const { data: packageData, errors: packageFetchErrors } = await apolloClient.query({
        query: currentSubscriptionPackage,
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      if (packageFetchErrors && packageFetchErrors.length) {
        return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      setCurrentPackageID(packageData?.currentSubscriptionPackage?._id);

      setPackages(
        packagesData.subscriptionPackages.nodes.map(subscriptionPackage => ({
          ...subscriptionPackage,
          type: subscriptionPackage.subscriptionType.name,
        }))
      );
    }
    execute();
  }, []);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Subscription" />
      <Grid container spacing={2}>
        {packages.map(packageInfo => (
          <Grid key={packageInfo._id} item xs={6} sm={6} md={6} lg={6} xl={3}>
            <PackageCard
              info={{
                ...packageInfo,
                isSubscribedTo: currentPackageID === packageInfo._id,
                onSubscriptionChange: () => {
                  setCurrentPackageID(packageInfo._id);
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography style={{ marginTop: 30 }} align="center" color="textSecondary" size="small">
        *First shop is free. This rate is added to the base rate for every extra shop.
      </Typography>
    </MuiThemeProvider>
  );
};

export default SubscriptionsPage;
