import BusinessIcon from "@material-ui/icons/Business";
import MerchantsPage from "./pages/MerchantsPage";
import SettingsDashboard from "../../package/src/SettingsDashboard";
import EditMerchantSettingsPage from "./pages/EditMerchantSettingsPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerSetting }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 68,
    path: "/merchants",
    MainComponent: MerchantsPage,
    IconComponent: BusinessIcon,
    navigationItemLabel: "Merchants",
    showShopSelector: false,
    godmodeOnly: true,
  });

  registerRoute({
    path: "/merchants/create",
    MainComponent: MerchantsPage,
    showShopSelector: false,
    godmodeOnly: true,
  });

  registerRoute({
    path: "/merchants/edit/:companyId",
    MainComponent: MerchantsPage,
    showShopSelector: false,
    godmodeOnly: true,
  });

  registerSetting({
    name: "merchant",
    group: "settings",
    priority: 70,
    LayoutComponent: SettingsDashboard,
    MainComponent: EditMerchantSettingsPage,
    navigationItemLabel: "Company Info",
  });
}
