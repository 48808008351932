import React, { Fragment, useState } from "react";
import { useSnackbar } from "notistack";

import Button from "@reactioncommerce/catalyst/Button";

import { makeStyles } from "@material-ui/core";

import useGroups from "../hooks/useGroups";
import useAccountTypesSlugs from "../hooks/useAccountTypesSlugs";

import Displayer from "../../../../package/src/CRUDer/Displayer";

import CreateOrEditGroupDialog from "./CreateOrEditGroupDialog";
import InviteShopMemberDialog from "./InviteShopMemberDialog";

import accountsQuery from "../graphql/queries/accounts";
import suspendAccountMutation from "../graphql/mutations/suspendAccountMutation.js";
import enableAccountMutation from "../graphql/mutations/enableAccountMutation.js";
import resetStaffPassword from "../graphql/mutations/resetStaffPassword";

import ApproveIcon from "mdi-material-ui/CheckCircleOutline";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import { constants } from "../../../../constants";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginTop: "1rem",
  },
  actionButton: {
    marginLeft: ".5rem",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  approveAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
  declineAction: {
    color: theme.palette.colors.forestGreen300,
    width: "100%", // centers it in the cell
  },
}));

const GroupsDisplayer = props => {
  const { shopIds, viewer } = props;

  if (shopIds.length !== 1) {
    return <p>Please select 1 and only 1 shop.</p>;
  }

  const [isCreateGroupDialogVisible, setCreateGroupDialogVisibility] = useState(false);
  const [isInviteShopMemberDialogVisible, setInviteShopMemberDialogVisibility] = useState(false);

  const { isLoadingGroups, groups, refetchGroups } = useGroups(shopIds[0]);

  const { accountTypes } = useAccountTypesSlugs();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const godmode = viewer?.type === "godmode";

  // Only oBuy Admins and Merchant Admins can invite other staff members
  const canInviteStaffMembers = !!viewer?.groups?.nodes.find(
    group => group.slug === "godmode-admin" || group.slug === "merchant-admin"
  );

  return (
    <Fragment>
      {shopIds.length === 0 ? <p>You need to select at least one shop for data to appear</p> : ""}

      <CreateOrEditGroupDialog
        isOpen={isCreateGroupDialogVisible}
        onSuccess={refetchGroups}
        onClose={() => setCreateGroupDialogVisibility(false)}
        shopId={shopIds[0]}
      />
      <InviteShopMemberDialog
        isOpen={isInviteShopMemberDialogVisible}
        onSuccess={() =>
          enqueueSnackbar("Successfully invited member", {
            variant: "success",
          })
        }
        onClose={() => setInviteShopMemberDialogVisibility(false)}
        shopId={shopIds[0]}
        allShops={viewer.adminUIShops}
        accountTypes={accountTypes.filter(at => at.slug !== "standard-permissions")}
        godmode={godmode}
      />

      <div style={{ padding: "10px" }}>
        {canInviteStaffMembers && (
          <Button
            className={classes.actionButton}
            color="primary"
            variant="contained"
            onClick={() => setInviteShopMemberDialogVisibility(true)}
          >
            {"Invite staff member"}
          </Button>
        )}
      </div>

      {!isLoadingGroups && (
        <Displayer
          showDateRangeFilter={false}
          showExportButton={false}
          fields={[
            {
              type: "string",
              name: "name",
              label: "Name",
            },
            {
              type: "string",
              name: "email",
              label: "Email",
            },
            {
              type: "string",
              name: "phoneNumber",
              label: "Phone Number",
            },
            {
              type: "string",
              name: "groupName",
              label: "Group Name",
            },
            {
              type: "string",
              name: "shopName",
              label: "Shop Name",
            },
            {
              type: "dateTime",
              name: "createdAt",
              label: "Created On",
            },
            {
              type: "string",
              name: "disabled",
              label: "Enabled",
            },
          ]}
          readAll={{
            gql: accountsQuery,
            formatInput: () => ({
              groupIds: godmode
                ? groups.map(group => group._id) // if godmode return all groups
                : groups.filter(group => !group.slug.includes("godmode")).map(group => group._id), // if not, return non godmode groups
            }),
            formatOutput: output => {
              let data = [];

              output.accountsWithFilters.nodes.map(account => {
                let accountGroups = [];

                account.groups.nodes.map(group => {
                  if (groups.map(group => group._id).includes(group._id)) {
                    const groupName = group?.slug.includes("godmode")
                      ? `${constants.APP_NAME} ${group?.name}`
                      : group?.name || "";
                    const shopName = group?.shop?.name || "";

                    accountGroups.push({
                      groupName,
                      shopName,
                    });
                  }
                });

                accountGroups.map(group => {
                  data.push({
                    ...group,
                    email: account.emailRecords[0].address,
                    phoneNumber: account.phoneNumber,
                    name: account.name,
                    _id: account._id,
                    createdAt: account.createdAt,
                    disabled: account?.disabled ? (account.disabled ? "No" : "Yes") : "Yes",
                  });
                });
              });

              return {
                nodes: data,
              };
            },
          }}
          actions={[
            {
              label: "Reset Password",
              gql: resetStaffPassword,
              formatInput: id => ({ id }),
              formatOutput: output => output.resetAdminPassword,
              onSuccess: () =>
                enqueueSnackbar("Successfully reset password", { variant: "success" }),
              shouldAppearInTable: true,
              IconComponent: RotateLeftIcon,
            },
            {
              label: "Enable",
              gql: enableAccountMutation,
              formatInput: id => ({
                id,
                state: constants.MERCHANT_REQUESTS.STATE.DECLINED,
              }),
              formatOutput: output => output.handleMerchantRequest,
              onSuccess: viewer.hydrate,
              shouldAppearInTable: true,
              IconComponent: ApproveIcon,
              className: classes.declineAction,
            },
            {
              label: "Suspend",
              gql: suspendAccountMutation,
              formatInput: id => ({
                id,
                state: null,
              }),
              formatOutput: output => output.handleMerchantRequest,
              onSuccess: viewer.hydrate,
              shouldAppearInTable: true,

              IconComponent: DeclineIcon,
              className: classes.approveAction,
            },
          ]}
        />
      )}
    </Fragment>
  );
};

export default GroupsDisplayer;
