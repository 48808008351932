import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SimpleSchema from "simpl-schema";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import muiOptions from "reacto-form/cjs/muiOptions";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

import usePrimaryShopId from "../hooks/usePrimaryShopId";

import Divider from "@material-ui/core/Divider";

import CloseIcon from "mdi-material-ui/Close";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Button from "@reactioncommerce/catalyst/Button";
import TextField from "@reactioncommerce/catalyst/TextField";
import Select from "@reactioncommerce/catalyst/Select";

import {
  Box,
  Grid,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import inviteShopMemberMutation from "../graphql/mutations/inviteShopMember";

import { constants } from "../../../../constants.js";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
}));

const formSchema = new SimpleSchema({
  firstName: {
    type: String,
  },
  lastName: {
    type: String,
  },
  email: {
    type: String,
    min: 3,
  },
  password: {
    type: String,
    min: 8,
  },
});
const validator = formSchema.getFormValidator();

/**
 * InviteShopMember component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function InviteShopMember({ isOpen, onClose, onSuccess, allShops, accountTypes, godmode }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedMerchantAccountTypes, setSelectedMerchantAccountTypes] = useState([]);
  const [selectedGodmodeAccountTypes, setSelectedGodmodeAccountTypes] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { primaryShopId } = usePrimaryShopId();

  const { t } = useTranslation();

  const [inviteShopMember] = useMutation(inviteShopMemberMutation, {
    ignoreResults: true,
    onCompleted() {
      setIsSubmitting(false);
      onClose();
      onSuccess();
    },
    onError(error) {
      setIsSubmitting(false);
      enqueueSnackbar(error.message || t("admin.groupCards.inviteStaffMemberDialog.saveFailed"), {
        variant: "error",
      });
    },
  });

  const { getFirstErrorMessage, getInputProps, hasErrors, isDirty, submitForm } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      await inviteShopMember({
        variables: {
          input: {
            email: formData.email,
            groupSlugs:
              selectedGodmodeAccountTypes?.length > 0
                ? selectedGodmodeAccountTypes.map(at => at.value)
                : selectedMerchantAccountTypes.map(at => at.value),
            shopIds:
              selectedGodmodeAccountTypes?.length > 0
                ? [primaryShopId]
                : selectedShops.map(s => s.value),
            firstName: formData.firstName,
            lastName: formData.lastName,
            shouldGetAdminUIAccess: true,
            password: formData.password,
          },
        },
      });

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
  });

  const handleSubmit = event => {
    event.preventDefault();
    submitForm();
  };

  const shouldShowMerchantTab = () => {
    return (godmode && currentTab === 1) || (!godmode && currentTab === 0);
  };

  const accountTypesForGodmodeSelect = [
    {
      label: constants.APP_NAME,
      options: accountTypes
        .filter(accountType => accountType.slug.includes("godmode"))
        .map(accountType => ({
          value: accountType.slug,
          label: `${constants.APP_NAME} ${accountType.name}`,
        })),
    },
  ];
  const accountTypesForMerchantSelect = [
    {
      label: "Merchants",
      options: accountTypes
        .filter(accountType => !accountType.slug.includes("godmode"))
        .map(accountType => ({
          value: accountType.slug,
          label: accountType.name,
        })),
    },
  ];

  // Let's go iver all the shops and create the array of objects with the extra
  // details such as the companyId and companyName
  const shopsForSelect =
    allShops?.map((shop, index) => ({
      value: shop._id,
      label: shop.name,
      companyId: shop.company._id,
      companyName: shop.company.name,
    })) || [];

  // The user cannot select two shops from different companies, so to make it
  // easier on the UI, we are grouping all the shops that belong to the same
  // company together
  const reducedShopsForSelect = [];

  shopsForSelect.map(currentShop => {
    // Find the index of the object for this particular companyName
    const currentShopCompanyNameIndex = reducedShopsForSelect.findIndex(
      s => s.label === currentShop.companyName
    );

    if (currentShopCompanyNameIndex === -1) {
      // If not found, we need to add it
      reducedShopsForSelect.push({ label: currentShop.companyName, options: [currentShop] });
    } else {
      // If found, all we need to do is add the currentShop to the options array
      reducedShopsForSelect[currentShopCompanyNameIndex].options.push(currentShop);
    }
  });

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <CardHeader
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={t("admin.groupCards.inviteStaffMemberDialog.title")}
      />
      <CardContent>
        <Grid container spacing={1} className={classes.cardContainer}>
          <Grid item sm={12}>
            <TextField
              error={hasErrors(["firstName"])}
              fullWidth
              helperText={getFirstErrorMessage(["firstName"])}
              label="First Name"
              {...getInputProps("firstName", muiOptions)}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              error={hasErrors(["lastName"])}
              fullWidth
              helperText={getFirstErrorMessage(["lastName"])}
              label="Last Name"
              {...getInputProps("lastName", muiOptions)}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              error={hasErrors(["email"])}
              fullWidth
              helperText={getFirstErrorMessage(["email"])}
              label={t("admin.groupCards.inviteStaffMemberDialog.email")}
              {...getInputProps("email", muiOptions)}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              error={hasErrors(["password"])}
              fullWidth
              type="password"
              helperText={getFirstErrorMessage(["password"])}
              label="Password"
              {...getInputProps("password", muiOptions)}
            />
          </Grid>

          <Divider style={{ width: "100%", marginTop: "10px" }} />

          <Tabs
            value={currentTab}
            style={{ margin: "15px" }}
            indicatorColor="primary"
            onChange={(event, value) => {
              setCurrentTab(value);
              setSelectedGodmodeAccountTypes([]);
              setSelectedShops([]);
              setSelectedMerchantAccountTypes([]);
            }}
          >
            {godmode && <Tab label={constants.APP_NAME} />}
            <Tab label={"Merchant"} />
          </Tabs>

          <Grid container spacing={0}>
            {godmode && currentTab === 0 && (
              <>
                <Grid item xs={12}>
                  <div style={{ paddingBottom: "8px" }}>{constants.APP_NAME} Groups</div>
                  <Select
                    isMulti
                    options={accountTypesForGodmodeSelect}
                    onSelection={accountTypeToSelect =>
                      setSelectedGodmodeAccountTypes(accountTypeToSelect)
                    }
                    placeholder={"Select Groups"}
                    value={selectedGodmodeAccountTypes}
                  />
                  <p>{constants.APP_NAME} staff members have visibility over all merchant shops.</p>
                </Grid>
              </>
            )}

            {shouldShowMerchantTab() && (
              <Grid item xs={12}>
                <div style={{ paddingBottom: "8px" }}>Groups</div>
                <Select
                  isMulti
                  options={accountTypesForMerchantSelect}
                  onSelection={accountTypeToSelect =>
                    setSelectedMerchantAccountTypes(accountTypeToSelect)
                  }
                  placeholder={"Select Groups"}
                  value={selectedMerchantAccountTypes}
                />

                <br />

                <div style={{ paddingBottom: "8px" }}>Shops</div>
                <Select
                  isMulti
                  options={reducedShopsForSelect}
                  onSelection={shopsToSelect => {
                    if (
                      !shopsToSelect?.length ||
                      (shopsToSelect?.length &&
                        shopsToSelect?.every(shop => shop.companyId === shopsToSelect[0].companyId))
                    ) {
                      setSelectedShops(shopsToSelect);
                    } else {
                      enqueueSnackbar("Cannot select shops from different merchants", {
                        variant: "error",
                      });
                    }
                  }}
                  placeholder={"Select Shops"}
                  value={selectedShops}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Box>
          <Button onClick={onClose}>{t("app.cancel")}</Button>
        </Box>
        <Button
          color="primary"
          disabled={isSubmitting || !isDirty}
          variant="contained"
          onClick={handleSubmit}
          type="submit"
        >
          {isSubmitting ? t("admin.settings.saveProcessing") : t("app.saveChanges")}
        </Button>
      </CardActions>
    </Dialog>
  );
}

InviteShopMember.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  shopId: PropTypes.string,
};

export default InviteShopMember;
