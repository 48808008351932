import React from "react";
import PropTypes from "prop-types";

// GraphQL
import { one, all, types } from "../graphql/queries";
import { add, update, remove } from "../graphql/mutations";

// Components
import CRUDer from "../../../package/src/CRUDer";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import { useQuery } from "@apollo/react-hooks";

import OverridePackage from "../additional-pages/OverridePackage";

import { constants } from "../../../constants";

import Toolbar from "../../../package/src/Toolbar";

// Component
const SubscriptionPackagesPage = props => {
  // Props
  const { match, history } = props;

  const Id = match?.params?.Id || null;
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  const { loading, error, data } = useQuery(types, {
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  let typeValues = data?.subscriptionTypes?.nodes.map(type => ({
    id: type._id,
    label: type.name,
    value: type._id,
  }));

  const allFields = [
    {
      type: "string",
      name: "title",
      label: "Title",
      placeholder: "e.g. Basic",
      isSortable: true,
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "This is a description.",
      optional: true,
      isSortable: true,
    },
    {
      type: "string",
      name: "subscriptionType",
      label: "Type",
      isSortable: "typeID",
    },
    {
      type: "dropdown",
      name: "typeID",
      label: "Type",
      dropdownValues: typeValues,
    },
    {
      type: "boolean",
      name: "isVisibleToMerchants",
      label: "Visibile for merchants",
      isSortable: true,
    },
    {
      type: "boolean",
      name: "allowsPremiumShops",
      label: "Premium Shops Allowed",
    },
    {
      type: "number",
      name: "baseRate",
      label: "Rate per period (USD)",
      placeholder: "20",
      description: "A value of 0 indicates that the package is free.",
      isSortable: true,
    },
    {
      type: "number",
      name: "durationInDays",
      label: "Period (in days)",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
    {
      type: "number",
      name: "additionalRatePerStandardShop",
      label: "Additional rate per extra standard shop (USD)",
      placeholder: "10",
      description:
        "This amount will be added to the package Rate (indicated above) for every additional standard shop that the merchant unlocks.",
    },
    {
      type: "number",
      name: "additionalRatePerPremiumShop",
      label: "Additional rate per extra premium shop (USD)",
      placeholder: "10",
      description:
        "This amount will be added to the package rate (indicated above) for every additional premium shop that the merchant unlocks.",
    },
    {
      type: "number",
      name: "commissionPercentageForStandardShops",
      label: "Commission for standard shop (%)",
      placeholder: "10",
      description: `${constants.APP_NAME}'s commission on sales in standard shops`,
    },
    {
      type: "number",
      name: "commissionPercentageForPremiumShops",
      label: "Commission for premium shop (%)",
      placeholder: "10",
      description: `${constants.APP_NAME}'s commission on sales in premium shops`,
    },
  ];

  const displayFields = [
    "title",
    "description",
    "subscriptionType",
    "isVisibleToMerchants",
    "baseRate",
    "durationInDays",
    "createdAt",
  ];
  const updateFields = [
    "title",
    "description",
    "typeID",
    "isVisibleToMerchants",
    "baseRate",
    "allowsPremiumShops",
    "isVisibleToMerchants",
    "durationInDays",
    "additionalRatePerPremiumShop",
    "additionalRatePerStandardShop",
    "commissionPercentageForPremiumShops",
    "commissionPercentageForStandardShops",
  ];
  const createFields = [
    "title",
    "description",
    "typeID",
    "isVisibleToMerchants",
    "baseRate",
    "allowsPremiumShops",
    "isVisibleToMerchants",
    "durationInDays",
    "additionalRatePerPremiumShop",
    "additionalRatePerStandardShop",
    "commissionPercentageForPremiumShops",
    "commissionPercentageForStandardShops",
  ];

  function formatPackageBulkDisplay(structure) {
    return {
      ...structure,
      nodes: structure.nodes.map(subscriptionPackage => ({
        ...subscriptionPackage,
        subscriptionType: subscriptionPackage.subscriptionType.name,
      })),
    };
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Subscription Packages" />
      <CRUDer
        crud={{ state: crudState, id: Id }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`/subscription-packages/edit/${id}`);
          },
          goToCreatePage: () => {
            history.push(`/subscription-packages/create`);
          },
          goToDisplayPage: () => {
            history.push(`/subscription-packages`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Subscription Package",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Subscription Packages by title",
          },
          creator: {
            formTitle: "Create Subscription Packages",
            buttonText: "Add Subscription Package",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Subscription Package",
            buttonText: "Save Subscription Package",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: allFields.filter(({ name }) => updateFields.includes(name)),
          create: allFields.filter(({ name }) => createFields.includes(name)),
          display: allFields.filter(({ name }) => displayFields.includes(name)),
        }}
        apis={{
          create: {
            gql: add,
            formatInput: input => ({
              input: {
                ...input,
                baseRate: Number(input.baseRate),
                durationInDays: Number(input.durationInDays),
                additionalRatePerStandardShop: Number(input.additionalRatePerStandardShop),
                additionalRatePerPremiumShop: Number(input.additionalRatePerPremiumShop),
                commissionPercentageForPremiumShops: Number(
                  input.commissionPercentageForPremiumShops
                ),
                commissionPercentageForStandardShops: Number(
                  input.commissionPercentageForStandardShops
                ),
              },
            }),
            formatOutput: output => output?.add,
          },
          readOne: {
            gql: one,
            formatInput: id => ({ id }),
            formatOutput: output => ({
              ...output?.subscriptionPackage,
              typeID: output?.subscriptionPackage.subscriptionType._id,
              baseRate: output?.subscriptionPackage.baseRate.toString(),
              durationInDays: output?.subscriptionPackage.durationInDays.toString(),
              additionalRatePerStandardShop: output?.subscriptionPackage.additionalRatePerStandardShop.toString(),
              additionalRatePerPremiumShop: output?.subscriptionPackage.additionalRatePerPremiumShop.toString(),
              commissionPercentageForPremiumShops: output?.subscriptionPackage.commissionPercentageForPremiumShops.toString(),
              commissionPercentageForStandardShops: output?.subscriptionPackage.commissionPercentageForStandardShops.toString(),
            }),
          },
          readAll: {
            gql: all,
            formatInput: () => {},
            formatOutput: output => formatPackageBulkDisplay(output?.subscriptionPackages),
          },
          update: {
            gql: update,
            formatInput: (id, input) => ({
              id,
              input: {
                title: input.title,
                description: input.description,
                typeID: input.typeID,
                baseRate: Number(input.baseRate),
                allowsPremiumShops: input.allowsPremiumShops,
                isVisibleToMerchants: input.isVisibleToMerchants,
                durationInDays: Number(input.durationInDays),
                additionalRatePerPremiumShop: Number(input.additionalRatePerPremiumShop),
                additionalRatePerStandardShop: Number(input.additionalRatePerStandardShop),
                commissionPercentageForPremiumShops: Number(
                  input.commissionPercentageForPremiumShops
                ),
                commissionPercentageForStandardShops: Number(
                  input.commissionPercentageForStandardShops
                ),
              },
            }),
            formatOutput: output => output?.update,
          },
          actions: [
            {
              label: "Delete",
              gql: remove,
              formatInput: id => ({ id }),
              formatOutput: output => output?.remove,
            },
          ],
        }}
        additionalTabs={[
          {
            label: "Overrides",
            Component: <OverridePackage history={history} subscriptionPackageId={Id} />,
          },
        ]}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

SubscriptionPackagesPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.string,
    }),
  }),
  Id: PropTypes.string,
};

export default SubscriptionPackagesPage;
