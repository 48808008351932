import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { shopSubscriptionOverrides } from "../graphql/queries";
import { setShopSubscriptionOverrides } from "../graphql/mutations";

import Divider from "@material-ui/core/Divider";

import { useApolloClient } from "@apollo/react-hooks";

import OverrideForm from "../../../package/src/OverrideForm/OverrideForm";

import { useSnackbar } from "notistack";

import { Box } from "@material-ui/core";

const PaddedDivider = styled(Divider)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;
`;

const OverridePackage = ({ shopId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const apolloClient = useApolloClient();

  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState({});

  async function submitOverrideForm(name, value) {
    setData({
      ...data,
      [name]: value,
    });
    const { errors: overrideFetchErrors } = await apolloClient.mutate({
      mutation: setShopSubscriptionOverrides,
      variables: {
        id: shopId,
        [`${name}Override`]: value,
      },
      errorPolicy: "all",
    });

    if (overrideFetchErrors && overrideFetchErrors.length) {
      return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
        variant: "error",
      });
    } else {
      return enqueueSnackbar(`Override for ${name} saved sucessfully.`, {
        variant: "success",
      });
    }
  }

  useEffect(() => {
    async function fetchOverridesAndShow() {
      const { data: overrideData, errors: overrideFetchErrors } = await apolloClient.query({
        query: shopSubscriptionOverrides,
        variables: {
          id: shopId,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      if (overrideFetchErrors && overrideFetchErrors.length) {
        return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      setData(overrideData.shopSubscriptionOverrides ?? {});
      setFetching(false);
    }

    fetchOverridesAndShow();
  }, []);

  return (
    <Box>
      {!fetching && (
        <Box>
          <OverrideForm
            title={"Rate"}
            type="percentage"
            initial={data.rate ?? {}}
            onSubmit={values => {
              submitOverrideForm("rate", values);
            }}
          />
          <PaddedDivider />
          <OverrideForm
            title={"Commission"}
            type="new-value"
            initial={data.commission ?? {}}
            onSubmit={values => {
              submitOverrideForm("commission", values);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default OverridePackage;
