import React from "react";
import CubeIcon from "mdi-material-ui/Cube";
import { FileRecord } from "@reactioncommerce/file-collections";
import ProductsTable from "./components/ProductsTable";
import ProductDetail from "./layouts/ProductDetail";
import ProductHeader from "./blocks/ProductHeader";
import ProductDetailForm from "./blocks/ProductDetailForm";
// import ProductMetadataForm from "./blocks/ProductMetadataForm";
import ProductSocialForm from "./blocks/ProductSocialForm";
import ProductTagForm from "./blocks/ProductTagForm";
import ProductBrandsAndCategoriesForm from "./blocks/ProductBrandsAndCategoriesForm";
import RelatedProductsForm from "./blocks/RelatedProductsForm";
// import ProductCategoryForm from "./blocks/ProductCategoryForm";
import ProductMediaForm from "./blocks/ProductMediaForm";
import VariantHeader from "./blocks/VariantHeader";
import VariantList from "./blocks/VariantList";
import VariantDetailForm from "./blocks/VariantDetailForm";
import VariantPricesForm from "./blocks/VariantPricesForm";
import VariantStockForm from "./blocks/VariantStockForm";
// import VariantTaxForm from "./blocks/VariantTaxForm";
import VariantMediaForm from "./blocks/VariantMediaForm";
import config from "../../config.js";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // TODO: This should live in a media plugin
  // Settings for file uploads
  FileRecord.downloadEndpointPrefix = "/assets/files";
  FileRecord.uploadEndpoint = "/assets/uploads";
  FileRecord.absoluteUrlPrefix = config.PUBLIC_FILES_BASE_URL;

  // Register routes
  registerRoute({
    LayoutComponent: null,
    MainComponent: ProductDetail,
    path: "/products/:shopId/:handle/:variantId?/:optionId?",
    showShopSelector: false,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  registerRoute({
    group: "navigation",
    priority: 30,
    layoutComponentProps: {
      size: "wide",
    },
    path: "/products",
    MainComponent: ProductsTable,
    IconComponent: CubeIcon,
    navigationItemLabel: "Product List",
    showShopSelector: true,
    collapse: {
      title: "Products",
      IconComponent: CubeIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  // Register blocks

  // ProductDetail Block: Sidebar
  registerBlock({
    region: "ProductDetailSidebar",
    name: "VariantList",
    Component: VariantList,
    priority: 10,
  });

  // ProductDetail Block: Header
  registerBlock({
    region: "ProductDetailHeader",
    name: "ProductHeader",
    // eslint-disable-next-line react/display-name
    Component: props => <ProductHeader shouldDisplayStatus={true} {...props} />,
    priority: 10,
  });

  // ProductDetail Block Region: Main
  registerBlock({
    region: "ProductDetailMain",
    name: "ProductHeader",
    Component: ProductHeader,
    priority: 10,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "ProductDetailForm",
    Component: ProductDetailForm,
    priority: 20,
  });

  // Media gallery card and form
  registerBlock({
    region: "ProductDetailMain",
    name: "ProductMediaForm",
    Component: ProductMediaForm,
    priority: 30,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "ProductSocialForm",
    Component: ProductSocialForm,
    priority: 40,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "ProductTagForm",
    Component: ProductTagForm,
    priority: 50,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "ProductBrandsForm",
    Component: ({ refreshToolbar }) => (
      <ProductBrandsAndCategoriesForm
        refreshToolbar={refreshToolbar}
        type={{ value: "brands", title: "Brands", selector: "productBrands" }}
      />
    ),
    priority: 53,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "ProductCategoriesForm",
    Component: ({ refreshToolbar }) => (
      <ProductBrandsAndCategoriesForm
        refreshToolbar={refreshToolbar}
        type={{
          value: "categories",
          title: "Categories",
          selector: "productCategories",
        }}
      />
    ),
    priority: 56,
  });

  registerBlock({
    region: "ProductDetailMain",
    name: "RelatedProductsForm",
    Component: RelatedProductsForm,
    priority: 60,
  });

  // registerBlock({
  //   region: "ProductDetailMain",
  //   name: "ProductMetadataForm",
  //   Component: ProductMetadataForm,
  //   priority: 60
  // });

  registerBlock({
    region: "VariantDetailSidebar",
    name: "VariantList",
    Component: VariantList,
    priority: 70,
  });

  // VariantDetail: Sidebar Region
  registerBlock({
    region: "VariantDetailHeader",
    name: "ProductHeader",
    Component: ProductHeader,
    priority: 10,
  });

  // VariantDetail: Main Region
  registerBlock({
    region: "VariantDetailMain",
    name: "VariantHeader",
    Component: VariantHeader,
    priority: 10,
  });

  registerBlock({
    region: "VariantDetailMain",
    name: "VariantDetailForm",
    Component: VariantDetailForm,
    priority: 20,
  });

  registerBlock({
    region: "VariantDetailMain",
    name: "VariantPricesForm",
    Component: VariantPricesForm,
    priority: 30,
  });

  registerBlock({
    region: "VariantDetailMain",
    name: "VariantStockForm",
    Component: VariantStockForm,
    priority: 40,
  });

  registerBlock({
    region: "VariantDetailMain",
    name: "VariantMediaForm",
    Component: VariantMediaForm,
    priority: 50,
  });

  // Move to tax plugin, or something
  // registerBlock({
  //   region: "VariantDetailMain",
  //   name: "VariantTaxForm",
  //   component: VariantTaxForm,
  //   priority: 50
  // });
}
