import CouponTriggerCreate from "../components/CouponTriggerCreate.js";
import Toolbar from "../../../package/src/Toolbar";

/**
 * @summary Renders discounts page
 * @param {Object} props Component props
 * @return {React.Node} React node
 */
export default function CreateCouponTriggerPage(props) {
  const shopId = props?.match?.params?.shopId;

  return (
    <>
      <Toolbar
        title="Create Coupon Trigger"
        onCancel={() => props.history.push("/coupon-triggers")}
      />

      <CouponTriggerCreate {...props} shopId={shopId} />
    </>
  );
}
