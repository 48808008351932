import gql from "graphql-tag";

export const gdprDistortMutation = gql`
  mutation gdprDistort($accountIds: [ID]) {
    gdprDistort(accountIds: $accountIds)
  }
`;

export const gdprUpdateTermsMutation = gql`
  mutation gdprUpdateTerms($input: GdprUpdateTermsInput!) {
    gdprUpdateTerms(input: $input) {
      terms
      createdAt
      updatedAt
    }
  }
`;
