import React, { useMemo, useState, useCallback, forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import moment from "moment";
import downloadCSV from "../components/util/downloadCSV";

// Reaction Commerce
import { useDataTable } from "@reactioncommerce/catalyst/DataTable";
import Button from "@reactioncommerce/catalyst/Button";
import { useConfirmDialog } from "@reactioncommerce/catalyst";

// Local catalyst
// import DataTable from "../components/catalyst/package/src/DataTable";
import DataTable from "../components/DataTable";

// Material UI
import CreateIcon from "@material-ui/icons/Create";
import CircleIcon from "mdi-material-ui/CheckboxBlankCircle";
import CloseIcon from "mdi-material-ui/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

// Components
import DateRangeSelector from "../../DateRangeSelector";

// Styled Components
const ButtonBar = styled.div`
  margin-bottom: 20px;
`;

const useStyles = makeStyles(theme => ({
  isVisible: {
    color: theme.palette.colors.forestGreen300,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1),
  },
  isHidden: {
    color: theme.palette.colors.black40,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1),
  },
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  tableTop: {
    display: "flex",
  },
  dateRangeFilter: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  exportButton: {
    float: "right",
    marginLeft: "auto",
  },
}));

const StatusIconCell = ({ visible }) => {
  const classes = useStyles();

  if (visible) {
    return (
      <div style={{ display: "flex" }}>
        <CircleIcon className={classes.isVisible} />
        <span>Yes</span>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <CircleIcon className={classes.isHidden} />
      <span>No</span>
    </div>
  );
};

const ImageCell = ({ url, title, width, height }) => {
  // If both width and height aren't passed, we default to thumbnail 36x36
  // Otherwise we simply pass the one that is passed
  if (!width && !height) {
    width = 36;
    height = 36;
  } else if (!width) {
    // Only width missing
    return <img src={url || "https://via.placeholder.com/150"} alt={title} height={height} />;
  } else if (!height) {
    // Only height missing
    return <img src={url || "https://via.placeholder.com/150"} alt={title} width={width} />;
  }

  return (
    <img src={url || "https://via.placeholder.com/150"} alt={title} width={width} height={height} />
  );
};

const DateRangeCell = ({ from, to }) => {
  const fromDate = from ? moment(from).format("MMMM Do YYYY") : "Start of time";
  const toDate = to ? moment(to).format("MMMM Do YYYY") : "End of time";

  return `[${fromDate}] to [${toDate}]`;
};

let textFilter = "";

const Displayer = (
  {
    readAll,
    actions,
    fields,
    goToCreatePage,
    goToUpdatePage,
    strings,
    showDateRangeFilter = true,
    showExportButton = true,
    onSelectionChange = () => {},
  },
  ref
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [sorting, setSorting] = useState({ clickedColumn: null, sortBy: null, sortOrder: null });

  // Register every mutation of every action.
  // Also prep an array of all actions that should appear in the rows of the
  // table (i.e. have `shouldAppearInTable` set to `true)
  const mutations = [];
  const actionsThatShouldAppearInTable = [];

  actions.forEach(action => {
    mutations[action.label] = useMutation(action.gql, {
      ignoreResults: true,
    })[0];

    if (action?.shouldAppearInTable) {
      actionsThatShouldAppearInTable.push(action);
    }
  });

  // Performs the passed action (i.e. mutation) on a specific ID
  const performAction = async (action, id) => {
    const variables = { ...(await action.formatInput(id)) };

    const { data, errors } = await mutations[action.label]({
      variables,
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    if (errors && errors.length) {
      return {
        succeeded: false,
        errorMessage: errors[0].message.replace("GraphQL error:", ""),
      };
    }

    return { succeeded: true };
  };

  const Table = () => {
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [textAreaDialog, setTextAreaDialog] = useState({
      isOpen: false,
      title: "",
      body: "",
    });
    const [isExportingData, setIsExportingData] = useState(false);

    // We always keep the data inside the ref in case parents needs to access it
    if (ref) ref.current = data;

    // Out of all the `actions` passed by the caller of Displayer, singleAction
    // singleAction.action contains the one currently clicked on and
    // singleAction.id contains the row on which the user clicked
    const [singleAction, setSingleAction] = useState({
      action: null,
      id: null,
    });

    const apolloClient = useApolloClient();

    const TextAreaCell = ({ title, body = "" }) => {
      const openTextAreaDialog = e => {
        e.stopPropagation(); // otherwise onRowClick() will execute
        setTextAreaDialog({
          isOpen: true,
          title,
          body,
        });
      };
      const maxLength = 20;
      const isPastMaxLength = body.length > maxLength;

      return (
        <span onClick={isPastMaxLength ? openTextAreaDialog : () => {}}>
          {isPastMaxLength ? `${body.substring(0, maxLength)}...` : body}
        </span>
      );
    };

    const columns = useMemo(() => {
      const columnsArray = [
        ...fields.map(field => {
          return {
            Header: field.label,
            accessor: field.name,
            isSortable: !!field?.isSortable,
            sortOrder: sorting.clickedColumn === field.name ? sorting.sortOrder : null,
            headerProps: {}, // Optional. Header props may be a function, object
            cellProps: {
              // Optional. Cell Props props may be a function, object
            },
            Cell: ({ row, cell }) => {
              switch (field.type) {
                // If the field is a boolean, show a status icon that is green
                // when on and no when off
                case "boolean": {
                  return (
                    <>
                      <StatusIconCell visible={cell.value} />
                    </>
                  );
                }

                // If the field is a dateTime, convert from server format
                // to readable format
                case "dateTime": {
                  return (
                    <span>
                      {cell?.value ? moment(cell.value).format("MMMM Do YYYY, h:mm:ss a") : ""}
                    </span>
                  );
                }

                case "image": {
                  return (
                    <>
                      <ImageCell
                        url={cell.value}
                        title={row.values.name}
                        width={field?.imageWidth}
                        height={field?.imageHeight}
                      />
                    </>
                  );
                }

                case "dateRange": {
                  return (
                    <>
                      <DateRangeCell from={cell.value.startDate} to={cell.value.endDate} />
                    </>
                  );
                }

                case "textarea": {
                  return (
                    <>
                      <TextAreaCell title={field.label} body={cell?.value || ""} />
                    </>
                  );
                }

                case "component": {
                  return (
                    <>
                      <field.Component value={cell.value} />
                    </>
                  );
                }

                default: {
                  return <span>{cell.value}</span>;
                }
              }
            },
          };
        }),
      ];

      // Add the actions as buttons in the row if user passed `shouldAppearInTable`
      // as `true` for some actions
      if (actionsThatShouldAppearInTable.length) {
        actionsThatShouldAppearInTable.forEach(action => {
          columnsArray.push({
            Header: action.label,
            accessor: `action-${action.label}`,
            headerProps: {}, // Optional. Header props may be a function, object
            cellProps: {
              // Optional. Cell Props props may be a function, object
              padding: "checkbox",
            },
            Cell: ({ row }) =>
              action?.IconComponent ? (
                <action.IconComponent
                  className={action?.className}
                  onClick={e => {
                    e.stopPropagation(); // otherwise onRowClick() will execute
                    setSingleAction({
                      action,
                      id: row.original._id,
                    });
                    openConfirmationDialog();
                  }}
                />
              ) : (
                ""
              ),
          });
        });
      }

      // Add the edit button (pen) if user passed goToUpdatePage prop
      if (goToUpdatePage) {
        columnsArray.push({
          Header: "",
          accessor: "edit",
          headerProps: {}, // Optional. Header props may be a function, object
          cellProps: {
            // Optional. Cell Props props may be a function, object
            padding: "checkbox",
          },
          Cell: ({ row }) => {
            return (
              <CreateIcon
                onClick={e => {
                  e.stopPropagation(); // other onRowClick() will execute
                  goToUpdatePage(row.original._id, row.original);
                }}
              />
            );
          },
        });
      }

      return columnsArray;
    }, []);

    const executeReadAllQuery = async (filter, first, offset) => {
      let data;
      let errors;
      let queryReturn;

      const genericFiltersData = {
        ...(showDateRangeFilter
          ? {
              dateRange: {
                gte: dateRange.startDate,
                lte: dateRange.endDate,
                field: "createdAt",
              },
            }
          : {}),
      };

      let genericFilters = {};

      if (genericFiltersData !== {}) {
        genericFilters = { genericFilters: genericFiltersData };
      }

      try {
        queryReturn = await apolloClient.query({
          query: readAll.gql,
          variables: {
            filter,
            first,
            offset,
            ...(sorting.clickedColumn
              ? {
                  sortBy: sorting.sortBy,
                  sortOrder: sorting.sortOrder,
                }
              : {}),
            ...genericFilters,
            ...readAll.formatInput(filter, dateRange, sorting),
          },
          fetchPolicy: "network-only",
          errorPolicy: "all", // without this, errors wouldn't get populated
        });

        data = queryReturn.data;
        errors = queryReturn.errors;

        if (errors && errors.length) {
          return {
            data: null,
            error: errors[0].message,
          };
        }

        return {
          data,
          error: null,
        };
      } catch (error) {
        console.error(error);

        return {
          data: null,
          error: error.toString(),
        };
      }
    };

    const exportData = async () => {
      if (!readAll?.gql) return;

      setIsExportingData(true);

      let { data, error } = await executeReadAllQuery(textFilter, 999999999, 0);

      setIsExportingData(false);

      if (error) {
        return enqueueSnackbar(error.replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      downloadCSV(fields, readAll.formatOutput(data).nodes);
    };

    // Fetch data callback whenever the table requires more data to properly
    // display. This is the case if theres an update with pagination, filtering
    // or sorting. This function is called on the initial load of the table to
    // fetch the first set of results.
    const onFetchData = useCallback(
      async ({
        globalFilter,
        pageIndex,
        pageSize,
        filters,
        filtersByKey,
        manualFilters,
        manualFiltersByKey,
      }) => {
        if (!readAll?.gql) {
          return;
        }

        // Keep globalFilter stored in a global variable
        textFilter = globalFilter;

        setIsLoading(true);

        let { data, error } = await executeReadAllQuery(
          globalFilter,
          pageSize,
          pageIndex * pageSize
        );

        setIsLoading(false);

        if (error) {
          return enqueueSnackbar(error.replace("GraphQL error:", ""), {
            variant: "error",
          });
        }

        // Update the state with the fetched data as an array of objects and the
        // calculated page count
        setData(readAll.formatOutput(data).nodes);
        setPageCount(Math.ceil(readAll.formatOutput(data).totalCount / pageSize));

        // Disable loading animation
        setIsLoading(false);
      },
      [setData, setPageCount, apolloClient]
    );

    const onRowClick = useCallback(async ({ row }) => {
      if (goToUpdatePage) goToUpdatePage(row.original._id, row.original);
    }, []);

    const onRowSelect = useCallback(async ({ selectedRows: rows }) => {
      setSelectedRows(rows || []);
      onSelectionChange(rows); // callback to inform parent of selected row IDs
    }, []);

    const labels = useMemo(
      () => ({
        globalFilterPlaceholder: strings?.filterPlaceholder || "Filter",
      }),
      []
    );

    const dataTableProps = useDataTable({
      columns,
      data,
      labels,
      pageCount,
      onFetchData,
      onRowClick,
      onRowSelect,
      getRowId: row => row._id,
    });

    const { refetch, fetchData, toggleAllRowsSelected, setManualFilters } = dataTableProps;

    // Action Confirmation Dialog
    const {
      openDialog: openConfirmationDialog,
      ConfirmDialog: ActionConfirmationDialog,
    } = useConfirmDialog({
      title: "Confirm",
      message: `Are you sure you want to ${
        singleAction?.action?.label ? singleAction.action.label.toLowerCase() : "proceed"
      }?`,
      onConfirm: async () => {
        setIsLoading(true);
        const actionResult = await performAction(singleAction.action, singleAction.id);

        if (!actionResult.succeeded) {
          setIsLoading(false);
          return enqueueSnackbar(actionResult.errorMessage, {
            variant: "error",
          });
        }

        // Success
        setIsLoading(false);
        if (typeof singleAction.action.onSuccess === "function") {
          singleAction.action.onSuccess();
        }

        refetch();
      },
    });

    // Create options for the built-in ActionMenu in the DataTable
    const actionMenuOptions = useMemo(
      () =>
        actions.map(action => ({
          label: action.label,
          onClick: async () => {
            if (selectedRows.length) {
              setIsLoading(true);

              for (let selectedRow of selectedRows) {
                const actionResult = await performAction(action, selectedRow);

                if (!actionResult.succeeded) {
                  setIsLoading(false);

                  return enqueueSnackbar(actionResult.errorMessage, {
                    variant: "error",
                  });
                }
              }

              setIsLoading(false);

              if (typeof action?.onSuccess === "function") {
                action.onSuccess();
              }

              refetch();
              enqueueSnackbar("Successfully performed action on selected rows.");
            } else {
              enqueueSnackbar("Please select one or more rows", {
                variant: "warning",
              });
            }
          },
        })),
      [selectedRows]
    );

    const TextAreaDialog = () => {
      const onClose = () => {
        setTextAreaDialog(oldState => ({
          ...oldState,
          isOpen: false,
        }));
      };

      const isJson = str => {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      };

      return (
        <Dialog
          open={textAreaDialog.isOpen}
          onClose={onClose}
          classes={{ root: classes.cardRoot }}
          fullWidth
          maxWidth="sm"
        >
          <CardHeader
            action={
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
            title={textAreaDialog.title}
          />
          <React.Fragment>
            <CardContent>
              {/* <pre> needed for displaying json objects */}
              {isJson(textAreaDialog.body) ? (
                <pre>{textAreaDialog.body}</pre>
              ) : (
                <>{textAreaDialog.body}</>
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Box>
                <Button variant="contained" color="primary" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </CardActions>
          </React.Fragment>
        </Dialog>
      );
    };

    return (
      <>
        <div className={classes.tableTop}>
          {showDateRangeFilter && (
            <div className={classes.dateRangeFilter}>
              <DateRangeSelector dateRange={dateRange} onChange={setDateRange} />
            </div>
          )}
          {showExportButton && (
            <div className={classes.exportButton}>
              {/* above div is a must for spinner to appear when exporting */}
              <Button
                color="primary"
                isWaiting={isExportingData}
                variant="outlined"
                onClick={exportData}
              >
                <GetAppIcon className={classes.buttonIcon} />
                Export
              </Button>
            </div>
          )}
        </div>
        <DataTable
          {...dataTableProps}
          actionMenuProps={{ options: actionMenuOptions }}
          onColumnHeaderClick={column => {
            const isSortable = fields.find(field => field.name === column.id)?.isSortable;
            let sortBy = column.id;

            if (typeof isSortable === "string") {
              // User not simply indicating whether or not the column is sortable by this field,
              // but actually what string to set as `sortBy` when querying server
              sortBy = isSortable;
            }

            setSorting({
              clickedColumn: column.id, // keep it stored to be able to properly identify which column was clicked
              sortBy,
              sortOrder: sorting.sortOrder === "asc" ? "desc" : "asc",
            });
          }}
          isLoading={isLoading}
        />
        <ActionConfirmationDialog />
        <TextAreaDialog />
      </>
    );
  };

  return (
    <div>
      {goToCreatePage ? (
        <ButtonBar>
          <Button variant="contained" color="primary" onClick={goToCreatePage}>
            {strings.createButton || "Create"}
          </Button>
        </ButtonBar>
      ) : (
        ""
      )}
      <Card style={{ overflow: "visible" }}>
        <CardContent>
          <Table />
        </CardContent>
      </Card>
    </div>
  );
};

Displayer.propTypes = {
  client: PropTypes.shape({
    mutate: PropTypes.func.isRequired,
  }),
};

export default forwardRef(Displayer);
