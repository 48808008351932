import React, { useState, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "@reactioncommerce/catalyst";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { SnackbarProvider } from "notistack";
import Dashboard from "../Dashboard";
import AuthenticationPage from "../AuthenticationPage";

// Amplify
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

/**
 * BAse application component containing providers for theme, auth, routing and more
 * @param {Object} props Props for configuring various providers and base components
 * @returns {React.ReactElement} App
 */
function App(props) {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  const {
    DashboardComponent: DashboardComponentProp,
    apolloClient,
    dashboardComponentProps,
    plugins,
    snackbarProviderProps: snackbarProps
  } = props;

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  const DashboardComponent = DashboardComponentProp || Dashboard;

  const snackbarProviderProps = {
    maxSnack: 3,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    ...snackbarProps
  };

  return authState === AuthState.SignedIn && user ? (
    <Suspense fallback={null}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <ThemeProvider theme={defaultTheme}>
            <SnackbarProvider {...snackbarProviderProps}>
              <DashboardComponent {...dashboardComponentProps} plugins={plugins} />
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>
  ) : (
    <AuthenticationPage />
  );
}

App.propTypes = {
  DashboardComponent: PropTypes.elementType,
  apolloClient: PropTypes.object,
  dashboardComponentProps: PropTypes.object,
  plugins: PropTypes.arrayOf(PropTypes.object),
  snackbarProviderProps: PropTypes.object
};

export default App;
