import React, { useState } from "react";
import i18next from "i18next";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import SimpleSchema from "simpl-schema";
import styled from "styled-components";
import _ from "lodash";
import { Button, TextField } from "@reactioncommerce/catalyst";
import {
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import muiOptions from "reacto-form/cjs/muiOptions";
import useReactoForm from "reacto-form/cjs/useReactoForm";

// GraphQL
import createDiscountCodeCampaignGQL from "../graphql/mutations/createDiscountCodeCampaign";

// Components
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import ShopperSelectorWithData from "../../../package/src/ShopperSelectorWithData";
import DateRangeSelector from "../../../package/src/DateRangeSelector";

// Constants
import { constants } from "../constants.js";

const {
  conditionMessage,
  productSelectionLabel,
  shopperSelectionLabel,
  dateRangeSelectionLabel,
  discountAmountLabel,
  discountAmountPlaceholder,
  calculationMethods,
  commenceCampaignBtnLabel,
} = constants;

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const useStyles = makeStyles(theme => ({
  deleteButton: {
    marginRight: "auto",
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  legend: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize * 0.9,
    lineHeight: 2,
  },
  gridLowerThanDropdown: {
    zIndex: 0, // otherwise dropdowns will appear beneath the grids
  },
}));

const discountCodeSchema = new SimpleSchema({
  calculation: { type: String },
  conditions: {
    type: Object,
    optional: true,
  },
  "conditions.enabled": {
    type: Boolean,
    optional: true,
    defaultValue: true,
  },
  "conditions.accountLimit": {
    type: Number,
    optional: true,
  },
  "conditions.redemptionLimit": {
    type: Number,
    optional: true,
  },
  "conditions.order": {
    type: Object,
    defaultValue: {},
  },
  "conditions.order.startDate": {
    type: Date,
    optional: true,
  },
  "conditions.order.endDate": {
    type: Date,
    optional: true,
  },
  "conditions.audience": {
    type: Array,
    optional: true,
  },
  "conditions.audience.$": {
    type: String,
    optional: true,
  },
  "conditions.products": {
    type: Array,
    optional: true,
  },
  "conditions.products.$": {
    type: String,
    optional: true,
  },
  description: {
    type: String,
    optional: true,
  },
  discount: {
    type: Number,
  },
  shopperIds: {
    type: Array,
    optional: true,
  },
  "shopperIds.$": {
    type: String,
    optional: true,
  },
  shopId: {
    type: String,
    optional: true,
  },
});
const validator = discountCodeSchema.getFormValidator();

/**
 * @summary React component that renders the form for adding, updating, or deleting
 *   a discount code record.
 * @param {Object} props React props
 * @return {React.Node} React node
 */
export default function DiscountCodeForm(props) {
  const { shopId, history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // These are not handled by reactoform so we create separate states for them
  const [productSelection, setProductSelection] = useState([]);
  const [shopperSelection, setShopperSelection] = useState([]);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: null, endDate: null });

  const onSuccess = () => {
    setIsSubmitting(false);
    history.push("/discounts");
  };

  const onFailure = () => {
    setIsSubmitting(false);
    enqueueSnackbar(i18next.t("admin.discountCode.failure"), { variant: "warning" });
  };

  const [createDiscountCodeCampaign] = useMutation(createDiscountCodeCampaignGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar("Discount Code Campaign created successfully", { variant: "success" });
    },
    onError() {
      onFailure();
    },
  });

  const { getFirstErrorMessage, getInputProps, isDirty, hasErrors, submitForm } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      // formData coming from reactoform does not contain everything inside  it.
      // We have separate states as well --> add them
      if (!formData?.conditions) formData.conditions = {};

      formData.shopperIds = Array.isArray(shopperSelection)
        ? shopperSelection.map(shopper => shopper.value)
        : null;

      formData.conditions.products = Array.isArray(productSelection)
        ? productSelection.map(product => product.value)
        : null;

      if (dateRangeSelection?.startDate || dateRangeSelection?.endDate) {
        formData.conditions.order = {};

        if (dateRangeSelection?.startDate)
          formData.conditions.order.startDate = dateRangeSelection.startDate;

        if (dateRangeSelection?.endDate)
          formData.conditions.order.endDate = dateRangeSelection.endDate;
      }

      formData.discount = parseFloat(formData?.discount);
      formData.shopId = shopId;

      const discountCodeInput = discountCodeSchema.clean(formData);

      if (discountCodeInput.conditions) {
        // Set order minimum to 0, this will allow a discount to be
        // Redeemed infinitely on any number of orders.
        _.set(discountCodeInput, "conditions.order.min", 0);
      }

      await createDiscountCodeCampaign({
        variables: {
          input: discountCodeInput,
        },
      });
    },
    validator(formData) {
      return validator(discountCodeSchema.clean(formData));
    },
    // value: discountCode,
    logErrorsOnSubmit: true,
  });

  let calculationMethodField;
  if (Array.isArray(calculationMethods) && calculationMethods.length) {
    const options = calculationMethods.map(({ value, label }) => ({ label, value }));
    calculationMethodField = (
      <TextField
        error={hasErrors(["calculation"])}
        fullWidth
        helperText={getFirstErrorMessage(["calculation"])}
        label={`${i18next.t("admin.discountCode.form.calculationMethod")} *`}
        onKeyPress={event => {
          if (event.key === "Enter") submitForm();
        }}
        select
        {...getInputProps("calculation", muiOptions)}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  const classes = useStyles();

  return (
    <div>
      <Card style={{ overflow: "visible" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="label" classes={{ root: classes.label }}>
                {shopperSelectionLabel}
              </FormLabel>
              <ShopperSelectorWithData
                shouldShowEmail
                shopIds={[shopId]}
                value={shopperSelection}
                onSelection={setShopperSelection}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              <TextField
                type="number"
                error={hasErrors(["discount"])}
                fullWidth
                helperText={getFirstErrorMessage(["discount"])}
                label={discountAmountLabel}
                placeholder={discountAmountPlaceholder}
                {...getInputProps("discount", muiOptions)}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              {calculationMethodField}
            </Grid>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              <FormLabel component="legend" classes={{ root: classes.legend }}>
                Conditions
              </FormLabel>
              {conditionMessage}
            </Grid>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              <TextField
                error={hasErrors(["conditions.accountLimit"])}
                fullWidth
                helperText={getFirstErrorMessage(["conditions.accountLimit"])}
                label={i18next.t("admin.discountCode.form.accountLimit")}
                placeholder={i18next.t("admin.discountCode.form.accountLimitPlaceholder")}
                {...getInputProps("conditions.accountLimit", muiOptions)}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              <TextField
                error={hasErrors(["conditions.redemptionLimit"])}
                fullWidth
                helperText={getFirstErrorMessage(["conditions.redemptionLimit"])}
                label={i18next.t("admin.discountCode.form.redemptionLimit")}
                onKeyPress={event => {
                  if (event.key === "Enter") submitForm();
                }}
                placeholder={i18next.t("admin.discountCode.form.redemptionLimitPlaceholder")}
                {...getInputProps("conditions.redemptionLimit", muiOptions)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="label" classes={{ root: classes.label }}>
                {productSelectionLabel}
              </FormLabel>
              <ProductSelectorWithData
                shouldShowShopName
                shopIds={[shopId]}
                value={productSelection}
                onSelection={setProductSelection}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridLowerThanDropdown}>
              <FormLabel component="label" classes={{ root: classes.label }}>
                {dateRangeSelectionLabel}
              </FormLabel>
              <DateRangeSelector dateRange={dateRangeSelection} onChange={setDateRangeSelection} />
            </Grid>
          </Grid>

          <br />

          <CardActions disableSpacing>
            <Button
              disabled={isSubmitting /*  || !isDirty */}
              onClick={submitForm}
              variant="contained"
              color="primary"
            >
              {commenceCampaignBtnLabel}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
}
