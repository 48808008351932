import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { settingsQuery, updateSettingsMutation } from "../graphql/mutations.js";
import Typography from "@material-ui/core/Typography";
import MUICardActions from "@material-ui/core/CardActions";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Button from "@reactioncommerce/catalyst/Button";
import camelCaseToSentence from "../utils/camelCaseToSentence.js";
import styled from "styled-components";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

function FinancialsSettingsPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [settings, setSettings] = useState([]);

  const {
    isLoading: settingsLoading,
    error: settingsError,
    data: settingsData,
    refetch: refetchSettings,
  } = useQuery(settingsQuery, {
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (settingsData?.settings) {
      setSettings(settingsData.settings);
    }
  }, [settingsData]);

  const [updateSettings] = useMutation(updateSettingsMutation, {
    onCompleted() {
      enqueueSnackbar("Successfully updated setting!", { variant: "success" });
      refetchSettings();
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  const onSave = () =>
    updateSettings({
      variables: {
        input: {
          settings: settings.map(setting => ({
            id: setting._id,
            data: {
              name: setting.name,
              value: setting.value,
              shopId: setting.shopId,
            },
          })),
        },
      },
    });

  const settingComponent = (
    index,
    settingId,
    settingName,
    settingLabel,
    settingValue,
    settingDescription
  ) => (
    <div key={settingId}>
      <Typography variant="h3" style={{ paddingBottom: "10px" }}>
        {settingLabel}
      </Typography>

      <TextInput
        id={settingId}
        key={settingId}
        name={settingName}
        value={settingValue}
        onChanging={value =>
          setSettings(
            settings.map((setting, innerIndex) => ({
              ...setting,
              value: innerIndex === index ? value : setting.value,
            }))
          )
        }
      />

      <Typography variant="body1" style={{ paddingBottom: "5px" }}>
        {settingDescription}
      </Typography>

      <br />
    </div>
  );

  return (
    <div>
      {settings?.map((setting, index) =>
        settingComponent(
          index,
          setting._id,
          setting.name,
          camelCaseToSentence(setting.name),
          setting.value,
          setting.description
        )
      )}

      <CardActions disableSpacing>
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </CardActions>
    </div>
  );
}

export default FinancialsSettingsPage;
