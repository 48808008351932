import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { subscriptionPackageOverrides } from "../graphql/queries";
import { setSubscriptionOverrides } from "../graphql/mutations";

import Divider from "@material-ui/core/Divider";

import { useApolloClient } from "@apollo/react-hooks";

import OverrideForm from "../../../package/src/OverrideForm/OverrideForm";

import { useSnackbar } from "notistack";

import { Box } from "@material-ui/core";

const PaddedDivider = styled(Divider)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;
`;

const OverridePackage = ({ subscriptionPackageId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const apolloClient = useApolloClient();

  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState({});

  async function submitOverrideForm(name, value) {
    setData({
      ...data,
      [name]: value,
    });
    const { errors: overrideFetchErrors } = await apolloClient.mutate({
      mutation: setSubscriptionOverrides,
      variables: {
        id: subscriptionPackageId,
        [`${name}Override`]: value,
      },
      errorPolicy: "all",
    });

    if (overrideFetchErrors && overrideFetchErrors.length) {
      return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
        variant: "error",
      });
    } else {
      return enqueueSnackbar(`Override for ${name} saved sucessfully.`, {
        variant: "success",
      });
    }
  }

  useEffect(() => {
    async function fetchOverridesAndShow() {
      const { data: overrideData, errors: overrideFetchErrors } = await apolloClient.query({
        query: subscriptionPackageOverrides,
        variables: {
          id: subscriptionPackageId,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      if (overrideFetchErrors && overrideFetchErrors.length) {
        return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
          variant: "error",
        });
      }

      setData(overrideData.subscriptionPackageOverrides ?? {});
      setFetching(false);
    }

    fetchOverridesAndShow();
  }, []);

  return (
    <Box>
      {!fetching && (
        <Box>
          <OverrideForm
            title={"Base Rate"}
            type="percentage"
            initial={data.baseRate ?? {}}
            onSubmit={values => {
              submitOverrideForm("baseRate", values);
            }}
          />
          <PaddedDivider />
          <OverrideForm
            title={"Additional rate per standard shop"}
            type="percentage"
            initial={data.additionalRatePerStandardShop ?? {}}
            onSubmit={values => {
              submitOverrideForm("additionalRatePerStandardShop", values);
            }}
          />
          <PaddedDivider />
          <OverrideForm
            title={"Additional rate per premium shop"}
            type="percentage"
            initial={data.additionalRatePerPremiumShop ?? {}}
            onSubmit={values => {
              submitOverrideForm("additionalRatePerPremiumShop", values);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default OverridePackage;
