import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import Totals from "../components/Totals";
import Orders from "../components/Orders";
import Transactions from "../components/Transactions";
import Subscriptions from "../components/Subscriptions";

import { constants } from "../../../constants.js";

import Toolbar from "../../../package/src/Toolbar";

// Libs
import { Divider, Grid, Card, CardContent, Tabs, Tab, makeStyles } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import MUICardActions from "@material-ui/core/CardActions";
import { defaultTheme } from "@reactioncommerce/catalyst";
import Button from "@reactioncommerce/catalyst/Button";
import styled from "styled-components";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const ContentGroup = styled.div`
  margin-bottom: 30px;
`;

const useStyles = makeStyles(theme => ({
  inputControlContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  inputControlContent: {
    width: "100%",
  },
  merchantSelector: {
    marginBottom: theme.spacing(1),
  },
}));

const InputControl = ({ viewer, onSubmit }) => {
  const classes = useStyles();
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: viewer.type !== "godmode" ? [viewer.companyId] : [],
    selectedShopIds:
      viewer.type !== "godmode"
        ? [
            ...viewer.adminUIShops
              .filter(shop => shop.company._id === viewer.companyId)
              .map(shop => shop._id),
          ]
        : [],
  });
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  useEffect(() => {
    onSubmit(selectedMerchants.selectedShopIds, selectedMerchants.selectedCompanyIds, dateRange);
  }, [viewer]);

  return (
    <Card className={classes.inputControlContainer}>
      <CardContent className={classes.inputControlContent}>
        {viewer.type === "godmode" && (
          <div className={classes.merchantSelector}>
            <MerchantSelectorWithData
              shouldShowShopName
              size={32}
              viewer={viewer}
              selectedCompanyIds={selectedMerchants.selectedCompanyIds}
              onMerchantSelectionChange={setSelectedMerchants}
            />
          </div>
        )}

        <CardActions style={{ padding: 0 }}>
          <DateRangeSelector dateRange={dateRange} onChange={e => setDateRange(e)} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit(
                selectedMerchants.selectedShopIds,
                selectedMerchants.selectedCompanyIds,
                dateRange
              );
            }}
          >
            Submit
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

const FinancialsPage = props => {
  const { viewer, history, match } = props;

  if (!viewer) return null;

  const tabDisabled = viewer?.groups.nodes
    .map(group => group.slug)
    .includes(constants.ACCOUNT_TYPES.godmodeAdmin)
    ? undefined
    : true;

  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: [],
    selectedShopIds: [],
  });

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [currentTab, setCurrentTab] = useState(parseInt(match?.params?.tab) || 0);
  const [refreshTotals, setRefreshTotals] = useState(false);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Balance" />
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
          <InputControl
            viewer={viewer}
            onSubmit={(selectedShopIds, selectedCompanyIds, dateRange) => {
              setSelectedMerchants({
                selectedCompanyIds,
                selectedShopIds,
              });
              setDateRange(dateRange);
              setRefreshTotals(!refreshTotals);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Card>
            <CardContent>
              <Totals
                dateRange={dateRange}
                companyIds={selectedMerchants.selectedCompanyIds}
                viewerType={viewer.type}
                refresh={refreshTotals}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <br />

      <ContentGroup>
        <Tabs value={currentTab} onChange={(e, val) => setCurrentTab(val)}>
          <Tab label="Orders" key={0} />
          <Tab label="Subscriptions" key={1} disabled={tabDisabled} />
          <Tab label="Transactions" key={2} disabled={tabDisabled} />
        </Tabs>
        <Divider />
      </ContentGroup>

      {currentTab === 0 && (
        <Orders
          dateRange={dateRange}
          shopIds={selectedMerchants.selectedShopIds}
          companyIds={selectedMerchants.selectedCompanyIds}
          viewer={viewer}
          history={history}
        />
      )}
      {currentTab === 1 && (
        <Subscriptions
          dateRange={dateRange}
          companyIds={selectedMerchants.selectedCompanyIds}
          viewerType={viewer.type}
        />
      )}
      {currentTab === 2 && (
        <Transactions
          dateRange={dateRange}
          companyIds={selectedMerchants.selectedCompanyIds}
          viewer={viewer}
          history={history}
          match={match}
          onChange={() => {
            setRefreshTotals(!refreshTotals);
          }}
        />
      )}
    </MuiThemeProvider>
  );
};

FinancialsPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      shopId: PropTypes.string,
    }),
  }),
  shopId: PropTypes.string,
  viewer: PropTypes.object,
};

export default FinancialsPage;
