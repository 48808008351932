import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { one } from "../graphql/queries";
import { update } from "../graphql/mutations";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Button from "@reactioncommerce/catalyst/Button";
import Field from "@reactioncommerce/components/Field/v1";
import { Typography, CardActions } from "@material-ui/core";

const PaddedField = styled(Field)`
  margin-bottom: 30px !important;
`;

const CustomCardActions = styled(CardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

export default function({ viewer }) {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apolloClient.query({
        query: one,
        variables: {
          id: viewer.companyId,
        },
        fetchPolicy: "network-only",
      });

      setDescription(data.company.description);
      setEmail(data.company.email);
      setPhoneNumber(data.company.phoneNumber);
      setCompanyName(data.company.name);
    };

    fetchData();
  }, []);

  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    const { errors } = await apolloClient.mutate({
      mutation: update,
      variables: {
        id: viewer.companyId,
        input: {
          description,
          phoneNumber,
          email,
        },
      },
    });
    setLoading(false);

    if (errors && errors.length) {
      return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
        variant: "error",
      });
    }

    enqueueSnackbar("Update successful", { variant: "success" });
  };

  return (
    <div>
      <PaddedField>
        <Typography variant="h3">{companyName}</Typography>
      </PaddedField>
      <PaddedField label="Email">
        <TextInput type="email" value={email} onChange={setEmail} />
      </PaddedField>
      <PaddedField label="Phone Number">
        <TextInput type="text" value={phoneNumber} onChange={setPhoneNumber} />
      </PaddedField>
      <CustomCardActions disableSpacing>
        <Button isWaiting={loading} variant="contained" color="primary" onClick={save}>
          Save
        </Button>
      </CustomCardActions>
    </div>
  );
}
