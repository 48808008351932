import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Invitations from "./Invitations";

import GroupsDisplayer from "./GroupsDisplayer";
import Toolbar from "../../../../package/src/Toolbar";

/**
 * @summary Main accounts view
 * @name Accounts
 * @returns {React.Component} A React component
 */
function Accounts({ selectedShopIds: shopIds, viewer }) {
  const [currentTab, setCurrentTab] = useState(0);

  const { t } = useTranslation();

  return (
    <Fragment>
      <Toolbar title="Staff" />
      <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}>
        <Tab label={t("admin.accounts.tabs.staff")} />
        <Tab label={t("admin.accounts.tabs.invites")} />
      </Tabs>

      <Divider />

      {currentTab === 0 && <GroupsDisplayer shopIds={shopIds} viewer={viewer} />}
      {currentTab === 1 && <Invitations shopIds={shopIds} />}
    </Fragment>
  );
}

export default Accounts;
