import { useLazyQuery } from "@apollo/react-hooks";
import { getPrimaryShopId } from "../graphql/queries/getPrimaryShopId";
import { useSnackbar } from "notistack";

const usePrimaryShopId = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [getPrimaryShop, { called, data, loading, refetch }] = useLazyQuery(getPrimaryShopId, {
    onError(error) {
      enqueueSnackbar("Something went wrong! Please try again.", {
        variant: "error",
      });
    },
  });

  if (!called) {
    getPrimaryShop({ variables: {} });
  }

  return {
    isPrimaryShopIdLoading: loading || !called,
    primaryShopId: data?.primaryShopId,
  };
};

export default usePrimaryShopId;
