import gql from "graphql-tag";

export default gql`
  query productsWithFilters(
    $shopIds: [ID]!
    $productIds: [ID]
    $query: String
    $first: ConnectionLimitInt
    $offset: Int
    $genericFilters: GenericFilterInput
  ) {
    productsWithFilters(
      shopIds: $shopIds
      productIds: $productIds
      query: $query
      first: $first
      offset: $offset
      genericFilters: $genericFilters
    ) {
      nodes {
        _id
        createdAt
        title
        currentProductHash
        isVisible
        shop {
          _id
          name
        }
        media {
          URLs {
            thumbnail
          }
        }
        price {
          range
        }
        publishedProductHash
        variants {
          _id
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`;
