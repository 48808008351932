import React, { useState } from "react";

// Components
import Select from "@reactioncommerce/catalyst/Select";
import Button from "@reactioncommerce/catalyst/Button";
import TextField from "@reactioncommerce/catalyst/TextField";
import MUICardActions from "@material-ui/core/CardActions";
import styled from "styled-components";
import PendingIcon from "mdi-material-ui/ClockOutline";

// GraphQL
import { briefShopQuery, pendingMerchantRequestQuery } from "../graphql/queries";
import { addMerchantRequestMutation, removeMerchantRequestMutation } from "../graphql/mutations";

// Libs
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

// Other
import { constants } from "../../../constants.js";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const ChangeShopCategory = ({ shopId, options: shopCategories }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [addMerchantRequest] = useMutation(addMerchantRequestMutation, {
    ignoreResults: true,
  });
  const [removeMerchantRequest] = useMutation(removeMerchantRequestMutation, {
    ignoreResults: true,
  });
  const { loading: loadingShop, data: shopData } = useQuery(briefShopQuery, {
    variables: {
      id: shopId,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });
  const {
    loading: loadingMerchantRequest,
    data: merchantRequestData,
    refetch: refetchPendingMerchantRequest,
  } = useQuery(pendingMerchantRequestQuery, {
    variables: {
      shopId,
      type: constants.MERCHANT_REQUESTS.TYPE.SHOP_CATEGORY,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  if (loading || loadingShop || loadingMerchantRequest) return "Loading...";

  const handleSubmit = async () => {
    const changeFrom = shopData?.shop?.shopCategoryId;
    const changeTo = selectedCategory?.value;

    if (!selectedCategory || changeFrom === changeTo) {
      return enqueueSnackbar("You haven't changed the Shop Category", {
        variant: "error",
      });
    }

    setLoading(true);

    await addMerchantRequest({
      variables: {
        input: {
          type: constants.MERCHANT_REQUESTS.TYPE.SHOP_CATEGORY,
          reason,
          changeFrom,
          changeTo,
          shopId,
        },
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingMerchantRequest();

    setLoading(false);
  };

  const handleCancel = async merchantRequestId => {
    setLoading(true);

    await removeMerchantRequest({
      variables: {
        id: merchantRequestId,
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingMerchantRequest();

    setLoading(false);
  };

  return merchantRequestData.pendingMerchantRequest ? (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PendingIcon style={{ fontSize: 50 }} />
        <div style={{ marginLeft: 10 }}>
          Your request to change the category for this Shop from [
          {merchantRequestData.pendingMerchantRequest.fromShopCategory[0].name}] to [
          {merchantRequestData.pendingMerchantRequest.toShopCategory[0].name}] is currently under
          review...
        </div>
      </div>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCancel(merchantRequestData.pendingMerchantRequest._id)}
        >
          Cancel Request
        </Button>
      </CardActions>
    </>
  ) : (
    <>
      <div>
        Below is the current category for this Shop. If you'd like this Shop to appear on a
        different street, you can place a request to change its category. We'll handle it as soon as
        possible.
      </div>
      <br />
      <Select
        name={"New shop category"}
        options={shopCategories}
        value={
          selectedCategory ||
          shopCategories.find(category => category.value === shopData?.shop?.shopCategoryId)
        }
        onSelection={selectedCategory => setSelectedCategory(selectedCategory)}
        placeholder={"New shop category"}
      />
      <br />
      <TextField
        onChange={event => setReason(event.currentTarget.value)}
        placeholder="Provide reason. i.e. Why would you like to change this Shop's category?"
        multiline
        rows={7}
      />
      <br />
      <CardActions disableSpacing>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Place Request
        </Button>
      </CardActions>
    </>
  );
};

export default ChangeShopCategory;
