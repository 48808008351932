import { useLazyQuery } from "@apollo/react-hooks";
import accountTypesQuery from "../graphql/queries/accountTypes";

export default function useAccountTypesSlugs() {
  const [getAccountTypes, { called, data, loading, refetch }] = useLazyQuery(accountTypesQuery);

  if (!called) {
    getAccountTypes();
  }

  return {
    isLoadingGroups: loading || !called,
    refetchGroups: refetch,
    accountTypes: data?.getAccountTypes || []
  };
}
