import React from "react";
import CouponTriggerForm from "../components/CouponTriggerForm.js";
import Toolbar from "../../../package/src/Toolbar";

/**
 * @summary Renders discounts page
 * @param {Object} props Component props
 * @return {React.Node} React node
 */
export default function EditCouponTriggerPage(props) {
  const shopId = props?.match?.params?.shopId;
  const couponTriggerId = props?.match?.params?.couponTriggerId;

  return (
    <>
      <Toolbar
        title="Edit Coupon Trigger"
        onCancel={() => props.history.push("/coupon-triggers")}
      />
      <CouponTriggerForm {...props} shopId={shopId} couponTriggerId={couponTriggerId} />
    </>
  );
}
