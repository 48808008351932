import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Button from "@reactioncommerce/catalyst/Button";
import CloseIcon from "mdi-material-ui/Close";
import {
  Box,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { mediaRecordsQuery } from "./queries.js";
import {
  linkMediaRecordsToProductMutation,
  linkMediaRecordsToVariantMutation,
} from "./mutations.js";
import CRUDer from "../../../../package/src/CRUDer";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
  imageList: {
    paddingTop: theme.spacing(2),
  },
}));

/**
 * MediaGallerySelectorDialog component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function MediaGallerySelectorDialog({
  title,
  isOpen,
  onClose,
  shopId,
  productId,
  variantId,
  onSuccess,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLinking, setIsLinking] = useState(false);

  const [linkMediaRecordsToProduct] = useMutation(linkMediaRecordsToProductMutation, {
    onCompleted({ linkMediaRecordsToProduct }) {
      if (!linkMediaRecordsToProduct) return;
      enqueueSnackbar(linkMediaRecordsToProduct, { variant: "success" });
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  const [linkMediaRecordsToVariant] = useMutation(linkMediaRecordsToVariantMutation, {
    onCompleted({ linkMediaRecordsToVariant }) {
      if (!linkMediaRecordsToVariant) return;
      enqueueSnackbar(linkMediaRecordsToVariant, { variant: "success" });
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  // Contains all media records selected by the user (shouldn't be a state
  // otherwise CRUDer will re-render everytime we set it --> infinite loop)
  let selectedMediaRecordIds = [];

  const fields = [
    { type: "image", name: "url", label: "Image", imageWidth: 165 },
    { type: "string", name: "_id", label: "ID", isSortable: true },
    { type: "string", name: "name", label: "Name", isSortable: true },
    { type: "dateTime", name: "uploadedAt", label: "Uploaded on", isSortable: true },
  ];

  const readAll = {
    gql: mediaRecordsQuery,
    formatInput: (globalFilter, dateRange) => ({
      shopId,
      unlinkedOnly: true,
      genericFilters: {
        dateRange: {
          gte: dateRange.startDate,
          lte: dateRange.endDate,
          field: "original.uploadedAt",
        },
      },
    }),
    formatOutput: output => ({
      nodes: output.mediaRecords.nodes.map(node => ({
        ...node,
        url: node?.imageURLs?.original,
        name: node?.original?.name,
        uploadedAt: node?.original?.uploadedAt,
      })),
      totalCount: output.mediaRecords.totalCount,
    }),
  };

  const linkMediaToProductOrVariant = async () => {
    setIsLinking(true);

    if (variantId) {
      await linkMediaRecordsToVariant({
        variables: {
          input: {
            shopId,
            productId,
            variantId,
            mediaRecordIds: selectedMediaRecordIds,
          },
        },
      });
    } else {
      await linkMediaRecordsToProduct({
        variables: {
          input: {
            shopId,
            productId,
            mediaRecordIds: selectedMediaRecordIds,
          },
        },
      });
    }

    onClose();
    setIsLinking(false);
    onSuccess();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ root: classes.cardRoot }}
      fullWidth
      maxWidth="md"
    >
      <CardHeader
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={title}
      />
      <React.Fragment>
        <CardContent>
          <Typography variant="caption" display="inline">
            If you can't find your image below, it could be because it has already been linked to
            another Product or Variant. In that case, you can upload a new one from the Media
            Gallery.
          </Typography>
          <div className={classes.imageList}>
            <CRUDer
              crud={{ state: "display" }}
              handlers={{
                onSelectionChange: selectedRowIds => {
                  selectedMediaRecordIds = selectedRowIds;
                },
              }}
              strings={{
                displayer: {
                  filterPlaceholder: "Filter by Name",
                },
              }}
              fields={{
                display: fields,
              }}
              apis={{
                readAll,
                actions: [],
              }}
              showExportButton={false}
            ></CRUDer>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Box>
            <Button
              isWaiting={isLinking}
              variant="contained"
              color="primary"
              onClick={linkMediaToProductOrVariant}
            >
              Select
            </Button>
          </Box>
        </CardActions>
      </React.Fragment>
    </Dialog>
  );
}

export default MediaGallerySelectorDialog;
