import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "mdi-material-ui/Close";
import Button from "@reactioncommerce/catalyst/Button";
import Select from "@reactioncommerce/catalyst/Select";
import SplitButton from "@reactioncommerce/catalyst/SplitButton";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import {
  Box,
  Grid,
  CardActions,
  CardHeader,
  CardContent,
  Dialog,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { getTags } from "./helpers";
import { ADD_TAGS_TO_PRODUCTS, REMOVE_TAGS_FROM_PRODUCTS, CREATE_TAG } from "./mutations";
import { useParams } from "react-router-dom";
import TextField from "@reactioncommerce/catalyst/TextField";

const ACTION_OPTIONS = [
  {
    label: "Add tags to product",
    type: "ADD",
  },
  {
    label: "Remove tags from product",
    isDestructive: true,
    type: "REMOVE",
  },
];

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
}));

/**
 * TagSelector component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function TagSelector(props) {
  const { isOpen, onClose, onSuccess, productIds, refreshToolbar } = props;

  const routeParams = useParams();
  const apolloClient = useApolloClient();
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addTagInput, setAddTagInput] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const handleTagsAction = async option => {
    const tagIds = selectedTags && selectedTags.map(({ value }) => value);

    // Prevent user from executing action if he/she has not yet selected at
    // least one tag
    if (!tagIds.length) {
      enqueueSnackbar(t("admin.addRemoveTags.invalidSelection"), { variant: "warning" });

      return;
    }

    let mutationName;
    let data;
    let loading;
    let error;

    setIsLoading(true);

    switch (option.type) {
      case "ADD":
        mutationName = "addTagsToProducts";
        ({ data, loading, error } = await apolloClient.mutate({
          mutation: ADD_TAGS_TO_PRODUCTS,
          variables: {
            input: {
              productIds,
              shopId: routeParams?.shopId,
              tagIds,
            },
          },
        }));

        refreshToolbar();

        setIsLoading(loading);
        break;

      case "REMOVE":
        mutationName = "removeTagsFromProducts";
        ({ data, loading, error } = await apolloClient.mutate({
          mutation: REMOVE_TAGS_FROM_PRODUCTS,
          variables: {
            input: {
              productIds,
              shopId: routeParams?.shopId,
              tagIds,
            },
          },
        }));

        refreshToolbar();

        setIsLoading(loading);
        break;

      default:
        break;
    }

    if (data && data[mutationName]) {
      // Notify user of performed action
      if (mutationName.startsWith("add")) {
        enqueueSnackbar(t("admin.addRemoveTags.addConfirmation"));
      } else {
        enqueueSnackbar(t("admin.addRemoveTags.removeConfirmation"));
      }
      onSuccess && onSuccess();
    }

    if (error) {
      enqueueSnackbar(t("admin.addRemoveTags.errorMessage"), { variant: "error" });
    }

    onClose();
    setSelectedTags([]);
  };

  const createTag = async () => {
    if (!addTagInput) return;

    try {
      const { error } = await apolloClient.mutate({
        mutation: CREATE_TAG,
        variables: {
          input: {
            shopId: routeParams?.shopId,
            isVisible: true,
            name: addTagInput,
            displayTitle: addTagInput,
          },
        },
      });

      if (error) {
        enqueueSnackbar("Something went wrong! Could not add new tag.", { variant: "error" });
      } else {
        enqueueSnackbar("Successfully added new tag.", { variant: "success" });
      }
    } catch (error) {
      return enqueueSnackbar(error.toString().replace("GraphQL error:", ""), {
        variant: "error",
      });
    }

    setAddTagInput("");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ root: classes.cardRoot }}
      fullWidth
      maxWidth="sm"
    >
      <CardHeader
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        title={t("admin.productTable.bulkActions.addRemoveTags")}
      />
      <React.Fragment>
        <div className={classes.cardActions}>
          To add a tag, simply start typing the tag's name then select it from the available
          options. If it does not exist, create it in the create section below.
        </div>

        <CardContent>
          <Grid container spacing={1} className={classes.cardContainer}>
            <Grid item sm={12}>
              <Select
                defaultOptions
                isAsync
                isMulti
                loadOptions={query => getTags(apolloClient, query, routeParams?.shopId)}
                onSelection={tags => setSelectedTags(tags)}
                placeholder={"Search for tag"}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <SplitButton
            color="primary"
            options={ACTION_OPTIONS}
            onClick={handleTagsAction}
            isWaiting={isLoading}
          />
        </CardActions>

        <div className={classes.cardActions}>
          If you can't find the Tag in the above options, you can always add a new one by using the
          input field below and clicking on 'Create'.
        </div>

        <CardActions className={classes.cardActions}>
          <TextField
            onChange={event => setAddTagInput(event.currentTarget.value)}
            placeholder="Add"
            onKeyDown={event => {
              if (event.keyCode == 13) {
                createTag();
              }
            }}
            value={addTagInput}
          />
          <Button variant="outlined" color="primary" onClick={createTag}>
            Create
          </Button>
        </CardActions>

        <CardActions className={classes.cardActions}>
          <Box>
            <Button onClick={onClose}>{t("app.cancel")}</Button>
          </Box>
        </CardActions>
      </React.Fragment>
    </Dialog>
  );
}

TagSelector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  productIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagSelector;
