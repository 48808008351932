import React from "react";
import Toolbar from "../../../package/src/Toolbar";

import CouponTriggersTable from "../components/CouponTriggersTable.js";

/**
 * @summary Renders discounts page
 * @param {Object} props Component props
 * @return {React.Node} React node
 */
export default function CouponTriggersPage(props) {
  const shopId = props.selectedShopIds[0];

  return (
    <>
      <Toolbar title="Coupon Triggers" />
      <CouponTriggersTable {...props} shopId={shopId} />
    </>
  );
}
