import gql from "graphql-tag";

export const subscriptionPackages = gql`
  {
    subscriptionPackages {
      nodes {
        _id
        title
        subscriptionType {
          _id
          name
        }
        _id
        description
        rate: baseRate
        allowsPremiumShops
        duration: durationInDays
        additionalPremiumRate: additionalRatePerPremiumShop
        additionalStandardRate: additionalRatePerStandardShop
        commissionPremium: commissionPercentageForPremiumShops
        commissionStandard: commissionPercentageForStandardShops
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const currentSubscriptionPackage = gql`
  {
    currentSubscriptionPackage {
      _id
    }
  }
`;
