import gql from "graphql-tag";
import { SubscriptionType } from "./fragments";

export const removeSubscriptionType = gql`
  mutation removeSubscriptionType($id: ID!) {
    removeSubscriptionType(id: $id) {
      ${SubscriptionType}
    }
  }
`;

export const updateSubscriptionType = gql`
  mutation updateSubscriptionType($id: ID!, $input: UpdateSubscriptionTypeInput!) {
    updateSubscriptionType(id: $id, input: $input) {
      ${SubscriptionType}
    }
  }
`;

export const addSubscriptionType = gql`
  mutation addSubscriptionType($input: AddSubscriptionTypeInput!) {
    addSubscriptionType(input: $input) {
      ${SubscriptionType}
    }
  }
`;