import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { stripeListPaymentMethodsQuery } from "../graphql/queries.js";
import {
  stripeAddPaymentMethodMutation,
  stripeRemovePaymentMethodMutation,
} from "../graphql/mutations.js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import config from "../../../config.js";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "mdi-material-ui/Close";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import Button from "@reactioncommerce/catalyst/Button";
import { useConfirmDialog } from "@reactioncommerce/catalyst";
import styled from "styled-components";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2),
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  paymentMethodList: {
    marginBottom: theme.spacing(3),
  },
  dialogBody: {
    textAlign: "center",
  },
}));

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CheckoutForm = ({ onFinish }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    setIsCreating(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setIsCreating(false);

    onFinish(paymentMethod?.id, error);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <CardActions className={classes.cardActions}>
        <Button
          disabled={!stripe}
          isWaiting={isCreating}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </CardActions>
    </form>
  );
};

let paymentMethodIdToDelete = null;

const CreditCardSettingsPage = ({ viewer }) => {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const {
    openDialog: openRemoveCreditCardDialog,
    ConfirmDialog: RemoveCreditCardDialog,
  } = useConfirmDialog({
    title: "Remove Credit Card",
    message: "Are you sure you want to remove this Credit Card?",
    onConfirm: async () => {
      if (!paymentMethodIdToDelete) return;

      await stripeRemovePaymentMethod({
        variables: {
          input: {
            companyId: viewer.companyId,
            paymentMethodId: paymentMethodIdToDelete,
          },
        },
      });

      stripeGetListPayments();
    },
  });

  const [stripeAddPaymentMethod] = useMutation(stripeAddPaymentMethodMutation, {
    ignoreResults: true,
    onCompleted() {
      enqueueSnackbar("Successfully added payment method", { variant: "success" });
      stripeGetListPayments();
      setIsOpen(false);
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
      setIsOpen(false);
    },
  });

  const [stripeRemovePaymentMethod] = useMutation(stripeRemovePaymentMethodMutation, {
    ignoreResults: true,
    onCompleted() {
      enqueueSnackbar("Successfully removed payment method", { variant: "success" });
      stripeGetListPayments();
      setIsOpen(false);
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
      setIsOpen(false);
    },
  });

  const [stripeGetListPayments, { data, loading }] = useLazyQuery(stripeListPaymentMethodsQuery, {
    variables: {
      input: {
        companyId: viewer.companyId,
      },
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    stripeGetListPayments();
  }, []);

  if (loading) return "Loading...";

  const paymentMethods = data?.stripeListPaymentMethods || [];

  const AddCreditCardDialog = () => {
    const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);

    const onDialogClose = () => setIsOpen(false);
    const onFinish = async (paymentMethodId, error) => {
      if (error) return;

      setIsAddingPaymentMethod(true);
      await stripeAddPaymentMethod({
        variables: {
          input: {
            companyId: viewer.companyId,
            paymentMethodId,
          },
        },
      });
      setIsAddingPaymentMethod(false);
    };

    return (
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        classes={{ root: classes.cardRoot }}
        fullWidth
        maxWidth="sm"
      >
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={onDialogClose}>
              <CloseIcon />
            </IconButton>
          }
          title="New Card"
        />
        <CardContent className={classes.dialogBody}>
          {isAddingPaymentMethod ? (
            <CircularProgress />
          ) : (
            <Elements stripe={stripePromise}>
              <CheckoutForm onFinish={onFinish} />
            </Elements>
          )}
        </CardContent>
      </Dialog>
    );
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <AddCreditCardDialog />
      <RemoveCreditCardDialog />
      <Typography className={classes.title}>
        Add a Credit Card to settle your subscription and commission online
      </Typography>
      <List className={classes.paymentMethodList} dense={true}>
        {paymentMethods.map(paymentMethod => (
          <ListItem key={paymentMethod.id}>
            <ListItemAvatar>
              <Avatar>
                <CreditCardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${capitalizeFirstLetter(paymentMethod.brand)} *${
                paymentMethod.lastFourDigits
              }`}
              secondary={`exp ${paymentMethod.expiryMonth}/${paymentMethod.expiryYear}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  paymentMethodIdToDelete = paymentMethod.id;
                  openRemoveCreditCardDialog();
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <CardActions disableSpacing>
        <Button variant="contained" color="primary" onClick={() => setIsOpen(true)}>
          Add Credit Card
        </Button>
      </CardActions>
    </MuiThemeProvider>
  );
};

export default CreditCardSettingsPage;
