import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Box, makeStyles, Typography } from "@material-ui/core";
import { Button, TextField } from "@reactioncommerce/catalyst";
import { FormControlLabel, FormHelperText, Checkbox } from "@material-ui/core";
import useProduct from "../hooks/useProduct";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(4),
  },
}));

const defaultInventoryInStock = currentVariant => {
  return currentVariant?.simpleInventory?.inventoryInStock
    ? parseInt(currentVariant.simpleInventory.inventoryInStock)
    : 0;
};

const defaultCanBackorder = currentVariant => {
  return currentVariant?.simpleInventory?.canBackorder
    ? currentVariant.simpleInventory.canBackorder
    : false;
};

/**
 * @name VariantStockForm
 * @param {Object} props Component props
 * @param {Object} ref Forwarded ref
 * @returns {React.Component} Variant form React component
 */
const VariantStockForm = React.forwardRef(({ refreshToolbar }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentVariant, onUpdateProductVariantStock, product } = useProduct();

  const [isBeingChanged, setIsBeingChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inventoryInStock, setInventoryInStock] = useState(defaultInventoryInStock(currentVariant));
  const [canBackorder, setCanBackorder] = useState(defaultCanBackorder(currentVariant));
  const [validationError, setValidationError] = useState();

  useEffect(() => {
    setInventoryInStock(defaultInventoryInStock(currentVariant));
    setCanBackorder(defaultCanBackorder(currentVariant));
  }, [currentVariant]);

  const isSaveDisabled = !product || !isBeingChanged || isSubmitting;

  const submitForm = async () => {
    setValidationError(undefined);

    const isNumeric = str => {
      if (typeof str == "number") return true;
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    };

    if (!isNumeric(inventoryInStock)) {
      setValidationError("Inventory in stock must be an integer");
      return;
    }

    const inventoryInt = parseInt(inventoryInStock);

    if (inventoryInt < 0) {
      setValidationError("Inventory in stock must be postive");
      return;
    }

    const MAX_INT = 2147483647;

    if (inventoryInt > MAX_INT) {
      setValidationError(`Inventory stock should not be greater than ${MAX_INT}.`);
      return;
    }

    setIsSubmitting(true);

    await onUpdateProductVariantStock({
      variantId: currentVariant._id,
      variantStock: { inventoryInStock: inventoryInt, canBackorder },
    });

    setIsBeingChanged(false);
    refreshToolbar();
    setIsSubmitting(false);
  };

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={"Inventory"} /> {/* TODO translate */}
      <CardContent>
        {currentVariant &&
        Array.isArray(currentVariant.options) &&
        currentVariant.options.length > 0 ? (
          /* TODO translation */
          <span>
            Inventory is managed only for sellable variants. Select an option to manage inventory
            for it.
          </span>
        ) : (
          <>
            <TextField
              type="numeric"
              className={classes.textField}
              error={validationError !== undefined}
              fullWidth
              helperText={validationError}
              label={"Items in stock"} // TODO translation
              placeholder="0"
              value={inventoryInStock}
              onChange={e => {
                setIsBeingChanged(true);
                setInventoryInStock(e.target.value);
              }}
            />
            <FormControlLabel
              label="Unlimited stock" // TODO translation
              control={<Checkbox />}
              checked={canBackorder}
              onChange={e => {
                setIsBeingChanged(true);
                setCanBackorder(e.target.checked);
              }}
            />
            {/* TODO translate */}
            <FormHelperText>
              Ticking this will allow orders even when variant is out of stock
            </FormHelperText>

            <br />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {!isSaveDisabled && (
                <Box paddingRight={2}>
                  <Typography>
                    {/* TODO translation */}
                    Changes to inventory are published on save
                  </Typography>
                </Box>
              )}
              <Button
                color="primary"
                disabled={isSaveDisabled}
                isWaiting={isSubmitting}
                type="submit"
                variant="contained"
                onClick={submitForm}
              >
                {t("app.saveChanges")}
              </Button>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
});

export default VariantStockForm;
