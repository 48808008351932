import React from "react";

// Components
import Form from "../components/Form";

// Component
const Updater = props => {
  const { id, fields, update, readOne, goToDisplayPage, strings, additionalTabs } = props;

  return (
    <Form
      queries={{ update, readOne }}
      onCancel={goToDisplayPage}
      onUpdate={goToDisplayPage}
      fields={fields}
      formTitle={strings.formTitle}
      buttonText={strings.buttonText}
      mainTabLabel={strings.mainTabLabel}
      idToEdit={id}
      tabs={additionalTabs}
    />
  );
};

export default Updater;
