import { shopsQuery } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";

const ShopViewer = ({ companyId, viewer, history }) => {
  const displayFields = [
    { type: "string", name: "name", label: "Name", isSortable: true },
    { type: "string", name: "category", label: "Category", isSortable: "shopCategoryId" },
    { type: "boolean", name: "eCommerceEnabled", label: `eCommerce`, isSortable: true },
    { type: "string", name: "companyName", label: "Merchant", isSortable: "companyId" },
    { type: "number", name: "ratePreOverride", label: "Rate Pre Override (USD)" },
    { type: "number", name: "ratePostOverride", label: "Rate Post Override (USD)" },
    { type: "number", name: "commissionPreOverride", label: "Commission Pre Override (%)" },
    { type: "number", name: "commissionPostOverride", label: "Commission Post Override (%)" },
    { type: "number", name: "billingPeriodInDays", label: "Billing Period (days)" },
    { type: "dateTime", name: "createdAt", label: "Created On", isSortable: true },
  ];

  return (
    <CRUDer
      crud={{ state: "display" }}
      handlers={{
        goToUpdatePage: id => history.push(`/shops/edit/${id}`),
      }}
      strings={{}}
      fields={{
        display: displayFields,
      }}
      apis={{
        readAll: {
          gql: shopsQuery,
          formatInput: () => ({
            companyIds: [companyId],
            shopIds:
              (viewer?.adminUIShops &&
                viewer.adminUIShops.length &&
                viewer.adminUIShops.map(shop => shop._id)) ||
              [],
          }),
          formatOutput: output => ({
            nodes: output.shopsWithFilters.nodes.map(node => ({
              ...node,
              category: node?.getShopCategory?.name,
              companyName: node?.company?.name || "",
              ratePreOverride: node?.subscription.preOverride.rate,
              ratePostOverride: node?.subscription.postOverride.rate,
              commissionPreOverride: node?.subscription.preOverride.commissionPercentage,
              commissionPostOverride: node?.subscription.postOverride.commissionPercentage,
              billingPeriodInDays: node?.subscription.billingPeriodInDays,
            })),
            totalCount: output.shopsWithFilters.totalCount,
          }),
        },
        actions: [],
      }}
    ></CRUDer>
  );
};

export default ShopViewer;
