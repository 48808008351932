import React, { useState } from "react";
import PropTypes from "prop-types";
import SimpleSchema from "simpl-schema";
import Button from "@reactioncommerce/catalyst/Button";
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import muiOptions from "reacto-form/cjs/muiOptions";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import gql from "graphql-tag";
import { Query } from "react-apollo";
// import { Logger } from "/client/api";

import { getShopCategoriesQuery } from "../graphql/queries/shopCategories.js";

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: "250px",
    minWidth: 350,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const formSchema = new SimpleSchema({
  name: {
    type: String,
    min: 1,
  },
  shopCategory: {
    type: String,
    min: 1,
  },
});
const validator = formSchema.getFormValidator();

/**
 * CreateShopForm
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function CreateShopForm(props) {
  const { onSubmit } = props;

  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shopCategory, setShopCategory] = useState("");

  const { getFirstErrorMessage, getInputProps, hasErrors, submitForm } = useReactoForm({
    async onSubmit(...args) {
      setIsSubmitting(true);
      await onSubmit(...args);
      setIsSubmitting(false);
    },
    validator,
  });

  const handleShopCategoryChange = event => {
    setShopCategory(event.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.textField}
        label="Shop name"
        error={hasErrors(["name"])}
        fullWidth
        helperText={getFirstErrorMessage(["name"])}
        onKeyPress={event => {
          if (event.key === "Enter") submitForm();
        }}
        {...getInputProps("name", muiOptions)}
      />

      <Query query={getShopCategoriesQuery} fetchPolicy="network-only">
        {({ data, error }) => {
          if (error || !data) {
            if (error) console.error(error);
            return null;
          }

          const shopCategories = data?.getShopCategories?.nodes;

          // Render the dropdown
          return (
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-helper-label">Shop Category</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={shopCategory}
                onChange={handleShopCategoryChange}
                {...getInputProps("shopCategory", muiOptions)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {shopCategories.map(shopCategory => (
                  <MenuItem key={shopCategory._id} value={shopCategory._id}>
                    {shopCategory.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Please choose a category for this Shop</FormHelperText>
            </FormControl>
          );
        }}
      </Query>

      <Button
        color="primary"
        fullWidth
        disabled={isSubmitting}
        onClick={submitForm}
        variant="contained"
        className={classes.button}
      >
        {isSubmitting ? "Creating Shop..." : "Create Shop"}
      </Button>
    </div>
  );
}

CreateShopForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateShopForm;
