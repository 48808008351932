import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import SimpleSchema from "simpl-schema";
import styled from "styled-components";
import _ from "lodash";
import { Button, TextField } from "@reactioncommerce/catalyst";
import {
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import muiOptions from "reacto-form/cjs/muiOptions";
import useReactoForm from "reacto-form/cjs/useReactoForm";

// GraphQL
import createDiscountCodeGQL from "../graphql/mutations/createDiscountCode";
import updateDiscountCodeGQL from "../graphql/mutations/updateDiscountCode";
import getDiscountCodeGQL from "../graphql/queries/discountCode";

// Components
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import DateRangeSelector from "../../../package/src/DateRangeSelector";

// Constants
import { constants } from "../constants.js";

const {
  conditionMessage,
  productSelectionLabelForRates,
  dateRangeSelectionLabel,
  discountAmountLabel,
  discountAmountPlaceholder,
  calculationMethods,
  rateLabelLabel,
  rateLabelPlaceholder,
  discountMethods,
  successfulSaleCreation,
  successfulSaleUpdate,
} = constants;

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const useStyles = makeStyles(theme => ({
  deleteButton: {
    marginRight: "auto",
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  legend: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize * 0.9,
    lineHeight: 2,
  },
}));

const discountCodeSchema = new SimpleSchema({
  label: {
    type: String,
    defaultValue: "",
  },
  code: {
    type: String,
    optional: true,
    defaultValue: "",
  },
  calculation: Object,
  "calculation.method": {
    type: String,
  },
  conditions: {
    type: Object,
    optional: true,
  },
  "conditions.enabled": {
    type: Boolean,
    optional: true,
    defaultValue: true,
  },
  "conditions.accountLimit": {
    type: Number,
    optional: true,
  },
  "conditions.redemptionLimit": {
    type: Number,
    optional: true,
  },
  "conditions.order": {
    type: Object,
    defaultValue: {},
  },
  "conditions.order.startDate": {
    type: Date,
    optional: true,
  },
  "conditions.order.endDate": {
    type: Date,
    optional: true,
  },
  "conditions.audience": {
    type: Array,
    optional: true,
  },
  "conditions.audience.$": {
    type: String,
    optional: true,
  },
  "conditions.products": {
    type: Array,
    optional: true,
  },
  "conditions.products.$": {
    type: String,
    optional: true,
  },
  description: {
    type: String,
    optional: true,
  },
  discount: {
    type: Number,
  },
  discountMethod: {
    type: String,
    defaultValue: discountMethods.rate,
  },
});
const validator = discountCodeSchema.getFormValidator();

/**
 * @summary React component that renders the form for adding, updating, or deleting
 *   a rate record (sale).
 * @param {Object} props React props
 * @return {React.Node} React node
 */
export default function RateForm(props) {
  const { discountCodeId, shopId, history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // These are not handled by reactoform so we create separate states for them
  const [productSelection, setProductSelection] = useState([]);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: null, endDate: null });

  const onSuccess = () => {
    setIsSubmitting(false);
    history.push("/sale");
  };

  const onFailure = () => {
    setIsSubmitting(false);
    enqueueSnackbar(i18next.t("admin.discountCode.failure"), { variant: "warning" });
  };

  const { data: discountCodeData } = useQuery(getDiscountCodeGQL, {
    variables: {
      id: discountCodeId,
    },
    skip: !discountCodeId,
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  const discountCode = discountCodeData?.discountCode;

  // Fill the extra formData with `discountCodeData` fetched from server
  useEffect(() => {
    if (!discountCodeId) return;

    setProductSelection(
      discountCode?.conditions?.productDetails?.map(product => ({
        value: product._id,
        label: `${product?.title} - ${product.shop.name}`,
      })) || []
    );

    const startDate = discountCode?.conditions?.order?.startDate;
    const endDate = discountCode?.conditions?.order?.endDate;

    setDateRangeSelection({
      startDate: startDate && new Date(startDate),
      endDate: endDate && new Date(endDate),
    });
  }, [discountCode]);

  const [createDiscountCode] = useMutation(createDiscountCodeGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar(successfulSaleCreation, { variant: "success" });
    },
    onError() {
      onFailure();
    },
  });

  const [updateDiscountCode] = useMutation(updateDiscountCodeGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar(successfulSaleUpdate, { variant: "success" });
    },
    onError() {
      onFailure();
    },
  });

  const { getFirstErrorMessage, getInputProps, isDirty, hasErrors, submitForm } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      // formData coming from reactoform does not contain everything inside  it.
      // We have separate states as well --> add them
      if (!formData?.conditions) formData.conditions = {};

      formData.conditions.products = Array.isArray(productSelection)
        ? productSelection.map(product => product.value)
        : null;

      if (dateRangeSelection?.startDate || dateRangeSelection?.endDate) {
        formData.conditions.order = {};

        if (dateRangeSelection?.startDate)
          formData.conditions.order.startDate = dateRangeSelection.startDate;

        if (dateRangeSelection?.endDate)
          formData.conditions.order.endDate = dateRangeSelection.endDate;
      }

      const discountCodeInput = discountCodeSchema.clean(formData);
      if (discountCodeInput.conditions) {
        // Set order minimum to 0, this will allow a discount to be
        // Redeemed infinitely on any number of orders.
        _.set(discountCodeInput, "conditions.order.min", 0);
      }

      if (discountCode) {
        // Update sale
        await updateDiscountCode({
          variables: {
            input: {
              discountCode: discountCodeInput,
              discountCodeId,
              shopId,
            },
          },
        });
      } else {
        // Create new sale
        await createDiscountCode({
          variables: {
            input: {
              discountCode: discountCodeInput,
              shopId,
            },
          },
        });
      }
    },
    validator(formData) {
      return validator(discountCodeSchema.clean(formData));
    },
    value: discountCode,
    logErrorsOnSubmit: true,
  });

  let calculationMethodField;
  if (Array.isArray(calculationMethods) && calculationMethods.length) {
    const options = calculationMethods.map(({ value, label }) => ({ label, value }));
    calculationMethodField = (
      <TextField
        error={hasErrors(["calculation.method"])}
        fullWidth
        helperText={getFirstErrorMessage(["calculation.method"])}
        label={`${i18next.t("admin.discountCode.form.calculationMethod")} *`}
        onKeyPress={event => {
          if (event.key === "Enter") submitForm();
        }}
        select
        {...getInputProps("calculation.method", muiOptions)}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  const classes = useStyles();

  return (
    <Card style={{ overflow: "visible" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={hasErrors(["label"])}
              fullWidth
              helperText={getFirstErrorMessage(["label"])}
              label={rateLabelLabel}
              placeholder={rateLabelPlaceholder}
              {...getInputProps("label", muiOptions)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              error={hasErrors(["discount"])}
              fullWidth
              helperText={getFirstErrorMessage(["discount"])}
              label={discountAmountLabel}
              placeholder={discountAmountPlaceholder}
              {...getInputProps("discount", muiOptions)}
            />
          </Grid>
          <Grid item xs={12}>
            {calculationMethodField}
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="label" classes={{ root: classes.label }}>
              {dateRangeSelectionLabel}
            </FormLabel>
            <DateRangeSelector dateRange={dateRangeSelection} onChange={setDateRangeSelection} />
          </Grid>
        </Grid>

        <br />
        <Divider />
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component="legend" classes={{ root: classes.legend }}>
              Conditions
            </FormLabel>
            {conditionMessage}
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="label" classes={{ root: classes.label }}>
              {productSelectionLabelForRates}
            </FormLabel>
            <ProductSelectorWithData
              shouldShowShopName
              shopIds={[shopId]}
              value={productSelection}
              onSelection={setProductSelection}
            />
          </Grid>
        </Grid>

        <br />

        <CardActions disableSpacing>
          <Button
            disabled={isSubmitting /*  || !isDirty */}
            onClick={submitForm}
            variant="contained"
            color="primary"
          >
            {i18next.t("app.save")}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
