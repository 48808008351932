import React, { useState } from "react";
import { mediaRecordsQuery } from "../graphql/queries";
import { deleteMediaRecordMutation } from "../graphql/mutations";
import Displayer from "../../../package/src/CRUDer/Displayer";
import MediaGalleryUploader from "../components/MediaGalleryUploader";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";
import Toolbar from "../../../package/src/Toolbar";

const useStyles = makeStyles(theme => ({
  declineAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
}));

const MediaGalleryPage = props => {
  const { selectedShopIds } = props;
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();

  if (selectedShopIds.length !== 1) {
    return <span>Please select only one shop</span>;
  }

  const fields = [
    { type: "image", name: "url", label: "Image", imageWidth: 165 },
    { type: "string", name: "_id", label: "ID", isSortable: true },
    { type: "string", name: "name", label: "Name", isSortable: true },
    { type: "dateTime", name: "uploadedAt", label: "Uploaded on", isSortable: true },
  ];

  const strings = {
    noData: "No Media",
    filterPlaceholder: "Search for images (by name or ID)",
  };

  const actions = [
    {
      label: "Delete",
      gql: deleteMediaRecordMutation,
      formatInput: id => ({
        input: { mediaRecordId: id, shopId: selectedShopIds[0] },
      }),
      formatOutput: output => output.mediaRecord,
      shouldAppearInTable: true,
      IconComponent: DeclineIcon,
      className: classes.declineAction,
    },
  ];

  const readAll = {
    gql: mediaRecordsQuery,
    formatInput: (globalFilter, dateRange) => ({
      shopId: selectedShopIds[0],
      genericFilters: {
        dateRange: {
          gte: dateRange.startDate,
          lte: dateRange.endDate,
          field: "original.uploadedAt",
        },
      },
    }),
    formatOutput: output => ({
      nodes: output.mediaRecords.nodes.map(node => ({
        ...node,
        url: node?.imageURLs?.original,
        name: node?.original?.name,
        uploadedAt: node?.original?.uploadedAt,
      })),
      totalCount: output.mediaRecords.totalCount,
    }),
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Media Gallery" />
      <MediaGalleryUploader shopId={selectedShopIds[0]} onSuccess={() => setRefresh(!refresh)} />
      <br />
      <Displayer fields={fields} readAll={readAll} actions={actions} strings={strings} />
    </MuiThemeProvider>
  );
};

export default MediaGalleryPage;
