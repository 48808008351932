export const SubscriptionPackage = `
_id
title
description
subscriptionType {
    _id
    name
}
baseRate
allowsPremiumShops
isVisibleToMerchants
durationInDays
additionalRatePerPremiumShop
additionalRatePerStandardShop
commissionPercentageForPremiumShops
commissionPercentageForStandardShops
createdAt
updatedAt
`;
