import CashIcon from "mdi-material-ui/CashMultiple";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FinancialsPage from "./pages/FinancialsPage";
import FinancialsSettingsPage from "./pages/FinancialsSettingsPage";
import SettingsDashboard from "../../package/src/SettingsDashboard";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock, registerSetting }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/financials/:tab",
    href: "/financials/0",
    MainComponent: FinancialsPage,
    IconComponent: AccountBalanceWalletIcon,
    navigationItemLabel: "Balance",
    collapse: {
      title: "Financials",
      IconComponent: CashIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerSetting({
    name: "financials",
    group: "settings",
    priority: 80,
    LayoutComponent: SettingsDashboard,
    MainComponent: FinancialsSettingsPage,
    navigationItemLabel: "Financials",
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
