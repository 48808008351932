import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import SimpleSchema from "simpl-schema";
import styled from "styled-components";
import { Button, TextField } from "@reactioncommerce/catalyst";
import { Grid, Card, CardContent } from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";
import muiOptions from "reacto-form/cjs/muiOptions";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import Select from "@reactioncommerce/components/Select/v1";
import { useConfirmDialog } from "@reactioncommerce/catalyst";
import moment from "moment";
import { createTransactionGQL } from "../graphql/mutations.js";
import { updateTransactionGQL } from "../graphql/mutations.js";
import { transactionQuery } from "../graphql/queries.js";
import { subscriptionHistoryRecords } from "../graphql/queries.js";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import { constants } from "../../../constants.js";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const transactionSchema = new SimpleSchema({
  companyId: {
    type: String,
    optional: true,
  },
  type: {
    type: String,
    optional: true,
  },
  subscriptionId: {
    type: String,
    optional: true,
  },
  recipient: {
    type: String,
    optional: true,
  },
  amount: {
    type: Number,
    optional: true,
  },
  notes: {
    type: String,
    optional: true,
  },
});
const validator = transactionSchema.getFormValidator();

let formDataGlobal = null;

export default function TransactionsForm(props) {
  const { transactionId, viewer, onFail = () => {}, onFinish = () => {} } = props;
  const isGodmode = viewer.type === "godmode";

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState("commission");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [paymentDirection, setPaymentDirection] = useState("godmode");

  // These are not handled by reactoform so we create separate states for them
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: [],
    selectedShopIds: [],
  });

  const {
    openDialog: openPaymentConfirmationDialog,
    ConfirmDialog: PaymentConfirmationDialog,
  } = useConfirmDialog({
    title: "Confirm Payment",
    message:
      "Are you sure you want to proceed? This will charge your active Credit Card immediately",
    onConfirm: async () => {
      if (!formDataGlobal) return;
      submitTransactionRequest(formDataGlobal);
      formDataGlobal = null;
    },
  });

  const onSuccess = () => {
    setIsSubmitting(false);
    onFinish();
  };

  const onFailure = error => {
    console.log("error= ", JSON.stringify(error));
    const message = error.message.replace("GraphQL error:", "");
    setIsSubmitting(false);
    enqueueSnackbar(message, { variant: "error" });

    onFail();
  };

  const { data: transactionData, isLoading: transactionDataLoading } = useQuery(transactionQuery, {
    variables: {
      id: transactionId,
    },
    skip: !transactionId,
    fetchPolicy: "network-only",
    onError(error) {
      onFailure(error);
    },
  });

  const transaction = transactionData?.transactionBetweenMerchantAndGodmode;

  // Fill the extra formData with `discountCodeData` fetched from server
  useEffect(() => {
    if (!transactionId || transactionDataLoading) return;

    if (transaction?.companyId)
      setSelectedMerchants({
        selectedCompanyIds: [transaction.companyId],
        selectedShopIds: [],
      });

    if (transaction?.type) setType(transaction.type);

    if (transaction?.recipient) setPaymentDirection(transaction.recipient);

    if (transaction?.subscriptionId) setSubscriptionId(transaction?.subscriptionId);
  }, [transaction]);

  const { data: subscriptionsData, refetch: refetchSubscriptionsData } = useQuery(
    subscriptionHistoryRecords,
    {
      variables: {
        // The selected companyId if godmode, our current companyId if merchant
        // since a merchant can only pay from himself
        companyIds: isGodmode ? selectedMerchants.selectedCompanyIds : [viewer.companyId],
      },
      fetchPolicy: "network-only",
      onError(error) {
        console.error(error);
      },
    }
  );

  let subscriptionDropdownOptions = subscriptionsData?.subscriptionHistoryRecords.nodes.map(
    node => {
      if (node.paymentStatus !== "pending") return null;

      return {
        label: `${node._id}: ${moment(node.date).format("MMMM Do YYYY, h:mm:ss a")} - $${
          node.paymentAmount
        } - ${node.description}`,
        value: node._id,
      };
    }
  );

  // Filter out `null` entries (i.e. the ones that are already settled)
  subscriptionDropdownOptions = subscriptionDropdownOptions?.filter(option => !!option);

  useEffect(() => {
    try {
      refetchSubscriptionsData();
    } catch (error) {}
  }, [selectedMerchants]);

  const [createTransaction] = useMutation(createTransactionGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar("Successfully created transaction", { variant: "success" });
    },
    onError(error) {
      onFailure(error);
    },
  });

  const [updateTransaction] = useMutation(updateTransactionGQL, {
    ignoreResults: true,
    onCompleted() {
      onSuccess();
      enqueueSnackbar("Successfully updated transaction", { variant: "success" });
    },
    onError(error) {
      onFailure(error);
    },
  });

  const submitTransactionRequest = async formData => {
    setIsSubmitting(true);

    formData.type = type;
    formData.companyId = isGodmode ? selectedMerchants.selectedCompanyIds[0] : viewer.companyId;
    formData.recipient = type === "subscription" ? "godmode" : paymentDirection;
    formData.subscriptionId = subscriptionId;

    // A merchant can only pay to godmode
    if (!isGodmode) {
      formData.recipient = "godmode";
    }

    const transactionInput = transactionSchema.clean(formData);
    transactionInput.paymentMethod = isGodmode ? "offline" : "online";

    if (transactionId) {
      await updateTransaction({
        variables: {
          id: transactionId,
          input: transactionInput,
        },
      });
    } else {
      await createTransaction({
        variables: {
          input: transactionInput,
        },
      });
    }

    setIsSubmitting(false);
  };

  const { getFirstErrorMessage, getInputProps, isDirty, hasErrors, submitForm } = useReactoForm({
    async onSubmit(formData) {
      if (isGodmode && !selectedMerchants.selectedCompanyIds[0]) {
        enqueueSnackbar("You need to pick a merchant first!", { variant: "warning" });
        return;
      }
      formDataGlobal = formData;

      // No need for a confirmation dialog when Godmode is creating the payment
      if (isGodmode) {
        submitTransactionRequest(formData);
      } else {
        openPaymentConfirmationDialog();
      }
    },
    validator(formData) {
      return validator(transactionSchema.clean(formData));
    },
    value: transaction,
    logErrorsOnSubmit: true,
  });

  return (
    <Card style={{ overflow: "visible" }}>
      <PaymentConfirmationDialog />
      <CardContent>
        <Grid container spacing={2}>
          {isGodmode && (
            <Grid item xs={12}>
              <MerchantSelectorWithData
                shouldShowShopName
                size={32}
                viewer={viewer}
                selectedCompanyIds={selectedMerchants.selectedCompanyIds}
                onMerchantSelectionChange={setSelectedMerchants}
                isMultiSelect={false}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            Transaction Type
            <Select
              name="type"
              options={[
                { label: "commission", value: "commission" },
                { label: "subscription", value: "subscription" },
                { label: "debit", value: "debit" },
                { label: "credit", value: "credit" },
              ]}
              value={type}
              onChange={setType}
            />
          </Grid>

          {type === "subscription" ? (
            <Grid item xs={12}>
              Subscription
              <Select
                name="subscriptionId"
                options={subscriptionDropdownOptions}
                value={subscriptionId}
                onChange={setSubscriptionId}
              />
            </Grid>
          ) : (
            <>
              {isGodmode && (
                <Grid item xs={12}>
                  Payment Direction
                  <Select
                    name="recipient"
                    options={[
                      { label: `${constants.APP_NAME} to Merchant`, value: "merchant" },
                      { label: `Merchant to ${constants.APP_NAME}`, value: "godmode" },
                    ]}
                    onChange={setPaymentDirection}
                    value={paymentDirection}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                Amount (USD)
                <TextField
                  type="number"
                  error={hasErrors(["amount"])}
                  fullWidth
                  helperText={getFirstErrorMessage(["amount"])}
                  {...getInputProps("amount", muiOptions)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            Notes
            <TextField
              error={hasErrors(["notes"])}
              fullWidth
              helperText={getFirstErrorMessage(["notes"])}
              {...getInputProps("notes", muiOptions)}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>

        <br />

        <CardActions disableSpacing>
          <Button
            isWaiting={isSubmitting}
            disabled={isSubmitting /*  || !isDirty */}
            onClick={submitForm}
            variant="contained"
            color="primary"
          >
            {transactionId ? "Save" : isGodmode ? "Create" : "Pay"}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
