/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f1e4f3db-20d5-48fb-9112-c4504349f961",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5dJOLTTrs",
    "aws_user_pools_web_client_id": "5gq297fn28m76ml8fsorsj0me0",
    "oauth": {},
    "aws_user_files_s3_bucket": "obuy-assets113229-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
