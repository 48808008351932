import SettingsDashboard from "../../package/src/SettingsDashboard";
import CreditCardSettingsPage from "./pages/CreditCardSettingsPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock, registerSetting }) {
  registerSetting({
    name: "credit-card",
    group: "settings",
    priority: 90,
    LayoutComponent: SettingsDashboard,
    MainComponent: CreditCardSettingsPage,
    navigationItemLabel: "Credit Card",
    merchantOnly: true,
    groupSlugs: [
      // constants.ACCOUNT_TYPES.godmodeAdmin,
      // constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      // constants.ACCOUNT_TYPES.godmodeAccountManager,
      // constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });
}
