import { Select } from "@reactioncommerce/catalyst";
import { useApolloClient } from "@apollo/react-hooks";
import productsQuery from "../graphql/queries/products";

/**
 * ProductSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ProductSelectorWithData({
  shopIds,
  excludeIds = [],
  shouldShowShopName = false,
  value,
  onSelection,
}) {
  const apolloClient = useApolloClient();

  const fetchProducts = async query => {
    const { data, error } = await apolloClient.query({
      query: productsQuery,
      variables: {
        shopIds,
        query,
      },
      fetchPolicy: "network-only",
    });

    let options = [];
    if (!error && data) {
      options = data?.products?.nodes
        ?.filter(product => !excludeIds.includes(product._id))
        .map(product => {
          const appendShopName = shouldShowShopName ? ` - ${product.shop.name}` : "";

          return {
            value: product._id,
            label: `${product.title}${appendShopName}`,
          };
        });
    }

    return options;
  };

  return (
    <Select
      isMulti
      isAsync
      defaultOptions
      value={value}
      loadOptions={fetchProducts}
      onSelection={onSelection}
      placeholder="Search products"
    />
  );
}

export default ProductSelectorWithData;
