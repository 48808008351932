import gql from "graphql-tag";

export const assignCategoryMutation = gql`
  mutation AssignShopCategory($shopCategoryId: ID!, $shopId: ID!) {
    assignCategoryToShop(shopCategoryId: $shopCategoryId, shopId: $shopId) {
      shopCategory {
        name
      }
    }
  }
`;
