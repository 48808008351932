import gql from "graphql-tag";
import { Shop, ShopCategory, BriefShop, MerchantRequest } from "./fragments";

export const shopsQuery = gql`
  query shopsWithFilters($shopIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortOrder: SortOrder, $sortBy: ShopsWithFiltersSortByField, $filter: String, $genericFilters: GenericFilterInput) {
    shopsWithFilters(shopIds: $shopIds, first: $first, last: $last, before: $before, after: $after, offset: $offset, sortOrder: $sortOrder, sortBy: $sortBy, filter: $filter, genericFilters: $genericFilters) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${Shop}
      }
      totalCount
    }
  }
`;

export const shopQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      ${Shop}
    }
  }
`;

export const briefShopQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      ${BriefShop}
    }
  }
`;

export const shopWithProductCategoriesQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      productCategories {
        _id
        name
      }
    }
  }
`;

export const shopWithProductBrandsQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      productBrands {
        _id
        name
      }
    }
  }
`;

export const shopCategoriesQuery = gql`
  query getShopCategories($shopCategoryIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $filter: String, $offset: Int) {
    getShopCategories(shopCategoryIds: $shopCategoryIds, first: $first, last: $last, before: $before, after: $after, filter: $filter, offset: $offset) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${ShopCategory}
      }
      totalCount
    }
  }
`;

export const pendingMerchantRequestQuery = gql`
  query pendingMerchantRequest($shopId: ID!, $type: MerchantRequestType!) {
    pendingMerchantRequest(shopId: $shopId, type: $type) {
      ${MerchantRequest}
    }
  }
`;

export const allProductCategoriesQuery = gql`
  query productCategories(
    $productCategoryIds: [ID]!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
  ) {
    productCategories(productCategoryIds: $productCategoryIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const allProductBrandsQuery = gql`
  query productBrands(
    $productBrandIds: [ID]!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
  ) {
    productBrands(productBrandIds: $productBrandIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const shopSubscriptionOverrides = gql`
  query shopSubscriptionOverrides($id: ID!) {
    shopSubscriptionOverrides(id: $id) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      commission {
        newValue
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;

export const additionalRateForNewShopQuery = gql`
  query additionalRateForNewShop($companyId: ID!, $shopLevel: levelEnum) {
    additionalRateForNewShop(companyId: $companyId, shopLevel: $shopLevel) {
      additionalRate
      billingPeriodInDays
    }
  }
`;

export const allCompanies = gql`
  query companies(
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: CompanySortByField
    $filter: String
    $genericFilters: GenericFilterInput
  ) {
    companies(
      companyIds: []
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      filter: $filter
      genericFilters: $genericFilters
    ) {
      nodes {
        _id
        name
        description
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;
