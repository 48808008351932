import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Zoom,
  Button,
  Card as MuiCard,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "mdi-material-ui/Close";
import CalendarImport from "mdi-material-ui/CalendarImport";
import ImportIcon from "mdi-material-ui/Download";
import { Chip } from "@reactioncommerce/catalyst";
import clsx from "clsx";
import Amplify, { Storage } from "aws-amplify";
import { downloadBlob } from "../../../package/src/utils/downloadBlob.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
  helpText: {
    // marginLeft: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftChip: {
    marginRight: theme.spacing(1),
  },
  centeredContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

/**
 * BulkImportCard component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
export default function BulkImportCard(props) {
  const {
    files,
    getInputProps,
    getRootProps,
    handleDelete,
    importFiles,
    isFilterByFileVisible,
    setFilterByFileVisible,
    isImportLoading,
    numberOfImportedItems,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const cardClasses = clsx({
    [classes.hidden]: true,
    [classes.visible]: isFilterByFileVisible,
  });

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: Amplify._config.aws_user_files_s3_bucket,
        region: Amplify._config.aws_user_files_s3_bucket_region,
        customPrefix: {
          public: "",
        },
      },
    });
  }, []);

  const onDownloadExampleCSV = async () => {
    await Storage.get("templates/product-import.xlsx", {
      download: true,
    }).then(res => downloadBlob(res.Body, "product-import.xlsx"));
  };

  return isImportLoading ? (
    <Grid item sm={12} className={cardClasses}>
      <Zoom in={isFilterByFileVisible} mountOnEnter unmountOnExit>
        <MuiCard>
          <div className={classes.centeredContainer}>
            <CircularProgress />
          </div>
        </MuiCard>
      </Zoom>
    </Grid>
  ) : (
    <Grid item sm={12} className={cardClasses}>
      <Zoom in={isFilterByFileVisible} mountOnEnter unmountOnExit>
        <MuiCard>
          <CardHeader
            action={
              <IconButton aria-label="close" onClick={() => setFilterByFileVisible(false)}>
                <CloseIcon />
              </IconButton>
            }
            title={"Bulk Import"}
          />
          <CardContent>
            {files.length > 0 ? (
              <Grid container spacing={1} className={classes.cardContainer}>
                <Grid item sm={12}>
                  {files.map((file, index) => (
                    <Chip
                      label={file.name}
                      key={index}
                      className={classes.leftChip}
                      onDelete={() => handleDelete(file.name)}
                    />
                  ))}
                </Grid>
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={() => importFiles(files)}
                  >
                    {"Bulk Import"}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} className={classes.cardContainer}>
                <Grid item sm={12}>
                  <Typography variant="caption" display="inline" className={classes.helpText}>
                    Upload a .csv file in the correct format to bulk import many products and
                    variants with the click of a button.
                  </Typography>

                  <br />
                  <br />

                  <Button
                    {...getRootProps({ className: "dropzone" })}
                    variant="contained"
                    color="primary"
                  >
                    <input {...getInputProps()} />
                    <CalendarImport className={classes.leftIcon} />
                    Import
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ marginLeft: "10px" }}
                    onClick={onDownloadExampleCSV}
                  >
                    <ImportIcon className={classes.leftIcon} />
                    Sample Data
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </MuiCard>
      </Zoom>
    </Grid>
  );
}

BulkImportCard.propTypes = {
  files: PropTypes.array,
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  handleDelete: PropTypes.func,
  importFiles: PropTypes.func,
  isFilterByFileVisible: PropTypes.bool,
  setFilterByFileVisible: PropTypes.func,
};
