import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "@reactioncommerce/catalyst";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FileRecord } from "@reactioncommerce/file-collections";
import { createMediaRecordAndImageURLsMutation } from "../graphql/mutations";

/**
 * MediaGalleryUploader
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function MediaGalleryUploader(props) {
  const { onFiles, shopId, onSuccess } = props;

  const [isUploading, setIsUploading] = useState(false);
  const [numUploaded, setNumUploaded] = useState(0);
  const [totalToUpload, setTotalToUpload] = useState(0);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [createMediaRecordAndImageURLs] = useMutation(createMediaRecordAndImageURLsMutation, {
    ignoreResults: true,
  });

  const uploadFiles = async acceptedFiles => {
    const filesArray = Array.from(acceptedFiles);

    setTotalToUpload(filesArray.length);
    setIsUploading(true);

    for (let browserFile of filesArray) {
      // Paranthesis cause issues
      const newFileName = browserFile.name.replace(")", "").replace("(", "");
      const myNewFile = new File([browserFile], newFileName, { type: browserFile.type });
      const fileRecord = FileRecord.fromFile(myNewFile);

      // Listen for upload progress events
      // fileRecord.on("uploadProgress", uploadProgress => {
      //   setUploadProgress(uploadProgress);
      // });

      // Required
      fileRecord.metadata = {};

      // Do the upload. chunkSize is optional and defaults to 5MB
      await fileRecord.upload();

      // We insert only AFTER the server has confirmed that all chunks were uploaded
      await createMediaRecordAndImageURLs({
        variables: {
          input: {
            mediaRecord: fileRecord.document,
            shopId,
          },
          imageSize: "original",
        },
      });

      setNumUploaded(prevNum => prevNum + 1);
    }

    setIsUploading(false);
    setNumUploaded(0);

    // Inform caller that we're done uploading
    onSuccess();
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/png, image/jpeg",
    disableClick: true,
    disablePreview: true,
    multiple: true,
    onDrop(files) {
      if (files.length === 0) return;

      // Pass onFiles func to circumvent default uploader
      if (onFiles) {
        onFiles(files);
      } else {
        uploadFiles(files);
      }
    },
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      {isUploading ? (
        <>
          <LinearProgress />
          <>{`Uploaded ${numUploaded}/${totalToUpload} (${parseInt(
            (numUploaded / totalToUpload) * 100
          )}%)`}</>
        </>
      ) : (
        <Button fullWidth size="large" variant="outlined">
          Drag images or click to upload
        </Button>
      )}
    </div>
  );
}

MediaGalleryUploader.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  shopId: PropTypes.string,
};

export default MediaGalleryUploader;
