import ShapeIcon from "mdi-material-ui/Shape";
import StoreIcon from "mdi-material-ui/Store";
import ShopCategoriesPage from "./pages/ShopCategoriesPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 5,
    path: "/shop-categories",
    MainComponent: ShopCategoriesPage,
    IconComponent: ShapeIcon,
    navigationItemLabel: "Shop Categories",
    showShopSelector: false,
    godmodeOnly: true,
    collapse: {
      title: "Shops",
      IconComponent: StoreIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/shop-categories/create",
    MainComponent: ShopCategoriesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/shop-categories/edit/:shopCategoryId",
    MainComponent: ShopCategoriesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
