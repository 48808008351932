import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import SimpleSchema from "simpl-schema";
import muiOptions from "reacto-form/cjs/muiOptions";
import { TextField, useConfirmDialog } from "@reactioncommerce/catalyst";
import useGenerateSitemaps from "../hooks/useGenerateSitemaps";
import useProduct from "../hooks/useProduct";
import { slugify } from "transliteration";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(4),
    minWidth: 350,
  },
}));

const formSchema = new SimpleSchema({
  title: {
    type: String,
    optional: true,
  },
  pageTitle: {
    type: String,
    optional: true,
  },
  metaDescription: {
    type: String,
    optional: true,
  },
  description: {
    type: String,
    optional: true,
  },
  purchaseNotes: {
    type: String,
    optional: true,
  },
  // originCountry: {
  //   type: String,
  //   optional: true
  // },
  shouldAppearInSitemap: {
    type: Boolean,
    optional: true,
  },
});

const validator = formSchema.getFormValidator();

/**
 * @name ProductDetailForm
 * @param {Object} props Component props
 * @returns {React.Component} Product detail form react component
 */
const ProductDetailForm = React.forwardRef((props, ref) => {
  const { viewer, refreshToolbar } = props;

  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const { onUpdateProduct, product, shopIds, refetchProduct } = useProduct();

  const { generateSitemaps } = useGenerateSitemaps(shopIds[0]);

  const companyName = viewer?.adminUIShops.find(shop => shop._id === shopIds[0])?.company?.name;

  const {
    openDialog: openGenerateSitemapsConfirmDialog,
    ConfirmDialog: GenerateSitemapsConfirmDialog,
  } = useConfirmDialog({
    title: t("productDetailEdit.refreshSitemap", {
      defaultValue: "Refresh sitemap now?",
    }),
    cancelActionText: t("productDetailEdit.refreshSitemapNo", {
      defaultValue: "No, don't refresh",
    }),
    confirmActionText: t("productDetailEdit.refreshSitemapYes", {
      defaultValue: "Yes, refresh",
    }),
    onConfirm: () => {
      generateSitemaps();
    },
  });

  let content;

  const { getFirstErrorMessage, getInputProps, hasErrors, isDirty, submitForm } = useReactoForm({
    async onSubmit(formData) {
      const shouldConformSitemapGenerate =
        formData.shouldAppearInSitemap !== product.shouldAppearInSitemap &&
        formData.isVisible &&
        !formData.isDeleted;

      setIsSubmitting(true);

      const cleanFormData = formSchema.clean(formData);

      await onUpdateProduct({
        product: {
          ...cleanFormData,
          slug: slugify(cleanFormData.title),
          vendor: companyName,
        },
      });

      refreshToolbar();

      if (shouldConformSitemapGenerate) {
        openGenerateSitemapsConfirmDialog();
      }

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
    value: product,
  });

  // const originCountryInputProps = getInputProps("originCountry", muiOptions);

  if (product) {
    content = (
      <form
        onSubmit={event => {
          event.preventDefault();
          submitForm();
        }}
      >
        <TextField
          className={classes.textField}
          error={hasErrors(["title"])}
          fullWidth
          helperText={getFirstErrorMessage(["title"])}
          label={t("productDetailEdit.title")}
          {...getInputProps("title", muiOptions)}
        />
        <TextField
          className={classes.textField}
          error={hasErrors(["pageTitle"])}
          fullWidth
          helperText={getFirstErrorMessage(["pageTitle"])}
          label={t("productDetailEdit.pageTitle")}
          {...getInputProps("pageTitle", muiOptions)}
        />
        <TextField
          className={classes.textField}
          error={hasErrors(["metaDescription"])}
          fullWidth
          helperText={getFirstErrorMessage(["metaDescription"])}
          label={"Short Description"}
          {...getInputProps("metaDescription", muiOptions)}
        />

        <TextField
          className={classes.textField}
          error={hasErrors(["description"])}
          fullWidth
          multiline={true}
          rows={5}
          helperText={getFirstErrorMessage(["description"])}
          label={"Long Description"}
          {...getInputProps("description", muiOptions)}
        />
        <TextField
          className={classes.textField}
          error={hasErrors(["purchaseNotes"])}
          fullWidth
          multiline={true}
          rows={5}
          helperText={getFirstErrorMessage(["purchaseNotes"])}
          label={"Purchase Notes"}
          {...getInputProps("purchaseNotes", muiOptions)}
        />
        {/* <TextField
          className={classes.textField}
          error={hasErrors(["originCountry"])}
          fullWidth
          helperText={getFirstErrorMessage(["originCountry"])}
          label={t("productDetailEdit.originCountry")}
          onKeyPress={(event) => {
            if (event.key === "Enter") submitForm();
          }}
          select
          {...originCountryInputProps}
          value={originCountryInputProps.value || ""}
        >
          {CountryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
        {/* <FormControlLabel
          label={t("productDetailEdit.shouldAppearInSitemap")}
          control={<Checkbox />}
          {...getInputProps("shouldAppearInSitemap", muiCheckboxOptions)}
        /> */}
        <Box textAlign="right">
          <Button
            color="primary"
            disabled={!isDirty || isSubmitting}
            variant="contained"
            type="submit"
          >
            {t("app.saveChanges")}
          </Button>
        </Box>
        <GenerateSitemapsConfirmDialog />
      </form>
    );
  }

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={t("admin.productAdmin.details")} />
      <CardContent>{content}</CardContent>
    </Card>
  );
});

export default ProductDetailForm;
