import gql from "graphql-tag";

export const stripeListPaymentMethodsQuery = gql`
  query stripeListPaymentMethods($input: StripeListPaymentMethodsInput!) {
    stripeListPaymentMethods(input: $input) {
      id
      brand
      expiryMonth
      expiryYear
      lastFourDigits
    }
  }
`;
