import { productBrand, productBrands } from "../graphql/queries";
import { addProductBrand, updateProductBrand, removeProductBrand } from "../graphql/mutations";
import CRUDer from "../../../package/src/CRUDer";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";
import Toolbar from "../../../package/src/Toolbar";

const useStyles = makeStyles(theme => ({
  declineAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
}));

const ProductBrandsPage = props => {
  const { match, history } = props;
  const classes = useStyles();
  const productBrandId = match?.params?.productBrandId || null;
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  const displayFields = [
    { type: "string", name: "name", label: "Brand", isSortable: true },
    { type: "string", name: "createdBy", label: "Created By", isSortable: "accountId" },
    { type: "dateTime", name: "createdAt", label: "Created On", isSortable: true },
  ];

  const createAndUpdateFields = [
    { type: "string", name: "name", label: "Name", placeholder: "e.g. Adidas" },
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Product Brands" />
      <CRUDer
        crud={{ state: crudState, id: productBrandId }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`/product-brands/edit/${id}`);
          },
          goToCreatePage: () => {
            history.push(`/product-brands/create`);
          },
          goToDisplayPage: () => {
            history.push(`/product-brands`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Product Brand",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Product Brand",
          },
          creator: {
            formTitle: "Create Product Brand",
            buttonText: "Add Product Brand",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Product Brand",
            buttonText: "Save Product Brand",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: createAndUpdateFields,
          create: createAndUpdateFields,
          display: displayFields,
        }}
        apis={{
          create: {
            gql: addProductBrand,
            formatInput: input => ({ name: input.name }),
            formatOutput: output => output?.addProductBrand,
          },
          readOne: {
            gql: productBrand,
            formatInput: id => ({ id }),
            formatOutput: output => output?.productBrand,
          },
          readAll: {
            gql: productBrands,
            formatInput: () => ({ productBrandIds: [] }),
            formatOutput: output => ({
              nodes: output.productBrands.nodes.map(node => {
                const email = node?.createdBy?.primaryEmailAddress
                  ? node?.createdBy?.primaryEmailAddress
                  : "";
                const name = node?.createdBy?.name ? node?.createdBy?.name : "";

                return {
                  ...node,
                  createdBy: `${email} - ${name}`,
                };
              }),
              totalCount: output.productBrands.totalCount,
            }),
          },
          update: {
            gql: updateProductBrand,
            formatInput: (id, input) => ({
              id,
              input: {
                name: input.name,
              },
            }),
            formatOutput: output => output?.updateProductBrand,
          },
          actions: [
            {
              label: "Delete",
              gql: removeProductBrand,
              formatInput: id => ({ id }),
              formatOutput: output => output?.removeProductBrand,
              shouldAppearInTable: true,
              IconComponent: DeclineIcon,
              className: classes.declineAction,
            },
          ],
        }}
        showDateRangeFilter={false}
        showExportButton={false}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

export default ProductBrandsPage;
