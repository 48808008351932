import gql from "graphql-tag";

export const stripeAddPaymentMethodMutation = gql`
  mutation stripeAddPaymentMethod($input: StripeAddPaymentMethodInput!) {
    stripeAddPaymentMethod(input: $input)
  }
`;

export const stripeRemovePaymentMethodMutation = gql`
  mutation stripeRemovePaymentMethod($input: StripeRemovePaymentMethodInput!) {
    stripeRemovePaymentMethod(input: $input)
  }
`;
