import React, {useState} from "react";

import styled from "styled-components";

import DateRangeSelector from "../../../package/src/DateRangeSelector/DateRangeSelector";

import {
  Typography,
  CardActions
} from "@material-ui/core";

import Button from "@reactioncommerce/catalyst/Button";
import Field from "@reactioncommerce/components/Field/v1";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Checkbox from "@reactioncommerce/components/Checkbox/v1";

const CustomCardActions = styled(CardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const PaddedField = styled(Field)`
  margin-bottom: 30px !important;
`;

export default function({ title, type, initial, onSubmit }) {

  const [dateRange, setDateRange] = useState({
    startDate: initial.startDate ? new Date(initial.startDate) : null,
    endDate: initial.endDate ? new Date(initial.endDate) : null
  })

  const [overrideValue, setOverrideValue] = useState(
    initial.changePercentage || initial.newValue ?
    new Number(type === "percentage" ? initial.changePercentage : initial.newValue).toString() :
    ""
  )

  const [enabled, setEnabled] = useState(!initial.disabled)

  const [notes, setNotes] = useState(initial.notes)

  const allFieldsFilled = overrideValue?.trim().length >= 1

  return (
    <div>
      <Typography variant="h2">{title}</Typography>
      <PaddedField
        name="enabled" >
        <Checkbox
          label={"Enable/Disable Override*"}
          value={enabled}
          onChange={value => {
            setEnabled(value)
          }}
        />
      </PaddedField>
      <PaddedField
        label={type === "percentage" ? "Change Percentage*" : "New Value*"}
      >
        <TextInput
          type="number"
          value={overrideValue}
          onChange={value => {
            setOverrideValue(value)
          }}
        />
      </PaddedField>
      <PaddedField label="Override Period*">
        <DateRangeSelector
          dateRange={dateRange}
          onChange={dateRange => {
            setDateRange(dateRange)
          }}
        />
      </PaddedField>
      <PaddedField label="Notes" >
        <TextInput
          type="text"
          shouldAllowLineBreaks
          value={notes}
          onChange={value => {
            setNotes(value)
          }}
        />
      </PaddedField>
      <CustomCardActions disableSpacing>
        <Button
          variant="contained"
          color="primary"
          disabled={!allFieldsFilled}
          onClick={() => {
            onSubmit({
              [type === "percentage" ? "changePercentage" : "newValue"]: parseInt(overrideValue),
              disabled: !enabled,
              startDate: dateRange?.startDate?.toISOString(),
              endDate: dateRange?.endDate?.toISOString(),
              notes
            })
          }}
        >
          Save Override
        </Button>
      </CustomCardActions>
    </div>
  );
}