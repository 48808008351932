import gql from "graphql-tag";

export const updateProductBrandsMutation = gql`
  mutation updateBrandsOfProduct($productId: ID!, $shopId: ID!, $productBrandIds: [ID]!) {
    updateBrandsOfProduct(productId: $productId, shopId: $shopId, productBrandIds: $productBrandIds) {
      _id
    }
  }
`;

export const updateProductCategoriesMutation = gql`
  mutation updateCategoriesOfProduct($productId: ID!, $shopId: ID!, $productCategoryIds: [ID]!) {
    updateCategoriesOfProduct(productId: $productId, shopId: $shopId, productCategoryIds: $productCategoryIds) {
      _id
    }
  }
`;

export const addProductBrandMutation = gql`
  mutation AddProductBrand($name: String!) {
    addProductBrand(name: $name) {
      _id
      name
    }
  }
`;

export const addProductCategoryMutation = gql`
  mutation AddProductCategory($name: String!) {
    addProductCategory(name: $name) {
      _id
      name
    }
  }
`;
