import { Select } from "@reactioncommerce/catalyst";
import { useApolloClient } from "@apollo/react-hooks";
import shoppersQuery from "../graphql/queries/shoppers";

/**
 * ShopperSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ShopperSelectorWithData({ shopIds, shouldShowEmail = false, value, onSelection }) {
  const apolloClient = useApolloClient();

  const fetchShoppers = async query => {
    const { data, error } = await apolloClient.query({
      query: shoppersQuery,
      variables: {
        shopIds,
        filter: query,
      },
      fetchPolicy: "network-only",
    });

    let options = [];
    if (!error && data) {
      options = data?.shoppers?.nodes?.map(shopper => {
        const appendEmail = shouldShowEmail ? ` - ${shopper?.primaryEmailAddress}` : "";

        return {
          value: shopper._id,
          label: `${shopper.name}${appendEmail}`,
        };
      });
    }

    return options;
  };

  return (
    <Select
      isMulti
      isAsync
      defaultOptions
      value={value}
      loadOptions={fetchShoppers}
      onSelection={onSelection}
      placeholder="Search shoppers"
    />
  );
}

export default ShopperSelectorWithData;
