import React, { Fragment } from "react";
import Helmet from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ShopLogoWithData from "../ShopLogoWithData";
import { constants } from "../../../constants.js";

/**
 * LandingPage
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function LandingPage() {
  const shopIds = null;

  return (
    <Fragment>
      <Helmet title={`${constants.APP_NAME} CMS`} />
      <Grid container direction="column" justify="center" alignItems="center" spacing={5}>
        <Grid item />
        <Grid item>
          <ShopLogoWithData shopIds={shopIds} size={100} />
        </Grid>

        <Grid item>
          <Typography align="center" variant="body1">
            {/* eslint-disable-next-line max-len */}
            Welcome to {constants.APP_NAME} CMS
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default LandingPage;
