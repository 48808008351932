import { useQuery } from "@apollo/react-hooks";
import groupsQuery from "../graphql/queries/groups";
import { useSnackbar } from "notistack";

/**
 * @summary Hook to get groups
 * @name useGroups
 * @param {String} shopId - the shop ID to get groups for
 * @return {Object} Permissions
 */
export default function useGroups(shopId) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, refetch } = useQuery(groupsQuery, {
    variables: { shopId },
  });

  return {
    isLoadingGroups: loading,
    refetchGroups: refetch,
    groups: (data && data.groups.nodes) || [],
  };
}
