import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import SelectAllAutocomplete from "../SelectAllAutocomplete";
import { MerchantSelectorContext } from "../context/MerchantSelectorContext";

const defaultLogo = "/reaction-logo-circular.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
}));

const useAutocompleteStyles = makeStyles(theme => ({}));

/**
 * MerchantSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function MerchantSelectorWithData({
  className,
  linkTo,
  size,
  viewer,
  selectedCompanyIds, // a merchant on the backend is actually a company
  onMerchantSelectionChange,
  isMultiSelect = true,
}) {
  const classes = useStyles();
  const classesAutocomplete = useAutocompleteStyles();
  const { localStorageKey } = useContext(MerchantSelectorContext);

  let adminUIShops = viewer?.adminUIShops || [];

  if (viewer?.type !== "godmode") {
    // Merchants cannot select other merchants
    return null;
  } else {
    // The godmode company should not be an option
    adminUIShops = adminUIShops.filter(shop => shop.company._id !== viewer.companyId);
  }

  useEffect(() => {
    // Infer the selected shop IDs from the selected company IDs
    const selectedShopIds = adminUIShops
      .filter(shop => selectedCompanyIds.includes(shop.company._id))
      .map(shop => shop._id);

    // Lift state up
    onMerchantSelectionChange({
      selectedCompanyIds,
      selectedShopIds,
    });
  }, [selectedCompanyIds]);

  const onChange = selectedItems => {
    const selectedItemValues = selectedItems.map(item => item.value);

    // Save changes in localStorage
    localStorage.setItem(localStorageKey, JSON.stringify(selectedItemValues));

    // Infer the selected shop IDs from the selected company IDs
    const selectedShopIds = adminUIShops
      .filter(shop => selectedItemValues.includes(shop.company._id))
      .map(shop => shop._id);

    // Lift state up
    onMerchantSelectionChange({
      selectedCompanyIds: selectedItemValues,
      selectedShopIds,
    });
  };

  const uniqueCompanyIds = [];
  const uniqueCompanies = [];

  for (let shop of adminUIShops) {
    if (!uniqueCompanyIds.includes(shop.company._id)) {
      // this is a new company (not a dupe) --> add it to uniqueCompanies
      uniqueCompanies.push({
        _id: shop.company._id,
        name: shop.company.name,
      });
      uniqueCompanyIds.push(shop.company._id);
    }
  }

  const label = isMultiSelect ? "Select Merchant(s)" : "Select Merchant";
  const placeholder = isMultiSelect ? "Search for Merchants" : "Search for Merchant";

  return (
    <SelectAllAutocomplete
      items={uniqueCompanies.map(company => ({
        label: company.name,
        value: company._id,
        selected: selectedCompanyIds.includes(company._id),
      }))}
      limitTags={6}
      label={label}
      placeholder={placeholder}
      selectAllLabel="All Merchants"
      onChange={onChange}
      classes={classesAutocomplete}
      isMultiSelect={isMultiSelect}
    />
  );
}

MerchantSelectorWithData.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  linkTo: PropTypes.string,
  shopId: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MerchantSelectorWithData.defaultProps = {
  linkTo: "/",
  size: 60,
};

export default MerchantSelectorWithData;
