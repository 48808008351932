import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ordersQuery, totalShopsBalance } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import { constants } from "../../../constants";
import calculateAccountingValues from "../utils/calculateAccountingValues";
import numberWithCommas from "../utils/numberWithCommas";
import getKeyByValue from "../utils/getKeyByValue";
import { Card, CardHeader, CardContent, makeStyles } from "@material-ui/core";

const ORDER_STATUS = {
  NEW: "new",
  CREATED: "coreOrderWorkflow/created",
  PROCESSING: "coreOrderWorkflow/processing",
  COMPLETED: "coreOrderWorkflow/completed",
  CANCELED: "coreOrderWorkflow/canceled",
  PICKED: "coreOrderWorkflow/picked",
  PACKED: "coreOrderWorkflow/packed",
  LABELED: "coreOrderWorkflow/labeled",
  SHIPPED: "coreOrderWorkflow/shipped",
};

const useStyles = makeStyles(theme => ({
  ordersTableTotalsCotnainer: {
    marginBottom: theme.spacing(1),
  },
  shopSelector: {
    marginBottom: theme.spacing(1),
  },
  card: {
    overflow: "visible",
  },
  cardHeader: {
    padding: 0,
  },
  container: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
  },
  cardBody: {
    padding: 0,
  },
  bigTotal: {
    fontSize: "35px",
    textAlign: "center",
    paddingTop: "20px",
  },
  smallTotals: {
    display: "flex",
    flex: 1,
    paddingTop: "35px",
    textAlign: "center",
    justifyContent: "space-between",
  },
  smallTotal: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  smallTotalLabel: {
    fontSize: "15px",
  },
  smallTotalDescription: {
    color: "gray",
  },
}));

const formatTotal = totalString => {
  if (isNaN(totalString)) return "-";

  let total = parseFloat(totalString);

  return total >= 0 ? `$${numberWithCommas(total)}` : `- $${numberWithCommas(Math.abs(total))}`;
};
const Orders = ({ dateRange, shopIds, companyIds, viewer }) => {
  const classes = useStyles();

  const [selectedShopIds, setSelectedShopIds] = useState(shopIds);

  useEffect(() => {
    try {
      setSelectedShopIds(shopIds);
    } catch (error) {}
  }, [shopIds]);

  const { data, isLoading, refetch } = useQuery(totalShopsBalance, {
    variables: {
      shopIds: selectedShopIds,
      fromDate: dateRange?.startDate?.toISOString(),
      toDate: dateRange?.endDate?.toISOString(),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (refetch) refetch();
  }, [selectedShopIds]);

  const viewerType = viewer.type;

  let displayFields = [
    { type: "string", name: "_id", label: "Order ID", isSortable: true },
    { type: "string", name: "status", label: "Status", isSortable: true },
    { type: "dateTime", name: "createdAt", label: "Date", isSortable: true },
    { type: "string", name: "customerId", label: "Shopper ID", isSortable: "accountId" },
    { type: "string", name: "customerName", label: "Shopper Name", isSortable: "accountId" },
    { type: "string", name: "companyId", label: "Merchant ID", isSortable: true },
    { type: "string", name: "companyName", label: "Merchant Name", isSortable: "companyId" },
    { type: "string", name: "shopId", label: "Shop ID", isSortable: true },
    { type: "string", name: "shopName", label: "Shop Name", isSortable: "shopId" },
    { type: "string", name: "shopCategory", label: "Shop Category", isSortable: "shopCategoryId" },
    { type: "string", name: "paymentMethod", label: "Payment", isSortable: true },
    { type: "string", name: "total", label: "Total" },
    { type: "string", name: "fulfillmentTotal", label: "Shipping" },
    { type: "string", name: "amount", label: "Items (Total - Shipping)" },
    { type: "string", name: "commissionPercentage", label: "Commission (%)", isSortable: true },
    { type: "string", name: "godmodeCut", label: `${constants.APP_NAME} Cut` },
    { type: "string", name: "merchantCut", label: "Merchant Cut" },
    { type: "string", name: "pay", label: "Pay" },
    { type: "string", name: "collect", label: "Collect" },
  ];

  const shouldfetchOrders = Array.isArray(selectedShopIds) && selectedShopIds.length > 0;

  // `viewer.adminUIShops` contains ALL shops. We should filter it to include
  // only the ones that belong to company with IDs in `companyIds` because
  // these are the user's selections
  let adminUIShops = viewer?.adminUIShops || [];
  adminUIShops = adminUIShops.filter(shop => companyIds.includes(shop.company._id));

  return (
    <>
      <div className={classes.shopSelector}>
        <SelectAllAutocomplete
          items={adminUIShops.map(shop => ({
            label: shop.name,
            value: shop._id,
            selected: selectedShopIds.includes(shop._id),
            groupId: shop.company.name,
          }))}
          limitTags={6}
          label="Select Shop(s)"
          placeholder="Search for Shops"
          selectAllLabel="All Shops"
          onChange={items => setSelectedShopIds(items.map(item => item.value))}
        />
      </div>

      <div className={classes.ordersTableTotalsCotnainer}>
        {isLoading || !data ? (
          "Loading..."
        ) : (
          <Card classes={classes.card}>
            <CardHeader classes={{ root: classes.cardHeader }} title="Totals" />
            <CardContent className={classes.cardBody}>
              <div className={classes.container}>
                <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Total</div>
                    <div className={classes.smallTotalDescription}>
                      The sum of all completed orders (in below table)
                    </div>
                  </div>
                  <div>{formatTotal(data.totalShopsBalance.transaction)}</div>
                </div>
                <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Shipping</div>
                    <div className={classes.smallTotalDescription}>
                      The sum of all shipping costs
                    </div>
                  </div>
                  <div>{formatTotal(data.totalShopsBalance.shipping)}</div>
                </div>
                <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Items</div>
                    <div className={classes.smallTotalDescription}>
                      The sum of all purchased items (Total - Shipping)
                    </div>
                  </div>
                  <div>{formatTotal(data.totalShopsBalance.total)}</div>
                </div>
                <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Merchant Cut</div>
                    <div className={classes.smallTotalDescription}>
                      The sum that goes to the merchant
                    </div>
                  </div>
                  <div>{formatTotal(data.totalShopsBalance.merchantCut)}</div>
                </div>
                <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>{constants.APP_NAME} Cut</div>
                    <div className={classes.smallTotalDescription}>
                      The sum that goes to {constants.APP_NAME}
                    </div>
                  </div>
                  <div>{formatTotal(data.totalShopsBalance.godmodeCut)}</div>
                </div>
                {/* <div className={classes.smallTotals}>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Collect</div>
                    <div>{formatTotal(data.totalShopsBalance.collect)}</div>
                  </div>
                  <div className={classes.smallTotal}>
                    <div className={classes.smallTotalLabel}>Pay</div>
                    <div>{formatTotal(data.totalShopsBalance.pay)}</div>
                  </div>
                </div> */}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      <CRUDer
        showDateRangeFilter={false}
        crud={{ state: "display", id: null }}
        handlers={{
          goToUpdatePage: (id, row) => {
            const win = window.open(`/orders/${row.shop._id}/${id}`, "_blank");
            win.focus();
          },
        }}
        strings={{
          displayer: {
            filterPlaceholder: "Filter Orders by ID or Shopper name",
          },
        }}
        fields={{
          display: displayFields,
        }}
        apis={{
          readAll: shouldfetchOrders
            ? {
                gql: ordersQuery,
                formatInput: searchField => {
                  const gte = dateRange?.startDate?.toISOString() || "";
                  const lte = dateRange?.endDate?.toISOString() || "";

                  const createdAt = {};

                  if (gte !== "") createdAt.gte = gte;
                  if (lte !== "") createdAt.lte = lte;

                  const filters = Object.keys(createdAt)?.length > 0 ? { createdAt } : {};

                  return {
                    shopIds: selectedShopIds || [],
                    filters: {
                      ...filters,
                      searchField,
                    },
                  };
                },
                formatOutput: output => {
                  return {
                    nodes: output.orders.nodes.map(node => {
                      const total = node.summary.total.amount;
                      const fulfillment = node.summary?.fulfillmentTotal?.amount;
                      const amount = total - fulfillment; // godmode doesn't take cut on delivery charge

                      const commissionPercentage = node.commissionPercentage;
                      const isOrderComplete = node.status === ORDER_STATUS.COMPLETED;
                      const currency =
                        node.summary.itemTotal.currency.code === "USD"
                          ? "$"
                          : `${node.summary.itemTotal.currency.code} `;
                      const paymentMethod =
                        node?.payments[0].method?.name === "cash" ? "Offline" : "Online";

                      let accounting = {
                        godmodeCut: 0,
                        merchantCut: 0,
                        pay: 0,
                        collect: 0,
                      };

                      if (isOrderComplete)
                        accounting = calculateAccountingValues(
                          amount,
                          commissionPercentage,
                          paymentMethod,
                          viewerType
                        );

                      return {
                        ...node,
                        _id: node.referenceId,
                        status: getKeyByValue(ORDER_STATUS, node?.status),
                        paymentMethod,
                        total: `${currency}${numberWithCommas(total)}`,
                        customerId: node?.shopper?._id,
                        customerName: node?.fulfillmentGroups[0]?.data?.shippingAddress?.fullName,
                        companyId: node.shop.company._id,
                        companyName: node.shop.company.name,
                        shopId: node.shop._id,
                        shopName: node.shop.name,
                        shopCategory: node.shop.getShopCategory.name,
                        amount: `${currency}${numberWithCommas(amount)}`,
                        fulfillmentTotal: `${currency}${numberWithCommas(fulfillment)}`,
                        godmodeCut: `${currency}${numberWithCommas(accounting.godmodeCut)}`,
                        merchantCut: `${currency}${numberWithCommas(accounting.merchantCut)}`,
                        pay: `${currency}${numberWithCommas(accounting.pay)}`,
                        collect: `${currency}${numberWithCommas(accounting.collect)}`,
                      };
                    }),
                    totalCount: output.orders.totalCount,
                  };
                },
              }
            : undefined,
          actions: [],
        }}
      ></CRUDer>
    </>
  );
};

export default Orders;
