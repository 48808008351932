import React, { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Button from "@reactioncommerce/catalyst/Button";
import Select from "@reactioncommerce/catalyst/Select";
import TextField from "@reactioncommerce/catalyst/TextField";
import Helmet from "react-helmet";
import Typography from "@material-ui/core/Typography";
import { Divider, makeStyles } from "@material-ui/core";
import MUICardActions from "@material-ui/core/CardActions";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const useStyles = makeStyles(theme => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    justifyContent: "flex-end",
  },
}));

const BrandsAndCategoriesMultiSelect = props => {
  const classes = useStyles();
  const { shopId, values, onCreate, onSuccess } = props;
  const [nameInput, setNameInput] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [createMutation] = useMutation(onCreate.api.gql, {
    onCompleted() {
      enqueueSnackbar("Successfully added!", { variant: "success" });
    },
    onError(error) {
      let errorMessage = "Something went wrong, please try again!";

      if (error.message.includes("Already exists")) {
        errorMessage = error.message;
      }

      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });
  const [updateMutation] = useMutation(values.apis.update.gql, {
    onError() {
      enqueueSnackbar("Something went wrong, please try again!", {
        variant: "error",
      });
    },
  });

  let {
    loading: loadingSelectedValues,
    data: selectedValues,
    refetch: fetchSelectedValues,
  } = useQuery(values.apis.selected.gql, {
    variables: values.apis.selected.formatInput(shopId),
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });
  selectedValues = values.apis.selected.formatOutput(selectedValues);

  let { loading: loadingAllValues, data: allValues, refetch: fetchAllValues } = useQuery(
    values.apis.all.gql,
    {
      variables: values.apis.all.formatInput(),
      fetchPolicy: "network-only",
      onError(error) {
        console.error(error);
      },
    }
  );
  allValues = values.apis.all.formatOutput(allValues);

  const create = async () => {
    await createMutation({
      variables: onCreate.api.formatInput(nameInput),
    });

    await fetchAllValues();
    setNameInput("");
  };

  const update = async selectedValues => {
    await updateMutation({
      variables: values.apis.update.formatInput(
        shopId,
        selectedValues ? selectedValues.map(sv => sv.value) : []
      ),
    });

    await fetchSelectedValues();
    if (onSuccess && typeof onSuccess === "function") onSuccess();
  };

  const onKeyDown = event => {
    if (event.keyCode == 13) {
      create();
    }
  };

  return (
    <div>
      <Typography variant="h2">
        <Helmet title={values.header} />
        {values.header}
      </Typography>
      <div></div>

      <Divider />
      <br />
      <br />

      <div>{values.description}</div>

      <br />

      <Select
        isMulti
        options={
          allValues
            ? allValues.map(value => ({
                value: value._id,
                label: value.name,
              }))
            : []
        }
        onSelection={values => update(values)}
        placeholder={"Search"}
        value={
          selectedValues
            ? selectedValues.map(value => ({
                value: value._id,
                label: value.name,
              }))
            : []
        }
      />

      <br />
      <br />

      <div>{onCreate.description}</div>

      <br />

      <CardActions className={classes.cardActions}>
        <TextField
          onChange={event => setNameInput(event.currentTarget.value)}
          placeholder="Add"
          onKeyDown={onKeyDown}
          value={nameInput}
        />

        <Button variant="outlined" color="primary" onClick={create}>
          {onCreate.button}
        </Button>
      </CardActions>
    </div>
  );
};

export default BrandsAndCategoriesMultiSelect;
