import React, { useRef } from "react";
import { useSnackbar } from "notistack";

import { useApolloClient } from "@apollo/react-hooks";

import Displayer from "../../../package/src/CRUDer/Displayer";

import { tagListingQuery, getTag } from "../lib/queries";
import { updateTagMutation, removeTagMutation } from "../lib/mutations";

import Toolbar from "../../../package/src/Toolbar";

const TagTable = props => {
  const { selectedShopIds: shopIds, history } = props;

  const { enqueueSnackbar } = useSnackbar();

  const apolloClient = useApolloClient();

  const readAllDataRef = useRef(null);

  const getTagData = async (shopId, tagId) => {
    const tagData = await apolloClient.mutate({
      mutation: getTag,
      variables: {
        shopId,
        slugOrId: tagId,
      },
    });

    return tagData.data.tag;
  };

  const goToUpdatePage = (id, row) => history.push(`/tags/edit/${row.shop._id}/${id}`);
  const gotToCreatePage = () => {
    if (shopIds.length > 1) {
      return enqueueSnackbar("You need to select only one shop to create a tag.", {
        variant: "warning",
      });
    }

    history.push(`/tags/create/${shopIds[0]}`);
  };

  const fields = [
    {
      type: "string",
      name: "name",
      label: "Name",
      isSortable: true,
    },
    {
      type: "string",
      name: "slug",
      label: "Slug",
      isSortable: true,
    },
    {
      type: "string",
      name: "shopName",
      label: "Shop",
      isSortable: true,
    },
    {
      type: "boolean",
      name: "isVisible",
      label: "Visible",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
  ];

  const strings = {
    createButton: "Create Tag",
    noData: "No Tags",
    filterPlaceholder: "Filter Tags by name or slug",
  };

  const actions = [
    {
      label: "Make Hidden",
      gql: updateTagMutation,
      formatInput: async id => {
        const tagShopId = readAllDataRef.current?.find(row => row._id === id).shop._id;

        const tag = await getTagData(tagShopId, id);

        return {
          input: {
            id: tag._id,
            shopId: tag.shop._id,
            isVisible: false,
            name: tag.name,
            displayTitle: tag.displayTitle,
          },
        };
      },
      formatOutput: output => output.updateTag,
    },
    {
      label: "Make Visible",
      gql: updateTagMutation,
      formatInput: async id => {
        const tagShopId = readAllDataRef.current?.find(row => row._id === id).shop._id;

        const tag = await getTagData(tagShopId, id);

        return {
          input: {
            id: tag._id,
            shopId: tag.shop._id,
            isVisible: true,
            name: tag.name,
            displayTitle: tag.displayTitle,
          },
        };
      },
      formatOutput: output => output.updateTag,
    },
    {
      label: "Delete",
      gql: removeTagMutation,
      formatInput: id => {
        const tagShopId = readAllDataRef.current?.find(row => row._id === id).shop._id;

        return { input: { id, shopId: tagShopId } };
      },
      formatOutput: output => output.removeTag,
    },
  ];

  const readAll = {
    gql: tagListingQuery,
    formatInput: () => ({ shopIds }),
    formatOutput: output => ({
      nodes: output?.tags?.nodes.map(node => ({
        ...node,
        shopName: node.shop.name,
      })),
      totalCount: output?.tags?.totalCount,
    }),
  };

  return (
    <>
      <Toolbar title="Tags" />
      <Displayer
        ref={readAllDataRef}
        fields={fields}
        readAll={readAll}
        actions={actions}
        goToCreatePage={gotToCreatePage}
        goToUpdatePage={goToUpdatePage}
        strings={strings}
      />
    </>
  );
};

export default TagTable;
