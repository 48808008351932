import React from "react";
import PropTypes from "prop-types";

// GraphQL
import { getShopCategoriesQuery, getShopCategory } from "../graphql/queries";
import {
  removeShopCategoryMutation,
  updateShopCategoryMutation,
  addShopCategoryMutation,
} from "../graphql/mutations";

// Components
import CRUDer from "../../../package/src/CRUDer";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";

import Toolbar from "../../../package/src/Toolbar";

// Component
const ShopCategoriesPage = props => {
  // Props
  const { match, history } = props;

  const shopCategoryId = match?.params?.shopCategoryId || null;
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  const fields = [
    {
      type: "string",
      name: "name",
      label: "Name",
      placeholder: "e.g. Men's Wear",
      isSortable: true,
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "This is a description",
      optional: true,
      isSortable: true,
    },
  ];

  const displayFields = [
    ...fields,
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Shop Categories" />

      <CRUDer
        crud={{ state: crudState, id: shopCategoryId }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`/shop-categories/edit/${id}`);
          },
          goToCreatePage: () => {
            history.push(`/shop-categories/create`);
          },
          goToDisplayPage: () => {
            history.push(`/shop-categories`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Shop Category",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Shop Categories by name",
          },
          creator: {
            formTitle: "Create Shop Category",
            buttonText: "Add Shop Category",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Shop Category",
            buttonText: "Save Shop Category",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: fields,
          create: fields,
          display: displayFields,
        }}
        apis={{
          create: {
            gql: addShopCategoryMutation,
            formatInput: input => ({ input }),
            formatOutput: output => output?.addShopCategory,
          },
          readOne: {
            gql: getShopCategory,
            formatInput: id => ({ shopCategoryId: id }),
            formatOutput: output => output?.getShopCategory,
          },
          readAll: {
            gql: getShopCategoriesQuery,
            formatInput: () => {},
            formatOutput: output => output?.getShopCategories,
          },
          update: {
            gql: updateShopCategoryMutation,
            formatInput: (id, input) => ({
              id,
              input: {
                name: input.name,
                description: input.description,
              },
            }),
            formatOutput: output => output?.updateShopCategory,
          },
          actions: [
            {
              label: "Delete",
              gql: removeShopCategoryMutation,
              formatInput: id => ({ id }),
              formatOutput: output => output?.removeShopCategory,
            },
          ],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

ShopCategoriesPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      shopCategoryId: PropTypes.string,
    }),
  }),
  shopCategoryId: PropTypes.string,
};

export default ShopCategoriesPage;
