import { all, one, packages } from "../graphql/queries";
import { addCompany, update, remove } from "../graphql/mutations";
import CRUDer from "../../../package/src/CRUDer";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import { useQuery } from "@apollo/react-hooks";
import OverrideMerchantSubscription from "../components/OverrideMerchantSubscription";
import ShopViewer from "../components/ShopViewer";
import Toolbar from "../../../package/src/Toolbar";

const MerchantsPage = ({ match, history, viewer }) => {
  const companyId = match?.params?.companyId || null;
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  const { data } = useQuery(packages, {
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  let packageValues = data?.subscriptionPackages?.nodes.map(subscriptionPackage => ({
    id: subscriptionPackage._id,
    label: subscriptionPackage.title,
    value: subscriptionPackage._id,
  }));

  const fields = [
    { type: "string", name: "_id", label: "ID", isSortable: true },
    { type: "string", name: "name", label: "Name", placeholder: "e.g. Standard", isSortable: true },
    {
      type: "email",
      name: "email",
      label: "Email",
      placeholder: "merchant@company.com",
      isSortable: true,
    },
    { type: "string", name: "phoneNumber", label: "Phone Number", isSortable: true },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "This is a description",
      optional: true,
      isSortable: true,
    },
    {
      type: "dropdown",
      name: "subscriptionPackageId",
      label: "Subscription Package",
      dropdownValues: packageValues,
    },
    { type: "string", name: "packageTitle", label: "Package", isSortable: "subscriptionPackageId" },
    { type: "dateTime", name: "createdAt", label: "Created On", isSortable: true },
  ];

  const updateFields = ["description", "email", "subscriptionPackageId", "phoneNumber"];
  const createFields = ["name", "email", "description", "subscriptionPackageId", "phoneNumber"];
  const displayFields = [
    "_id",
    "name",
    "email",
    "description",
    "packageTitle",
    "phoneNumber",
    "createdAt",
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Merchants" />
      <CRUDer
        crud={{ state: crudState, id: companyId }}
        handlers={{
          goToUpdatePage: id => history.push(`/merchants/edit/${id}`),
          goToCreatePage: () => history.push(`/merchants/create`),
          goToDisplayPage: () => history.push(`/merchants`),
        }}
        strings={{
          displayer: {
            createButton: "Create Merchant",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Merchant by ID, name, email or phone",
          },
          creator: {
            formTitle: "Create Merchant",
            buttonText: "Add Merchant",
            mainTabLabel: "Add",
          },
          updater: {
            formTitle: "Edit Merchant",
            buttonText: "Save Merchant",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: fields.filter(({ name }) => updateFields.includes(name)),
          create: fields.filter(({ name }) => createFields.includes(name)),
          display: fields.filter(({ name }) => displayFields.includes(name)),
        }}
        apis={{
          create: {
            gql: addCompany,
            formatInput: input => ({ input }),
            formatOutput: output => output?.addCompany,
          },
          readOne: {
            gql: one,
            formatInput: id => ({ id }),
            formatOutput: output => ({
              ...output?.company,
              subscriptionPackageId: output?.company?.subscriptionPackage?._id,
            }),
          },
          readAll: {
            gql: all,
            formatInput: () => {},
            formatOutput: output => ({
              ...output?.companies,
              nodes: output?.companies.nodes.map(company => ({
                ...company,
                packageTitle: company?.subscriptionPackage?.title ?? "Default",
              })),
            }),
          },
          update: {
            gql: update,
            formatInput: (id, input) => ({
              id,
              input: {
                description: input.description,
                subscriptionPackageId: input.subscriptionPackageId,
                phoneNumber: input.phoneNumber,
                email: input.email,
              },
            }),
            formatOutput: output => output?.updateCompany,
          },
          actions: [
            {
              label: "Delete",
              gql: remove,
              formatInput: id => ({ id }),
              formatOutput: output => output?.removeCompany,
            },
          ],
        }}
        additionalTabs={[
          {
            label: "Overrides",
            Component: <OverrideMerchantSubscription companyId={companyId} />,
          },
          {
            label: "Shops",
            Component: <ShopViewer companyId={companyId} viewer={viewer} history={history} />,
          },
        ]}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

export default MerchantsPage;
