import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import OrderPrint from "../components/OrderPrint";
import orderByReferenceId from "../graphql/queries/orderByReferenceId";
import getShopQuery from "../graphql/queries/getShop";

/**
 * @name OrderContainer
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
function OrderContainer(props) {
  const {
    match: { params }
  } = props;

  return (
    <Query errorPolicy="all" query={getShopQuery} variables={{ id: params.shopId }}>
      {({ data: shop, loading: isLoading }) => {
        if (isLoading) return null;

        return (
          <Query
            errorPolicy="all"
            query={orderByReferenceId}
            variables={{
              id: params._id,
              language: shop.shop.language,
              shopId: shop.shop._id,
              token: null
            }}
          >
            {({ data: orderData, loading: isLoading }) => {
              if (isLoading) return null;
              const { order } = orderData || {};

              if (!order) {
                return <div>Order not found</div>;
              }

              return <OrderPrint order={order} />;
            }}
          </Query>
        );
      }}
    </Query>
  );
}

OrderContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      shopId: PropTypes.string,
      _id: PropTypes.string
    })
  })
};

export default compose(withRouter)(OrderContainer);
