import gql from "graphql-tag";
import { Shopper, ShopperInvitation } from "./fragments";

export const remove = gql`
  mutation removeShopper($id: ID!) {
    removeShopper(id: $id) {
      ${Shopper}
    }
  }
`;

export const update = gql`
  mutation updateShopper($id: ID!, $input: UpdateShopperBaseInfoInput!) {
    updateShopper(id: $id, basicInfoInput: $input) {
      ${Shopper}
    }
  }
`;

export const updateBillingAddress = gql`
  mutation updateShopper($id: ID!, $input: AddressInput!) {
    updateShopper(id: $id, billingAddressInput: $input) {
      ${Shopper}
    }
  }
`;

export const updateShippingAddress = gql`
  mutation updateShopper($id: ID!, $input: AddressInput!) {
    updateShopper(id: $id, shippingAddressInput: $input) {
      ${Shopper}
    }
  }
`;

export const add = gql`
  mutation addShopper($input: AddShopperInput!) {
    addShopper(input: $input) {
      ${ShopperInvitation}
    }
  }
`;

export const resetPassword = gql`
  mutation resetPassword($id: ID!) {
    resetShopperPassword(input: {
      id: $id
    })
  }
`;