export const Company = `
  _id
  name
  description
  subscriptionPackage {
    _id
    title
  }
  phoneNumber
  email
  createdAt
  updatedAt
`;

export const SubscriptionPackage = `
_id
title
description
subscriptionType {
    _id
    name
}
baseRate
allowsPremiumShops
isVisibleToMerchants
durationInDays
additionalRatePerPremiumShop
additionalRatePerStandardShop
commissionPercentageForPremiumShops
commissionPercentageForStandardShops
createdAt
updatedAt
`;

export const Shop = `
  _id
  name
  description
  slug
  createdAt
  updatedAt
  shopLogoUrls {
    primaryShopLogoUrl
  }
  getShopCategory {
    _id,
    name
  }
  emails {
    address
  }
  phoneNumbers
  facebook
  instagram
  twitter
  eCommerceEnabled
  linkToExternalWebsite
  company {
    _id
    name
  }
  subscription {
    preOverride {
      rate
      commissionPercentage
    }
    postOverride {
      rate
      commissionPercentage
    }
    billingPeriodInDays
  }
`;
