import React from "react";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ProductMediaGallery from "../components/ProductMediaGallery";
import useProduct from "../hooks/useProduct";
import Typography from "@material-ui/core/Typography";

/**
 * Variant media form block component
 * @returns {Node} React component
 */
function VariantMediaForm({ refreshToolbar }) {
  const { t } = useTranslation();
  const { currentVariant, product, shopIds } = useProduct();

  if (!currentVariant) {
    return null;
  }

  const { _id: variantId, media } = currentVariant;

  return (
    <Card>
      <CardHeader title={t("admin.productAdmin.mediaGallery")} />
      <CardContent>
        <Typography variant="body2" component="p">
          You can control the order of the below images from the product page.
        </Typography>
        <ProductMediaGallery
          refreshToolbar={refreshToolbar}
          editable={true}
          media={media}
          productId={product._id}
          variantId={variantId}
          shopIds={shopIds}
        />
      </CardContent>
    </Card>
  );
}

export default VariantMediaForm;
