import CardMembershipIcon from "@material-ui/icons/CardMembership";
import Subscriptions from "./pages/Subscriptions";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/subscriptions",
    MainComponent: Subscriptions,
    IconComponent: CardMembershipIcon,
    navigationItemLabel: "Subscription",
    showShopSelector: false,
    merchantOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });
}
