import React from "react";

import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import DeclineIcon from "mdi-material-ui/CloseCircleOutline";

import CRUDer from "../../../package/src/CRUDer";

import couponTriggersQuery from "../graphql/queries/couponTriggers.js";
import removeCouponTriggerMutation from "../graphql/mutations/removeCouponTrigger.js";

const CALCULATION_METHODS = {
  credit: "flat",
  discount: "percentage",
};

const useStyles = makeStyles(theme => ({
  declineAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
}));

function CouponTriggersTable({ shopId, history }) {
  const classes = useStyles();

  const fields = [
    {
      type: "string",
      name: "code",
      label: "Code",
      isSortable: true,
    },
    {
      type: "string",
      name: "discount",
      label: "Discount Amount",
      isSortable: true,
    },
    {
      type: "string",
      name: "calculationMethod",
      label: "Calculation Method",
      isSortable: true,
    },
    {
      type: "boolean",
      name: "consumed",
      label: "Triggered",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Created On",
      isSortable: true,
    },
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CRUDer
        crud={{ state: "display" }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`coupon-triggers/edit/${shopId}/${id}`);
          },
          goToCreatePage: () => {
            history.push(`coupon-triggers/create/${shopId}`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Create Coupon Trigger",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Coupon Triggers by code",
          },
        }}
        fields={{
          display: fields,
        }}
        apis={{
          readAll: {
            gql: couponTriggersQuery,
            formatInput: filter => ({ shopIds: [shopId], filter }),
            formatOutput: output => ({
              nodes: output.couponTriggers.nodes.map(node => {
                const startDate = node?.conditions?.order?.startDate;
                const endDate = node?.conditions?.order?.endDate;

                const arrayToStringReducer = (accumulator, currentValue, index) =>
                  index === 0 ? `${currentValue}` : `${accumulator}, ${currentValue}`;

                return {
                  ...node,
                  calculationMethod: CALCULATION_METHODS[node.calculation.method],
                  validityPeriod: {
                    startDate: startDate && new Date(startDate),
                    endDate: endDate && new Date(endDate),
                  },
                  redemptionLimit: node?.conditions?.redemptionLimit || "",
                  accountLimit: node?.conditions?.accountLimit || "",
                  audience: (node?.conditions?.audience || []).reduce(arrayToStringReducer, ""),
                  products: (node?.conditions?.products || []).reduce(arrayToStringReducer, ""),
                  consumed: node?.consumed ? "Yes" : "No",
                  discountId: node?.discountId || "N/A",
                };
              }),
              totalCount: output.couponTriggers.totalCount,
            }),
          },
          actions: [
            {
              label: "Delete",
              gql: removeCouponTriggerMutation,
              formatInput: id => ({ id }),
              formatOutput: output => output?.couponTrigger,
              shouldAppearInTable: true,
              IconComponent: DeclineIcon,
              className: classes.declineAction,
            },
          ],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
}

export default CouponTriggersTable;
