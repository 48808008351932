import RateForm from "../components/RateForm";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../constants.js";

const { createRatePageTitle } = constants;

export default function CreateRatePage(props) {
  const shopId = props?.match?.params?.shopId;

  return (
    <>
      <Toolbar title={createRatePageTitle} onCancel={() => props.history.push("/sale")} />
      <RateForm {...props} shopId={shopId} />
    </>
  );
}
