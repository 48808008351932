import gql from "graphql-tag";

export const productCategories = gql`
  query productCategories(
    $productCategoryIds: [ID]!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: ProductCategorySortByField
    $filter: String
    $genericFilters: GenericFilterInput
  ) {
    productCategories(
      productCategoryIds: $productCategoryIds
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      filter: $filter
      genericFilters: $genericFilters
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        name
        createdAt
        updatedAt
        createdBy {
          name
          primaryEmailAddress
        }
      }
      totalCount
    }
  }
`;

export const productCategory = gql`
  query productCategory($id: ID!) {
    productCategory(id: $id) {
      name
    }
  }
`;
