import React, { useState } from "react";

import { Card, CardHeader, CardContent, Box, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import CreateIcon from "@material-ui/icons/Create";

import Chip from "@reactioncommerce/catalyst/Chip";

import BrandsAndCategoriesSelectorDialog from "../components/BrandsAndCategoriesSelector/BrandsAndCategoriesSelectorDialog.js";

import useProduct from "../hooks/useProduct";

function ProductBrandsAndCategoriesForm(props) {
  const { type, refreshToolbar } = props;

  const { product, refetchProduct } = useProduct();
  const [isDialogOpen, setDialogOpen] = useState(false);

  let content;

  if (product && Array.isArray(product[type.selector])) {
    content = product[type.selector].map(element => (
      <Box key={element._id}>
        <Chip
          variant="outlined"
          color="primary"
          label={element.name}
          style={{ marginRight: "4px" }}
        />
      </Box>
    ));
  }

  const formCaptionMessage = () => {
    return type.value === "categories"
      ? "* When you publish this product, the above categories will be automatically added to the Shop's product categories if they don't already exist"
      : "* When you publish this product, the above brands will be automatically added to the Shop's product brands if they don't already exist";
  };

  return (
    <Card style={{ overflow: "visible" }}>
      <CardHeader
        action={
          <IconButton onClick={() => setDialogOpen(true)} title="Add Brands">
            <CreateIcon />
          </IconButton>
        }
        title={type.title}
      />

      <CardContent>
        <Box display="flex">{content}</Box>
      </CardContent>

      {product && (
        <BrandsAndCategoriesSelectorDialog
          title={type.title}
          type={type.value}
          isOpen={isDialogOpen}
          onSuccess={() => {
            refetchProduct();
            refreshToolbar();
          }}
          onClose={() => setDialogOpen(false)}
          productIds={[product._id]}
          shopIds={[product.shop._id]}
        />
      )}

      <Typography variant="caption">{formCaptionMessage()}</Typography>
    </Card>
  );
}

export default ProductBrandsAndCategoriesForm;
