import React, { Fragment, useState, useMemo, useCallback } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataTable, { useDataTable } from "@reactioncommerce/catalyst/DataTable";
import Button from "@reactioncommerce/catalyst/Button";
import moment from "moment";
import { useSnackbar } from "../../../package/src";
import { useDropzone } from "react-dropzone";
import { Card, CardHeader, CardContent, Grid, makeStyles } from "@material-ui/core";
import productsQuery from "../graphql/queries/products";
import bulkExportQuery from "../graphql/queries/bulkExport";
import publishProductsToCatalog from "../graphql/mutations/publishProductsToCatalog";
import archiveProducts from "../graphql/mutations/archiveProducts";
import updateProduct from "../graphql/mutations/updateProduct";
import cloneProducts from "../graphql/mutations/cloneProducts";
import createProductMutation from "../graphql/mutations/createProduct";
import bulkImportMutation from "../graphql/mutations/bulkImport";
import getPDPUrl from "../utils/getPDPUrl";
import StatusIconCell from "./DataTable/StatusIconCell";
import MediaCell from "./DataTable/MediaCell";
import PublishedStatusCell from "./DataTable/PublishedStatusCell";
import BulkImportCard from "./BulkImportCard";
import TagSelector from "./TagSelector";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import useCurrentShop from "../../../package/src/hooks/useCurrentShop";
import Toolbar from "../../../package/src/Toolbar";

const useStyles = makeStyles(theme => ({
  card: {
    overflow: "visible",
  },
  cardHeader: {
    paddingBottom: 0,
  },
  selectedProducts: {
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CSV_FILE_TYPES = [
  "text/csv",
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
];

/**
 * @summary Main products view
 * @name ProductsTable
 * @returns {React.Component} A React component
 */
function ProductsTable({ selectedShopIds: shopIds, viewer }) {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [createProduct, { error: createProductError }] = useMutation(createProductMutation);
  const [bulkImport] = useMutation(bulkImportMutation);
  const { t } = useTranslation();

  const { isLoadingShop, shop: currentShop } = useCurrentShop(shopIds[0]);

  // React-Table state
  const [isLoading, setIsLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  // Filter by file state
  const [files, setFiles] = useState([]);
  const [isFilterByFileVisible, setFilterByFileVisible] = useState(false);
  const [isFiltered, setFiltered] = useState(false);

  // Tag selector state
  const [isTagSelectorVisible, setTagSelectorVisibility] = useState(false);

  // Create and memoize the column data
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "original.media[0].URLs.thumbnail",
        cellProps: () => ({
          style: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }),
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Cell: ({ row }) => <MediaCell row={row} />,
      },
      {
        Header: t("admin.productTable.header.product"),
        accessor: "title",
      },
      {
        Header: "Shop",
        accessor: "shop.name",
      },
      {
        Header: t("admin.productTable.header.id"),
        accessor: row => row._id,
        id: "_id",
      },
      {
        Header: t("admin.productTable.header.price"),
        accessor: "price.range",
      },
      {
        Header: t("admin.productTable.header.published"),
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Cell: ({ row }) => <PublishedStatusCell row={row} />,
      },
      {
        Header: t("admin.productTable.header.visible"),
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Cell: ({ row }) => <StatusIconCell row={row} />,
        id: "isVisible",
      },
      {
        Header: "Created On",
        accessor: row => moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
        id: "createdAt",
      },
    ],
    [t]
  );

  const onFetchData = useCallback(
    async ({ globalFilter, manualFilters, pageIndex, pageSize }) => {
      // Wait for shop id to be available before fetching products.
      if (shopIds.length === 0) {
        return;
      }

      setIsLoading(true);

      const filterByProductIds = {};
      if (manualFilters.length) {
        filterByProductIds.productIds = manualFilters[0].value;
        // Reset uploaded files
        setFiles([]);
      }

      const { data } = await apolloClient.query({
        query: productsQuery,
        variables: {
          shopIds,
          ...filterByProductIds,
          query: globalFilter,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          genericFilters: {
            dateRange: {
              gte: dateRange.startDate,
              lte: dateRange.endDate,
              field: "createdAt",
            },
          },
        },
        fetchPolicy: "network-only",
      });

      // Update the state with the fetched data as an array of objects and the calculated page count
      setTableData(data.productsWithFilters.nodes);
      setPageCount(Math.ceil(data.productsWithFilters.totalCount / pageSize));

      setIsLoading(false);
    },
    [apolloClient, shopIds, dateRange]
  );

  const persistantSnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      persist: true,
      action: key => (
        <Fragment>
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}
            style={{ color: "white" }}
          >
            X
          </Button>
        </Fragment>
      ),
    });
  };

  // Row click callback
  const onRowClick = useCallback(
    async ({ row }) => {
      const href = getPDPUrl(row.original.shop._id, row.original._id);

      history.push(href);
    },
    [history, shopIds]
  );

  const onRowSelect = useCallback(async ({ selectedRows: rows }) => {
    setSelectedRows(rows || []);
  }, []);

  const labels = useMemo(
    () => ({
      globalFilterPlaceholder: t("admin.productTable.filters.placeholder"),
    }),
    [t]
  );

  const dataTableProps = useDataTable({
    columns,
    data: tableData,
    labels,
    pageCount,
    onFetchData,
    onRowClick,
    onRowSelect,
    getRowId: row => row._id,
  });

  const { refetch, setManualFilters } = dataTableProps;

  const onDrop = accepted => {
    if (accepted.length === 0) return;
    setFiles(accepted);
  };

  const handleCreateProduct = async () => {
    if (shopIds.length !== 1) {
      enqueueSnackbar("Please select exactly one Shop", { variant: "error" });

      return;
    }

    const { data } = await createProduct({
      variables: {
        input: {
          shopId: shopIds[0],
          product: {
            isVisible: true,
          },
        },
      },
    });

    if (data) {
      const {
        createProduct: { product },
      } = data;
      history.push(`/products/${shopIds[0]}/${product._id}`);
    }

    if (createProductError) {
      enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
    }
  };

  const handleBulkExport = async () => {
    if (shopIds.length !== 1) {
      enqueueSnackbar("Please select exactly one Shop", { variant: "error" });

      return;
    }

    setIsImportLoading(true);

    let data = null;

    try {
      ({ data } = await apolloClient.query({
        query: bulkExportQuery,
        variables: {
          shopId: shopIds[0],
        },
        fetchPolicy: "network-only",
      }));

      setIsImportLoading(false);

      const { products } = data.bulkProductExport;

      data = products || [];
    } catch (error) {
      persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
      console.error(error);

      setIsImportLoading(false);
    }

    const NEWLINE = "\n";
    const noData = "";
    const arrayDelimeter = ";";
    let csvString = `ID,Parent ID,Is Visible,Image IDs (separated by semi-column. Priority left to right),Product Title,Product Subtitle,Product Short Description,Product Long Discription,Product Purchase Notes,Product Is Published,Facebook Share Message,Twitter Share Message,Direct Share Message,Product Tags (separated by semi-column),Product Brands (separated by semi-column),Product Categories (separated by semi-column),Related Product IDs (separated by semi-column),Variant Attribute Label,Variant Short Title or Hex,Variant Full Title,Variant SKU,Variant Origin Country,Variant Width,Variant Length,Variant Height,Variant Weight,Variant Price,Variant Original Price,Variant Items In Stock,Variant Can Be Backordered${NEWLINE}`;

    data.forEach(
      ({
        externalId: productExternalId,
        isVisible,
        title,
        subtitle,
        shortDescription,
        longDescription,
        purchaseNotes,
        isPublished,
        facebookShareMsg,
        twitterShareMsg,
        directShareMsg,
        tags,
        brands,
        categories,
        relatedProducts,
        imageIds: productImageIds,
        variants,
      }) => {
        csvString += `${productExternalId},${noData},${isVisible},${productImageIds.join(
          arrayDelimeter
        )},${title},${subtitle},${shortDescription},${longDescription},${purchaseNotes},${isPublished},${facebookShareMsg},${twitterShareMsg},${directShareMsg},${tags.join(
          arrayDelimeter
        )},${brands.join(arrayDelimeter)},${categories.join(arrayDelimeter)},${relatedProducts.join(
          arrayDelimeter
        )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${NEWLINE}`;

        variants.forEach(
          ({
            externalId: variantExternalId,
            isVisible,
            attributeLabel,
            attributeShortTitle,
            fullTitle,
            sku,
            originCountry,
            width,
            length,
            height,
            weight,
            price,
            originalPrice,
            inventoryInStock,
            canBackorder,
            imageIds: variantImageIds,
          }) => {
            csvString += `${variantExternalId},${productExternalId},${isVisible},${variantImageIds.join(
              arrayDelimeter
            )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${attributeLabel},${attributeShortTitle},${fullTitle},${sku},${originCountry},${width},${length},${height},${weight},${price},${originalPrice},${inventoryInStock},${canBackorder}${NEWLINE}`;
          }
        );
      }
    );

    const download = (content, mimeType, filename) => {
      let a = document.createElement("a");
      const blob = new Blob([content], { type: mimeType });
      const url = URL.createObjectURL(blob);
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      a.click();
    };

    download(
      csvString,
      "text/csv",
      `Products - ${currentShop.name} - ${moment().format("MM/DD/YYYY hh:mm:ss")}.csv`
    );
  };

  const handleBulkImport = () => {
    if (shopIds.length !== 1) {
      enqueueSnackbar("Please select exactly one Shop", { variant: "error" });

      return;
    }

    setFilterByFileVisible(true);
  };

  // Filter by file event handlers
  const { getRootProps, getInputProps } = useDropzone({
    accept: CSV_FILE_TYPES,
    disableClick: true,
    disablePreview: true,
    multiple: false,
    onDrop,
  });

  const importFiles = newFiles => {
    newFiles.map(file => {
      const output = [];
      const reader = new FileReader();

      reader.readAsText(file);
      reader.onloadend = () => {
        const parse = require("csv-parse");

        parse(reader.result, {
          trim: true,
          skip_empty_lines: true,
          delimiter: [","],
          columns: [
            "id",
            "parentId",
            "isVisible",
            "imageIds",
            "productTitle",
            "productSubTitle",
            "productShortDescription",
            "productLongDescription",
            "productPurchaseNotes",
            "productIsPublished",
            "facebookShareMessage",
            "twitterShareMessage",
            "directShareMessage",
            "productTags",
            "productBrands",
            "productCategories",
            "relatedProducts",
            "variantAttributeLabel",
            "variantShortTitleOrHex",
            "variantFullTitle",
            "variantSku",
            "variantOriginCountry",
            "variantWidth",
            "variantLength",
            "variantHeight",
            "variantWeight",
            "variantPrice",
            "variantOriginalPrice",
            "variantIventoryInStock",
            "variantCanBackorder",
          ],
        })
          .on("readable", function() {
            let record;

            while ((record = this.read())) {
              output.push(record);
            }
          })
          .on("error", error => {
            alert(error.message);
          })
          .on("end", async () => {
            let data = [];
            let errors = [];

            const parseToBoolean = value => {
              switch (value.toLowerCase()) {
                case "1":
                case "true":
                  return true;

                default:
                  return false;
              }
            };

            const splitIntoArray = value => {
              let createdArray = value && value !== "" ? value.split(";") : [];
              return createdArray.map(e => e.trim());
            };

            // Check the first line (table header)
            const titleObject = {
              id: "ID",
              parentId: "Parent ID",
              isVisible: "Is Visible",
              imageIds: "Image IDs (separated by semi-column. Priority left to right)",
              productTitle: "Product Title",
              productSubTitle: "Product Subtitle",
              productShortDescription: "Product Short Description",
              productLongDescription: "Product Long Discription",
              productPurchaseNotes: "Product Purchase Notes",
              productIsPublished: "Product Is Published",
              facebookShareMessage: "Facebook Share Message",
              twitterShareMessage: "Twitter Share Message",
              directShareMessage: "Direct Share Message",
              productTags: "Product Tags (separated by semi-column)",
              productBrands: "Product Brands (separated by semi-column)",
              productCategories: "Product Categories (separated by semi-column)",
              relatedProducts: "Related Product IDs (separated by semi-column)",
              variantAttributeLabel: "Variant Attribute Label",
              variantShortTitleOrHex: "Variant Short Title or Hex",
              variantFullTitle: "Variant Full Title",
              variantSku: "Variant SKU",
              variantOriginCountry: "Variant Origin Country",
              variantWidth: "Variant Width",
              variantLength: "Variant Length",
              variantHeight: "Variant Height",
              variantWeight: "Variant Weight",
              variantPrice: "Variant Price",
              variantOriginalPrice: "Variant Original Price",
              variantIventoryInStock: "Variant Items In Stock",
              variantCanBackorder: "Variant Can Be Backordered",
            };

            for (const property in titleObject) {
              const value = titleObject[property];

              if (output[0][property] !== value) {
                return alert(
                  `Wrong column names. Please keep them exactly as they appear in the template. Error at column: ${value}`
                );
              }
            }

            // At first we only want to go through all prodcuts, that way we create them all and there is no way we
            // reach a variant that belongs to a product that hasn't been inserted into the data object yet
            output.forEach((row, rowIndex) => {
              // We skip the first line (table header)
              if (rowIndex === 0) {
                return;
              }

              // We only want rows without a parentId - products
              if (row.parentId) {
                return;
              }

              data.push({
                externalId: row.id.trim(),
                isVisible: parseToBoolean(row.isVisible.trim()),
                title: row.productTitle.trim(),
                subtitle: row.productSubTitle.trim(),
                shortDescription: row.productShortDescription.trim(),
                longDescription: row.productLongDescription.trim(),
                purchaseNotes: row.productPurchaseNotes.trim(),
                isPublished: parseToBoolean(row.productIsPublished.trim()),
                facebookShareMsg: row.facebookShareMessage.trim(),
                twitterShareMsg: row.twitterShareMessage.trim(),
                directShareMsg: row.directShareMessage.trim(),
                tags: splitIntoArray(row.productTags.trim()),
                brands: splitIntoArray(row.productBrands.trim()),
                categories: splitIntoArray(row.productCategories.trim()),
                relatedProducts: splitIntoArray(row.relatedProducts.trim()),
                imageIds: splitIntoArray(row.imageIds.trim()),
                variants: [],
              });
            });

            // Then we go through all the rows again, this time inserting only the variants in the variants array of the
            // product they belong to
            output.forEach((row, rowIndex) => {
              // We skip the first line (table header)
              if (rowIndex === 0) {
                return;
              }

              // We only want rows with a parentId - variants
              if (!row.parentId) {
                return;
              }

              // Let's find the product index that this variant belongs to
              const productIndex = data.findIndex(product => product.externalId === row.parentId);

              if (productIndex === -1) {
                return errors.push(
                  `Could not find parentId ${row.parentId} of variant ${row.id}. Aborting import!`
                );
              }

              data[productIndex].variants.push({
                externalId: row.id.trim(),
                isVisible: parseToBoolean(row.isVisible.trim()),
                attributeLabel: row.variantAttributeLabel.trim(),
                attributeShortTitle: row.variantShortTitleOrHex.trim(),
                fullTitle: row.variantFullTitle.trim(),
                sku: row.variantSku.trim(),
                originCountry: row.variantOriginCountry.trim(),
                width: parseFloat(row.variantWidth.trim()),
                length: parseFloat(row.variantLength.trim()),
                height: parseFloat(row.variantHeight.trim()),
                weight: parseFloat(row.variantWeight.trim()),
                price: parseFloat(row.variantPrice.trim()),
                originalPrice: parseFloat(row.variantOriginalPrice.trim()),
                inventoryInStock: parseInt(row.variantIventoryInStock.trim()),
                canBackorder: parseToBoolean(row.variantCanBackorder.trim()),
                imageIds: splitIntoArray(row.imageIds.trim()),
              });
            });

            if (errors.length) {
              alert(errors[0]);

              return console.error(errors);
            }

            setIsImportLoading(true);

            try {
              const result = await bulkImport({
                variables: {
                  companyId: viewer.companyId,
                  shopId: shopIds[0],
                  products: data,
                },
              });

              setIsImportLoading(false);

              const { totalInputCount, totalMutatedCount } = result.data.bulkProductImport;

              persistantSnackbar(
                `Successfully imported ${totalMutatedCount} of ${totalInputCount} products`,
                "success"
              );

              refetch();
            } catch (error) {
              persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
              console.error(error);
              setIsImportLoading(false);
            }
          });
      };
      return;
    });
  };

  const handleDeleteUploadedFile = deletedFilename => {
    const newFiles = files.filter(file => file.name !== deletedFilename);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      setFiltered(false);
    } else if (isFiltered) {
      importFiles(newFiles);
    }
  };

  // Create options for the built-in ActionMenu in the DataTable
  const options = useMemo(
    () => [
      {
        label: t("admin.productTable.bulkActions.filterByFile"),
        onClick: () => {
          if (isTagSelectorVisible) setTagSelectorVisibility(false);
          setFilterByFileVisible(true);
        },
      },
      {
        label: t("admin.productTable.bulkActions.addRemoveTags"),
        isDisabled: selectedRows.length === 0,
        onClick: () => {
          if (isFilterByFileVisible) setFilterByFileVisible(false);
          setTagSelectorVisibility(true);
        },
      },
      {
        label: t("admin.productTable.bulkActions.publish"),
        confirmTitle: t("admin.productTable.bulkActions.publishTitle", {
          count: selectedRows.length,
        }),
        confirmMessage: t("admin.productTable.bulkActions.publishMessage", {
          count: selectedRows.length,
        }),
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const { data, error } = await apolloClient.mutate({
            mutation: publishProductsToCatalog,
            variables: {
              productIds: selectedRows,
            },
          });

          if (error && error.length) {
            enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
            return;
          }

          refetch();
          enqueueSnackbar(
            t("admin.productTable.bulkActions.published", {
              count: data.publishProductsToCatalog.length,
            })
          );
        },
      },
      {
        label: t("admin.productTable.bulkActions.makeVisible"),
        confirmTitle: t("admin.productTable.bulkActions.makeVisibleTitle", {
          count: selectedRows.length,
        }),
        confirmMessage: t("admin.productTable.bulkActions.makeVisibleMessage", {
          count: selectedRows.length,
        }),
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const errors = [];
          const successes = [];
          // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
          for (const productId of selectedRows) {
            // eslint-disable-next-line no-await-in-loop
            const { data, error } = await apolloClient.mutate({
              mutation: updateProduct,
              variables: {
                input: {
                  product: {
                    isVisible: true,
                  },
                  productId,
                  shopId: tableData.find(row => row._id === productId).shop._id,
                },
              },
            });

            if (error) errors.push(error);
            if (data) successes.push(data);
          }

          if (errors.length) {
            enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
            return;
          }

          refetch();
          enqueueSnackbar(
            t("admin.productTable.bulkActions.makeVisibleSuccess", {
              count: successes.length,
            })
          );
        },
      },
      {
        label: t("admin.productTable.bulkActions.makeHidden"),
        confirmTitle: t("admin.productTable.bulkActions.makeHiddenTitle", {
          count: selectedRows.length,
        }),
        confirmMessage: t("admin.productTable.bulkActions.makeHiddenMessage", {
          count: selectedRows.length,
        }),
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const errors = [];
          const successes = [];

          // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
          for (const productId of selectedRows) {
            // eslint-disable-next-line no-await-in-loop
            const { data, error } = await apolloClient.mutate({
              mutation: updateProduct,
              variables: {
                input: {
                  product: {
                    isVisible: false,
                  },
                  productId,
                  shopId: tableData.find(row => row._id === productId).shop._id,
                },
              },
            });

            if (error && error.length) errors.push(error);
            if (data) successes.push(data);
          }

          if (errors.length) {
            enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
            return;
          }

          refetch();
          enqueueSnackbar(
            t("admin.productTable.bulkActions.makeHiddenSuccess", {
              count: successes.length,
            })
          );
        },
      },
      {
        label: t("admin.productTable.bulkActions.duplicate"),
        confirmTitle: t("admin.productTable.bulkActions.duplicateTitle", {
          count: selectedRows.length,
        }),
        confirmMessage: t("admin.productTable.bulkActions.duplicateMessage", {
          count: selectedRows.length,
        }),
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const errors = [];
          const successes = [];

          // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
          for (const productId of selectedRows) {
            const { data, error } = await apolloClient.mutate({
              mutation: cloneProducts,
              variables: {
                input: {
                  productIds: [productId],
                  shopId: tableData.find(row => row._id === productId).shop._id,
                },
              },
            });

            if (error && error.length) errors.push(error);
            if (data) successes.push(data);
          }

          if (errors.length) {
            enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
            return;
          }

          refetch();
          enqueueSnackbar(
            t("admin.productTable.bulkActions.duplicateSuccess", {
              count: successes.length,
            })
          );
        },
      },
      {
        label: "Delete",
        confirmTitle: `Delete ${selectedRows.length} products?`,
        confirmMessage: "Deleting a product removes it from both admin and customer views.",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const errors = [];
          const successes = [];

          // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
          for (const productId of selectedRows) {
            const { data, error } = await apolloClient.mutate({
              mutation: archiveProducts,
              variables: {
                input: {
                  productIds: [productId],
                  shopId: tableData.find(row => row._id === productId).shop._id,
                },
              },
            });

            if (error && error.length) errors.push(error);
            if (data) successes.push(data);
          }

          if (errors.length) {
            enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
            return;
          }

          refetch();
          enqueueSnackbar(
            `${successes.length} product${successes.length > 1 ? "s" : ""} ${
              successes.length > 1 ? "have" : "has"
            } been deleted`
          );
        },
      },
    ],
    [
      apolloClient,
      enqueueSnackbar,
      isFilterByFileVisible,
      isTagSelectorVisible,
      refetch,
      selectedRows,
      shopIds,
      t,
    ]
  );

  const classes = useStyles();
  const selectedProducts = selectedRows.length ? `${selectedRows.length} selected` : "";
  const cardTitle = (
    <Fragment>
      {t("admin.products")}
      <span className={classes.selectedProducts}>{selectedProducts}</span>
    </Fragment>
  );

  return (
    <Grid container spacing={3}>
      <Toolbar title="Products" />
      <BulkImportCard
        isFilterByFileVisible={isFilterByFileVisible}
        files={files}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        importFiles={importFiles}
        handleDelete={handleDeleteUploadedFile}
        setFilterByFileVisible={setFilterByFileVisible}
        isImportLoading={isImportLoading}
      />

      <TagSelector
        isVisible={isTagSelectorVisible}
        setVisibility={setTagSelectorVisibility}
        selectedProductIds={selectedRows}
        shopIds={shopIds}
      />

      {!isTagSelectorVisible && !isFilterByFileVisible && (
        <Grid item sm={12}>
          <Button color="primary" variant="contained" onClick={handleCreateProduct}>
            Create Product
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleBulkImport}
            style={{ marginLeft: "10px" }}
          >
            <PublishIcon className={classes.leftIcon} />
            {"Bulk Import"}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleBulkExport}
            isWaiting={isImportLoading}
            style={{ marginLeft: "10px" }}
          >
            <GetAppIcon className={classes.leftIcon} />
            {"Bulk Export"}
          </Button>
        </Grid>
      )}
      <Grid item sm={12}>
        <Card className={classes.card}>
          <CardHeader classes={{ root: classes.cardHeader }} title={cardTitle} />
          <CardContent>
            {shopIds.length === 0 ? (
              <span>Please select some shops to see products.</span>
            ) : (
              <>
                <DateRangeSelector dateRange={dateRange} onChange={setDateRange} />
                <DataTable
                  {...dataTableProps}
                  actionMenuProps={{ options }}
                  isLoading={isLoading}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProductsTable;
