import React, { useState } from "react";

// Components
import Button from "@reactioncommerce/catalyst/Button";
import TextField from "@reactioncommerce/catalyst/TextField";
import MUICardActions from "@material-ui/core/CardActions";
import styled from "styled-components";
import PendingIcon from "mdi-material-ui/ClockOutline";

// GraphQL
import { briefShopQuery, pendingMerchantRequestQuery } from "../graphql/queries";
import { addMerchantRequestMutation, removeMerchantRequestMutation } from "../graphql/mutations";

// Libs
import { useMutation, useQuery } from "@apollo/react-hooks";

// Other
import { constants } from "../../../constants.js";

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const formatOutputMessage = start => {
  if (start) {
    return `Your request to start hosting eCommerce on ${constants.APP_NAME} for this Shop is under review...`;
  } else {
    return `Your request to stop hosting eCommerce on ${constants.APP_NAME} for this Shop is under review...`;
  }
};

const formatInputMessage = start => {
  if (start) {
    return `If you'd like to start hosting eCommerce on ${constants.APP_NAME} for this Shop, please place a request. We'll handle it as soon as possible.`;
  } else {
    return `If you'd like to stop hosting eCommerce on ${constants.APP_NAME} for this Shop, please place a request. We'll handle it as soon as possible.`;
  }
};

const ChangeECommerce = ({ shopId }) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const [addMerchantRequest] = useMutation(addMerchantRequestMutation, {
    ignoreResults: true,
  });
  const [removeMerchantRequest] = useMutation(removeMerchantRequestMutation, {
    ignoreResults: true,
  });
  const { loading: loadingShop, data: shopData } = useQuery(briefShopQuery, {
    variables: {
      id: shopId,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });
  const {
    loading: loadingMerchantRequest,
    data: merchantRequestData,
    refetch: refetchPendingMerchantRequest,
  } = useQuery(pendingMerchantRequestQuery, {
    variables: {
      shopId,
      type: constants.MERCHANT_REQUESTS.TYPE.E_COMMERCE_ENABLED,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
  });

  if (loading || loadingShop || loadingMerchantRequest) return "Loading...";

  const handleSubmit = async () => {
    setLoading(true);

    await addMerchantRequest({
      variables: {
        input: {
          type: constants.MERCHANT_REQUESTS.TYPE.E_COMMERCE_ENABLED,
          reason,
          changeFrom: shopData.shop.eCommerceEnabled ? "true" : "false",
          changeTo: shopData.shop.eCommerceEnabled ? "false" : "true",
          shopId,
        },
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingMerchantRequest();

    setLoading(false);
  };

  const handleCancel = async merchantRequestId => {
    setLoading(true);

    await removeMerchantRequest({
      variables: {
        id: merchantRequestId,
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingMerchantRequest();

    setLoading(false);
  };

  return merchantRequestData.pendingMerchantRequest ? (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PendingIcon style={{ fontSize: 50 }} />
        <div style={{ marginLeft: 10 }}>
          {formatOutputMessage(merchantRequestData.pendingMerchantRequest.changeTo === "true")}
        </div>
      </div>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCancel(merchantRequestData.pendingMerchantRequest._id)}
        >
          Cancel Request
        </Button>
      </CardActions>
    </div>
  ) : (
    <>
      <div>{formatInputMessage(!shopData.shop.eCommerceEnabled)}</div>
      <br />
      <TextField
        onChange={event => setReason(event.currentTarget.value)}
        placeholder="Provide reason. i.e. Why would you like to change eCommerce hosting?"
        multiline
        rows={7}
      />
      <br />
      <CardActions disableSpacing>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Place Request
        </Button>
      </CardActions>
    </>
  );
};

export default ChangeECommerce;
