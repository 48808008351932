import React from "react";

import Form from "../components/Form";

const Creator = props => {
  const { fields, create, goToDisplayPage, strings, additionalTabs } = props;

  return (
    <Form
      queries={{ create }}
      onCancel={goToDisplayPage}
      onCreate={goToDisplayPage}
      fields={fields}
      formTitle={strings.formTitle}
      buttonText={strings.buttonText}
      mainTabLabel={strings.mainTabLabel}
    />
  );
};

export default Creator;
