import SettingsDashboard from "../../package/src/SettingsDashboard";
import GdprSettingsPage from "./pages/GdprSettingsPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock, registerSetting }) {
  registerSetting({
    name: "gdpr",
    group: "settings",
    priority: 90,
    LayoutComponent: SettingsDashboard,
    MainComponent: GdprSettingsPage,
    navigationItemLabel: "GDPR",
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
