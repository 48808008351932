import React from "react";
import DiscountCodeForm from "../components/DiscountCodeForm";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../constants.js";

const { editCouponCodePageTitle } = constants;

export default function CreateDiscountPage(props) {
  const discountCodeId = props?.match?.params?.discountCodeId;
  const shopId = props?.match?.params?.shopId;

  return (
    <>
      <Toolbar title={editCouponCodePageTitle} onCancel={() => props.history.push("/discounts")} />
      <DiscountCodeForm {...props} shopId={shopId} discountCodeId={discountCodeId} />
    </>
  );
}
