import gql from "graphql-tag";

export const subscribe = gql`
  mutation subscribe($id: ID!) {
    subscribe(id: $id) {
        changeFrom {
            title
        }
        changeTo {
            title
        }
    }
  }
`;

