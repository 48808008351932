import React, { useEffect } from "react";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { CardHeader, CardContent, makeStyles } from "@material-ui/core";
import numberWithCommas from "../utils/numberWithCommas";

// GraphQL
import { totalBalancesQuery } from "../graphql/queries";

const useStyles = makeStyles(theme => ({
  card: {
    overflow: "visible",
  },
  cardHeader: {
    padding: 0,
  },
  cardBody: {
    padding: 0,
  },
  bigTotal: {
    fontSize: "35px",
    textAlign: "center",
    paddingTop: "20px",
  },
  smallTotals: {
    display: "flex",
    paddingTop: "35px",
    textAlign: "center",
    justifyContent: "space-between",
  },
  smallTotalLabel: {
    color: "gray",
  },
}));

const formatTotal = (totalString, viewerType) => {
  if (isNaN(totalString)) return "-";

  let total = parseFloat(totalString);
  if (viewerType !== "godmode") total *= -1;

  return total >= 0 ? `$${numberWithCommas(total)}` : `- $${numberWithCommas(Math.abs(total))}`;
};

const Totals = ({ dateRange, companyIds, viewerType, refresh }) => {
  const classes = useStyles();

  const { data, isLoading, refetch } = useQuery(totalBalancesQuery, {
    variables: {
      companyIds: companyIds || [],
      fromDate: dateRange?.startDate?.toISOString() || null,
      toDate: dateRange?.endDate?.toISOString() || null,
    },
    skip: !Array.isArray(companyIds) || !companyIds.length,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    try {
      refetch();
    } catch (error) {}
  }, [refresh]);

  return isLoading ? (
    "Loading..."
  ) : (
    <>
      <CardHeader classes={{ root: classes.cardHeader }} title="Totals" />
      <CardContent className={classes.cardBody}>
        <div className={classes.bigTotal}>
          {formatTotal(data?.totalBalances?.total, viewerType)}
        </div>
        <div className={classes.smallTotals}>
          <div>
            <div className={classes.smallTotalLabel}>Orders</div>
            <div>{formatTotal(data?.totalBalances?.orders, viewerType)}</div>
          </div>
          <div>
            <div className={classes.smallTotalLabel}>Subscriptions</div>
            <div>{formatTotal(data?.totalBalances?.subscriptions, viewerType)}</div>
          </div>
          <div>
            <div className={classes.smallTotalLabel}>Transactions</div>
            <div>{formatTotal(data?.totalBalances?.transactions, viewerType)}</div>
          </div>
        </div>
      </CardContent>
    </>
  );
};

export default Totals;
