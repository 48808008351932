import gql from "graphql-tag";
import { SubscriptionPackage } from "./fragments";

export const remove = gql`
  mutation removeSubscriptionPackage($id: ID!) {
    removeSubscriptionPackage(id: $id) {
      ${SubscriptionPackage}
    }
  }
`;

export const update = gql`
  mutation updateSubscriptionPackage($id: ID!, $input: UpdateSubscriptionPackageInput!) {
    updateSubscriptionPackage(id: $id, input: $input) {
      ${SubscriptionPackage}
    }
  }
`;

export const add = gql`
  mutation addSubscriptionPackage($input: AddSubscriptionPackageInput!) {
    addSubscriptionPackage(input: $input) {
      ${SubscriptionPackage}
    }
  }
`;

export const setSubscriptionOverrides = gql`
  mutation setSubscriptionOverrides(
    $id: ID!,
    $baseRateOverride: OverrideByPercentageInput,
    $additionalRatePerStandardShopOverride: OverrideByPercentageInput,
    $additionalRatePerPremiumShopOverride: OverrideByPercentageInput
  ) {
    setSubscriptionOverrides(
      id: $id,
      baseRateOverride: $baseRateOverride,
      additionalRatePerStandardShopOverride: $additionalRatePerStandardShopOverride,
      additionalRatePerPremiumShopOverride: $additionalRatePerPremiumShopOverride
    ) {
      baseRate {
        changePercentage
        disabled
      }
      additionalRatePerStandardShop {
        changePercentage
        disabled
      }
      additionalRatePerPremiumShop {
        changePercentage
        disabled
      }
    }
  }
`;