import React, { useState } from "react";
import styled from "styled-components";
import Field from "@reactioncommerce/components/Field/v1";
import { useSnackbar } from "notistack";
import { Button, TextField } from "@reactioncommerce/catalyst";
import { Typography, CardActions, Card, CardContent } from "@material-ui/core";
import { Auth } from "aws-amplify";

const PaddedField = styled(Field)`
  margin-bottom: 30px !important;
`;

const CustomCardActions = styled(CardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

export default function() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const formValid =
    oldPassword &&
    newPassword &&
    confirmNewPassword &&
    oldPassword.trim().length > 0 &&
    newPassword.trim().length > 0 &&
    newPassword.trim() === confirmNewPassword.trim();

  async function changePassword() {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      enqueueSnackbar("Successfully changed password.", { variant: "success" });
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: "error" });
    }

    setLoading(false);
  }

  return (
    <Card>
      <CardContent>
        <PaddedField>
          <Typography variant="h3">Change Password</Typography>
        </PaddedField>

        <PaddedField>
          <TextField
            label="Old Password"
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
            type="password"
          />
        </PaddedField>

        <PaddedField>
          <TextField
            label="New Password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            type="password"
          />
        </PaddedField>

        <PaddedField>
          <TextField
            label="Confirm New Password"
            value={confirmNewPassword}
            onChange={e => setConfirmNewPassword(e.target.value)}
            type="password"
          />
        </PaddedField>

        <CustomCardActions disableSpacing>
          <Button
            disabled={!formValid}
            isWaiting={loading}
            onClick={changePassword}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </CustomCardActions>
      </CardContent>
    </Card>
  );
}
