import React, { Fragment, useState } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { one, all, shopperInvitations } from "../graphql/queries";
import { add, update, remove, resetPassword } from "../graphql/mutations";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import CRUDer from "../../../package/src/CRUDer";
import BillingAddressEditor from "../additional-pages/BillingAddressEditor";
import ShippingAddressEditor from "../additional-pages/ShippingAddressEditor";
import { ThemeProvider as MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import PurchasesPage from "./PurchasesPage";
import { useSnackbar } from "notistack";

import Toolbar from "../../../package/src/Toolbar";

const useStyles = makeStyles(theme => ({
  resetAction: {
    color: theme.palette.colors.black40,
    width: "100%", // centers it in the cell
  },
}));

const ShoppersCruder = props => {
  const classes = useStyles();
  const { match, history, selectedShopIds: shopIds, crudState } = props;
  const Id = match?.params?.Id || null;
  const { enqueueSnackbar } = useSnackbar();

  const allFields = [
    {
      type: "string",
      name: "_id",
      label: "ID",
      isSortable: true,
    },
    {
      type: "string",
      name: "firstName",
      label: "First Name",
    },
    {
      type: "string",
      name: "lastName",
      label: "Last Name",
    },
    {
      type: "string",
      name: "name",
      label: "Full Name",
      isSortable: true,
    },
    {
      type: "string",
      name: "email",
      label: "Email",
      isSortable: true,
    },
    {
      type: "string",
      name: "phoneNumber",
      label: "Phone Number",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Registered On",
      isSortable: true,
    },
  ];

  const displayFields = ["_id", "name", "email", "phoneNumber", "createdAt"];
  const updateFields = ["firstName", "lastName", "email", "phoneNumber"];
  const createFields = ["firstName", "lastName", "email", "phoneNumber"];

  function formatPackageBulkDisplay(structure) {
    return {
      ...structure,
      nodes: structure.nodes.map(shopper => ({
        ...shopper,
        email: shopper.emailRecords[0].address,
      })),
    };
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CRUDer
        crud={{ state: crudState, id: Id }}
        handlers={{
          goToUpdatePage: id => {
            history.push(`/shoppers/edit/${id}`);
          },
          goToCreatePage: () => {
            history.push(`/shoppers/create`);
          },
          goToDisplayPage: () => {
            history.push(`/shoppers`);
          },
        }}
        strings={{
          displayer: {
            createButton: "Invite Shopper",
            deleteMessage: "Are you sure you want to delete?",
            filterPlaceholder: "Filter Shoppers",
          },
          creator: {
            formTitle: "Invite Shopper",
            buttonText: "Invite Shopper",
            mainTabLabel: "Invite",
          },
          updater: {
            formTitle: "Edit Shopper",
            buttonText: "Save Shopper",
            mainTabLabel: "Edit",
          },
        }}
        fields={{
          update: allFields.filter(({ name }) => updateFields.includes(name)),
          create: allFields.filter(({ name }) => createFields.includes(name)),
          display: allFields.filter(({ name }) => displayFields.includes(name)),
        }}
        apis={{
          create: {
            gql: add,
            formatInput: ({ firstName, lastName, email, phoneNumber }) => ({
              input: {
                firstName,
                lastName,
                phoneNumber,
                emails: [
                  {
                    address: email,
                    provides: "email",
                    verified: false,
                  },
                ],
              },
            }),
            formatOutput: output => output?.addShopper,
          },
          readOne: {
            gql: one,
            formatInput: id => ({ id }),
            formatOutput: output => ({
              ...output.shoppers.nodes[0],
              email: output.shoppers.nodes[0].emailRecords[0].address,
            }),
          },
          readAll: {
            gql: all,
            formatInput: () => ({ shopIds }),
            formatOutput: output => formatPackageBulkDisplay(output?.shoppers),
          },
          update: {
            gql: update,
            formatInput: (id, input) => ({
              id,
              input: {
                firstName: input.firstName,
                lastName: input.lastName,
                emails: [
                  {
                    address: input.email,
                  },
                ],
                phoneNumber: input.phoneNumber,
              },
            }),
            formatOutput: output => output?.update,
          },
          actions: [
            {
              label: "Delete",
              gql: remove,
              shouldAppearInTable: false,
              formatInput: id => ({ id }),
              formatOutput: output => output?.remove,
            },
            {
              label: "Reset Password",
              gql: resetPassword,
              shouldAppearInTable: true,
              IconComponent: RotateLeftIcon,
              className: classes.resetAction,
              formatInput: id => ({ id }),
              formatOutput: output => output?.remove,
              onSuccess: () =>
                enqueueSnackbar("Successfully reset password", { variant: "success" }),
            },
          ],
        }}
        additionalTabs={[
          {
            label: "Billing Address",
            Component: <BillingAddressEditor shopperId={Id} />,
          },
          {
            label: "Shipping Address",
            Component: <ShippingAddressEditor shopperId={Id} />,
          },
          {
            label: "View Purchases",
            Component: <PurchasesPage shopperId={Id} />,
          },
        ]}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

const InvitationsViewer = props => {
  const { match, selectedShopIds: shopIds } = props;

  const Id = match?.params?.Id || null;

  const allFields = [
    {
      type: "string",
      name: "firstName",
      label: "First Name",
      isSortable: true,
    },
    {
      type: "string",
      name: "lastName",
      label: "Last Name",
      isSortable: true,
    },
    {
      type: "string",
      name: "email",
      label: "Email",
      isSortable: true,
    },
    {
      type: "string",
      name: "phoneNumber",
      label: "Phone Number",
      isSortable: true,
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Invited On",
      isSortable: true,
    },
  ];

  const displayFields = ["firstName", "lastName", "email", "phoneNumber", "createdAt"];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CRUDer
        crud={{ state: "display", id: Id }}
        handlers={{}}
        fields={{
          display: allFields.filter(({ name }) => displayFields.includes(name)),
        }}
        apis={{
          readAll: {
            gql: shopperInvitations,
            formatInput: () => ({}),
            formatOutput: output => output.shopperInvitations,
          },
          actions: [],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

const ShoppersPage = props => {
  const { history } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const pathname = history?.location?.pathname;

  let crudState = null;

  if (pathname.includes("edit")) {
    crudState = "update";
  } else if (pathname.includes("create")) {
    crudState = "create";
  } else {
    // get all
    crudState = "display";
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Shoppers" />
      <Fragment>
        {crudState === "display" && (
          <>
            <Box>
              <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}>
                <Tab key={0} label={"Shoppers"} />
                <Tab key={1} label={"Invitations"} />
              </Tabs>
              <Divider />
            </Box>
            <br />
          </>
        )}
        {currentTab === 0 && <ShoppersCruder {...{ ...props, crudState }} />}
        {currentTab === 1 && <InvitationsViewer {...{ ...props, crudState }} />}
      </Fragment>
    </MuiThemeProvider>
  );
};

export default ShoppersPage;
