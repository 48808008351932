import gql from "graphql-tag";
import { SubscriptionType } from "./fragments";

export const subscriptionTypes = gql`
query subscriptionTypes($first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: SubscriptionTypeSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput) {
  subscriptionTypes(first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ${SubscriptionType}
    }
    totalCount
  }
}
`;

export const subscriptionType = gql`
  query subscriptionType($id: ID!) {
    subscriptionType(id: $id) {
      name
      description
    }
  }
`;
