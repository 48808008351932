import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox, Card, makeStyles } from "@material-ui/core";
import { groupBy } from "lodash";
import Button from "@reactioncommerce/catalyst/Button";

const useStyles = makeStyles(theme => ({
  applyButton: {
    float: "right",
    marginRight: theme.spacing(2),
  },
}));

const orderItemsByGroupId = items => {
  const groupedItems = groupBy(items, item => {
    return item.groupId;
  });

  items = [];
  for (var key in groupedItems) {
    if (groupedItems.hasOwnProperty(key)) {
      items.push(...groupedItems[key]);
    }
  }

  return items;
};

/**
 * Returns an array of user's selections (even if `isMultiSelect` is false in
 * which case it returns an array of 1 element)
 * @param {*} param0
 */
const SelectAllAutocomplete = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
  classes,
  isMultiSelect = true,
}) => {
  const classesLocal = useStyles();

  // Contains all selected options
  const [selectedOptions, setSelectedOptions] = useState(items.filter(item => item.selected));

  useEffect(() => {
    setSelectedOptions(items.filter(item => item.selected));
  }, [items]);

  // Contains the options that were selected when the user first opened the dropdown
  const [selectedOptionsOnOpen, setSelectedOptionsOnOpen] = useState([]);

  // Whether or not the dropdown is open
  const [open, setOpen] = useState(false);

  // This is needed because for some reason `groupBy` prop of `<Autocomplete/>`
  // fails to do proper grouping without first sorting the items by group
  items = orderItemsByGroupId(items);

  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = selectedOptions => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => {
    setSelectedOptions([]);
  };
  const getOptionLabel = option => `${option.label}`;

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptionsLocal, reason) => {
    if (!Array.isArray(selectedOptionsLocal)) {
      // This means `isMultiSelect` is false, so the returned value is not
      // an array --> make it an array of 1 element so that nothing breaks
      selectedOptionsLocal = [selectedOptionsLocal];
    }

    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptionsLocal.find(option => option.value === "select-all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptionsLocal);

        // If the dropdown is open, the state will be lifted up when the
        // dropdown closes (see `onClose`). So only lift state up if dropdown
        // is closed OR if this is a single select
        if (!open || !isMultiSelect) {
          setOpen(false);
          return onChange(selectedOptionsLocal);
        }
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
      return onChange([]);
    }
  };

  const onOpen = (event, reason) => {
    setOpen(true);
    setSelectedOptionsOnOpen(selectedOptions);
  };

  const onClose = (event, reason) => {
    setOpen(false);

    // We only lift state up if we find a difference between the selections of
    // the user prior to opening the dropdown and afterwards
    const diff1 = selectedOptions.filter(e => !selectedOptionsOnOpen.includes(e));
    const diff2 = selectedOptionsOnOpen.filter(e => !selectedOptions.includes(e));

    // If any of the two difference arrays isn't empty, it means there's a difference
    // between selection before and after --> lift state up to force a render
    if (diff1.length !== 0 || diff2.length !== 0) return onChange(selectedOptions);
  };

  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      items.length > 0 && option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};

    // We don't show a checkbox if single select
    return (
      <>
        {isMultiSelect && (
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
            {...selectAllProps}
          />
        )}
        {getOptionLabel(option)}
      </>
    );
  };

  const inputRenderer = params => (
    <TextField {...params} label={label} placeholder={placeholder} variant="outlined" />
  );
  const filter = createFilterOptions();
  const value = isMultiSelect ? selectedOptions : selectedOptions[0];

  // This Component is needed to show the Apply button at the bottom of the options
  const PaperComponent = ({ children }) => (
    <Card>
      {children}
      <Button className={classesLocal.applyButton} variant="outlined" color="primary">
        Apply
      </Button>
    </Card>
  );

  return (
    <Autocomplete
      open={open}
      multiple={isMultiSelect}
      fullWidth={true}
      classes={classes}
      limitTags={limitTags}
      options={items}
      groupBy={items => items.groupId}
      value={value}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.value === value.value}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        // Only show the "select all" checkbox if we have 2 or more items
        if (items.length > 1 && isMultiSelect) {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
        } else {
          const filtered = filter(options, params);
          return [...filtered];
        }
      }}
      onChange={handleChange}
      onOpen={onOpen}
      onClose={onClose}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      PaperComponent={isMultiSelect ? PaperComponent : undefined}
    />
  );
};

SelectAllAutocomplete.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: value => value,
};

export default SelectAllAutocomplete;
