import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Helmet from "react-helmet";
import i18next from "i18next";
import UIContext from "../context/UIContext";
import useCurrentMultiShopIds from "../hooks/useCurrentMultiShopIds.js";
import useCurrentSingleShopIds from "../hooks/useCurrentSingleShopIds.js";
import useMediaQuery from "../hooks/useMediaQuery";
import ContentLayout from "../ContentLayout";
import ShopSelectorWithData from "../ShopSelectorWithData";

/**
 * Operator routes
 * @param {Object} props ComponentProps
 * @returns {Object} An object containing filtered routes
 */
function Routes({ DefaultLayoutComponent = ContentLayout, isExactMatch, routes, viewer }) {
  const multiShopIds = useCurrentMultiShopIds(viewer);
  const singleShopIds = useCurrentSingleShopIds(viewer);
  const [selectedMultiShopIds, setSelectedMultiShopIds] = useState(multiShopIds);
  const [selectedSingleShopIds, setSelectedSingleShopIds] = useState(singleShopIds);
  const isMobile = useMediaQuery("mobile");
  const uiContext = useContext(UIContext);

  const ShopSelector = ({ isMultiSelect = true }) => {
    return (
      <div style={{ marginBottom: "50px" }}>
        <ShopSelectorWithData
          shouldShowShopName
          size={32}
          viewer={viewer}
          selectedShopIds={isMultiSelect ? selectedMultiShopIds : selectedSingleShopIds}
          onShopSelectionChange={isMultiSelect ? setSelectedMultiShopIds : setSelectedSingleShopIds}
          isMultiSelect={isMultiSelect}
        />
      </div>
    );
  };

  const RouteBody = ({ routeProps, route }) => {
    const title = i18next.t(route.navigationItemLabel);

    let shopSelectorComp = null;

    if (route.showShopSelector) {
      // A route with multi shopSelector on top
      shopSelectorComp = <ShopSelector isMultiSelect={true} />;
    } else if (route.showSingleShopSelector) {
      // A route with single shopSelector on top
      shopSelectorComp = <ShopSelector isMultiSelect={false} />;
    }

    return (
      <>
        <Helmet title={title} />
        {shopSelectorComp}
        <route.MainComponent {...routeProps} />
      </>
    );
  };

  return (
    <Switch>
      {routes.map(route => (
        <Route
          exact={isExactMatch}
          key={route.path}
          path={route.path}
          render={routeProps => {
            // pass the following to ALL routes
            routeProps.viewer = viewer;

            if (route.showShopSelector) {
              routeProps.selectedShopIds = selectedMultiShopIds;
            } else if (route.showSingleShopSelector) {
              routeProps.selectedShopIds = selectedSingleShopIds;
            }

            if (route.LayoutComponent === null) {
              return <RouteBody routeProps={routeProps} route={route} />;
            }

            const LayoutComponent = route.LayoutComponent || DefaultLayoutComponent;

            // Note that `routeProps` should be propagated not only to <RouteBody>
            // but also to <LayoutComponent> since some routes will be a pure
            // <LayoutComponent> that contains its own navigation (e.g. <SettingsDashboard>).
            // If we don't do this, these routes will not have access to `viewer`
            return (
              <LayoutComponent
                isLeadingDrawerOpen={!isMobile}
                isTrailingDrawerOpen={uiContext.isDetailDrawerOpen && !isMobile}
                {...route.layoutComponentProps}
                {...routeProps}
              >
                <RouteBody routeProps={routeProps} route={route} />
              </LayoutComponent>
            );
          }}
          {...route.props}
        />
      ))}
    </Switch>
  );
}

Routes.propTypes = {
  DefaultLayoutComponent: PropTypes.element,
  isExactMatch: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      props: PropTypes.object,
    })
  ),
};

export default Routes;
