export const constants = {
  APP_NAME: "oBuy",
  MERCHANT_REQUESTS: {
    TYPE: {
      E_COMMERCE_ENABLED: "eCommerceEnabled",
      SHOP_CATEGORY: "shopCategory",
    },
    STATE: {
      APPROVED: "approved",
      DECLINED: "declined",
      PENDING: "pending",
    },
  },
  ACCOUNT_TYPES: {
    godmodeAdmin: "godmode-admin",
    godmodeAssistantAdmin: "godmode-assistant-admin",
    godmodeFinancialManager: "godmode-financial-manager",
    godmodeAccountManager: "godmode-account-manager",
    merchantAdmin: "merchant-admin",
    merchantAssistantAdmin: "merchant-assistant-admin",
    merchantFinancialManager: "merchant-financial-manager",
    merchantShopFinancialManager: "merchant-shop-financial-manager",
    merchantProductManager: "merchant-product-manager",
    merchantShopProductManager: "merchant-shop-product-manager",
    systemManager: "system-manager",
  },
};
