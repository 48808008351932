import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { one } from "../graphql/queries";
import { updateShippingAddress } from "../graphql/mutations";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Button from "@reactioncommerce/catalyst/Button";
import Field from "@reactioncommerce/components/Field/v1";
import { CardActions } from "@material-ui/core";

const PaddedField = styled(Field)`
  margin-bottom: 30px !important;
`;

const CustomCardActions = styled(CardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

export default function({ shopperId }) {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apolloClient.query({
        query: one,
        variables: {
          id: shopperId,
        },
        fetchPolicy: "network-only",
      });

      const shippingAddress = data.shoppers.nodes[0].shippingAddress;

      setFirstName(shippingAddress?.firstName);
      setLastName(shippingAddress?.lastName);
      setEmail(shippingAddress?.email);
      setPhoneNumber(shippingAddress?.phone);
      setStreetAddress1(shippingAddress?.address1);
      setStreetAddress2(shippingAddress?.address2);
      setPostalCode(shippingAddress?.postal);
      setCountry(shippingAddress?.country);
      setState(shippingAddress?.region);
      setCity(shippingAddress?.city);
    };

    fetchData();
  }, []);

  const save = async () => {
    setLoading(true);
    const { errors } = await apolloClient.mutate({
      mutation: updateShippingAddress,
      variables: {
        id: shopperId,
        input: {
          firstName,
          lastName,
          address1: streetAddress1,
          address2: streetAddress2,
          country,
          region: state,
          city,
          postal: postalCode,
          phone: phoneNumber,
          email,
          fullName: "",
        },
      },
    });
    setLoading(false);

    if (errors && errors.length) {
      return enqueueSnackbar(errors[0].message.replace("GraphQL error:", ""), {
        variant: "error",
      });
    }

    enqueueSnackbar("Update successful", { variant: "success" });
  };

  const isValid =
    firstName &&
    firstName.trim().length > 0 &&
    lastName &&
    lastName.trim().length > 0 &&
    email &&
    email.trim().length > 0 &&
    phoneNumber &&
    phoneNumber.trim().length > 0 &&
    country &&
    country.trim().length > 0 &&
    state &&
    state.trim().length > 0 &&
    city &&
    city.trim().length > 0 &&
    streetAddress1 &&
    streetAddress1.trim().length > 0 &&
    streetAddress2 &&
    streetAddress2.trim().length > 0 &&
    postalCode &&
    postalCode.trim().length > 0;

  return (
    <div>
      <PaddedField label="First Name*">
        <TextInput type="text" value={firstName} onChange={setFirstName} />
      </PaddedField>
      <PaddedField label="Last Name*">
        <TextInput type="text" value={lastName} onChange={setLastName} />
      </PaddedField>
      <PaddedField label="Email*">
        <TextInput type="email" value={email} onChange={setEmail} />
      </PaddedField>
      <PaddedField label="Phone Number*">
        <TextInput type="text" value={phoneNumber} onChange={setPhoneNumber} />
      </PaddedField>
      <PaddedField label="Country*">
        <TextInput type="text" value={country} onChange={setCountry} />
      </PaddedField>
      <PaddedField label="State*">
        <TextInput type="text" value={state} onChange={setState} />
      </PaddedField>
      <PaddedField label="City*">
        <TextInput type="text" value={city} onChange={setCity} />
      </PaddedField>
      <PaddedField label="Street Address 1*">
        <TextInput type="text" value={streetAddress1} onChange={setStreetAddress1} />
      </PaddedField>
      <PaddedField label="Street Address 2*">
        <TextInput type="text" value={streetAddress2} onChange={setStreetAddress2} />
      </PaddedField>
      <PaddedField label="Postal Code*">
        <TextInput type="text" value={postalCode} onChange={setPostalCode} />
      </PaddedField>
      <CustomCardActions disableSpacing>
        <Button
          isWaiting={loading}
          disabled={!isValid}
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </CustomCardActions>
    </div>
  );
}
