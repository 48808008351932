import gql from "graphql-tag";

export const settingsQuery = gql`
  query settings($settingIds: [ID], $shopIds: [ID]) {
    settings(settingIds: $settingIds, shopIds: $shopIds) {
      _id
      name
      description
      value
      shopId
    }
  }
`;

export const updateSettingsMutation = gql`
  mutation updateSettings($input: UpdateSettingsInput) {
    updateSettings(input: $input) {
      _id
      name
    }
  }
`;

export const createTransactionGQL = gql`
  mutation addTransactionBetweenMerchantAndGodmode(
    $input: AddTransactionBetweenMerchantAndGodmodeInput!
  ) {
    addTransactionBetweenMerchantAndGodmode(input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;

export const updateTransactionGQL = gql`
  mutation updateTransactionBetweenMerchantAndGodmode(
    $id: ID!
    $input: UpdateTransactionBetweenMerchantAndGodmodeInput!
  ) {
    updateTransactionBetweenMerchantAndGodmode(id: $id, input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;
