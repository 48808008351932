import CardMembershipIcon from "@material-ui/icons/CardMembership";
import SubscriptionPackagesPage from "./pages/SubscriptionPackagesPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/subscription-packages",
    MainComponent: SubscriptionPackagesPage,
    navigationItemLabel: "Subscription Packages",
    showShopSelector: false,
    godmodeOnly: true,
    collapse: {
      title: "Subscriptions",
      IconComponent: CardMembershipIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-packages/create",
    MainComponent: SubscriptionPackagesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-packages/edit/:Id",
    MainComponent: SubscriptionPackagesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
