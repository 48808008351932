import React from "react";
import { purchases } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";

const ORDER_STATUS = {
  new: "NEW",
  "coreOrderWorkflow/created": "CREATED",
  "coreOrderWorkflow/processing": "PROCESSING",
  "coreOrderWorkflow/completed": "COMPLETED",
  "coreOrderWorkflow/canceled": "CANCELED",
  "coreOrderWorkflow/picked": "PICKED",
  "coreOrderWorkflow/packed": "PACKED",
  "coreOrderWorkflow/labeled": "LABELED",
  "coreOrderWorkflow/shipped": "SHIPPED",
};

const PurchasesViewer = ({ shopperId }) => {
  const allFields = [
    {
      type: "string",
      name: "_id",
      label: "ID",
      isSortable: true,
    },
    {
      type: "string",
      name: "merchantId",
      label: "Merchant ID",
    },
    {
      type: "string",
      name: "merchantName",
      label: "Merchant Name",
    },
    {
      type: "string",
      name: "merchantName",
      label: "Merchant Name",
    },
    {
      type: "string",
      name: "shopId",
      label: "Shop ID",
    },
    {
      type: "string",
      name: "shopName",
      label: "Shop Name",
    },
    {
      type: "string",
      name: "status",
      label: "Status",
    },
    {
      type: "string",
      name: "amount",
      label: "Amount",
    },
    {
      type: "dateTime",
      name: "createdAt",
      label: "Purchase Date",
    },
  ];

  const displayFields = [
    "_id",
    "merchantId",
    "merchantName",
    "shopId",
    "shopName",
    "status",
    "amount",
    "createdAt",
  ];

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CRUDer
        crud={{ state: "display" }}
        handlers={{
          goToUpdatePage: (id, row) => {
            const win = window.open(`/orders/${row.shop._id}/${id}`, "_blank");
            win.focus();
          },
        }}
        fields={{
          display: allFields.filter(({ name }) => displayFields.includes(name)),
        }}
        apis={{
          readAll: {
            gql: purchases,
            formatInput: () => ({ id: shopperId }),
            formatOutput: output => ({
              ...output.ordersByShopper,
              nodes: output.ordersByShopper.nodes.map(order => ({
                ...order,
                _id: order.referenceId,
                status: ORDER_STATUS[order.status],
                merchantName: order.shop.company.name,
                merchantId: order.shop.company._id,
                shopId: order.shop._id,
                shopName: order.shop.name,
                amount: order.payments[0].amount.amount,
              })),
            }),
          },
          actions: [],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

export default function(props) {
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <PurchasesViewer {...props} />
    </MuiThemeProvider>
  );
}
