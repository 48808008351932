import gql from "graphql-tag";

export const gdprQuery = gql`
  query gdpr {
    gdpr {
      terms
      createdAt
      updatedAt
    }
  }
`;
