import CardMembershipIcon from "@material-ui/icons/CardMembership";
import SubscriptionTypesPage from "./pages/SubscriptionTypesPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/subscription-types",
    MainComponent: SubscriptionTypesPage,
    navigationItemLabel: "Subscription Types",
    showShopSelector: false,
    godmodeOnly: true,
    collapse: {
      title: "Subscriptions",
      IconComponent: CardMembershipIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-types/create",
    MainComponent: SubscriptionTypesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-types/edit/:subscriptionTypeId",
    MainComponent: SubscriptionTypesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
