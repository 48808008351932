import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Box,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import SimpleSchema from "simpl-schema";
import muiOptions from "reacto-form/cjs/muiOptions";
import CountryOptions from "@reactioncommerce/api-utils/lib/CountryOptions.js";
import { Select, Button, TextField } from "@reactioncommerce/catalyst";
import useProduct from "../hooks/useProduct";
import ColorPicker from "../../../package/src/ColorPicker.js";
import { useSnackbar } from "notistack";
import rgb2hex from "rgb2hex";
import hexrgb from "hex-rgb";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(4),
    zIndex: 0,
  },
}));

const formSchema = new SimpleSchema({
  title: {
    type: String,
    optional: true,
  },
  optionTitle: {
    type: String,
    optional: true,
  },
  otherAttributeLabel: {
    type: String,
    optional: true,
  },
  sku: {
    type: String,
    optional: true,
  },
  originCountry: {
    type: String,
    optional: true,
  },
  length: {
    type: Number,
    optional: true,
  },
  width: {
    type: Number,
    optional: true,
  },
  height: {
    type: Number,
    optional: true,
  },
  weight: {
    type: Number,
    optional: true,
  },
});

const validator = formSchema.getFormValidator();

const sizeAttributeLabel = { value: "size", label: "Size" };
const colorAttributeLabel = { value: "color", label: "Color" };
const otherAttributeLabel = { value: "other", label: "Other" };

const attributeLabelOptions = [sizeAttributeLabel, colorAttributeLabel, otherAttributeLabel];

/**
 * @name VariantDetailForm
 * @param {Object} props Component props
 * @param {Object} ref Forwarded ref
 * @returns {React.Component} Variant form React component
 */
const VariantDetailForm = React.forwardRef((props, ref) => {
  const { refreshToolbar } = props;
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [color, setColor] = useState(null);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { onUpdateProductVariant, product, variant, option, isLoading } = useProduct();

  const editingVariant = option || variant;

  const isOtherAttributeLabel = attributeLabel => {
    return !(attributeLabel.charAt(0) === "#" || attributeLabel === "size");
  };

  const getOtherAttributeLabel = attributeLabel => {
    if (!attributeLabel) return "";

    return isOtherAttributeLabel(attributeLabel) ? attributeLabel : "";
  };

  const getAttributeLabel = attributeLabel => {
    if (attributeLabel === "size") {
      return sizeAttributeLabel;
    }

    if (attributeLabel === "color") {
      return colorAttributeLabel;
    }

    if (attributeLabel) {
      return otherAttributeLabel;
    }

    return null;
  };

  const getAttributeLabelColor = editingVariant => {
    if (editingVariant?.attributeLabel === "color") {
      try {
        const rgb = hexrgb(editingVariant?.optionTitle);

        return {
          r: rgb.red,
          g: rgb.green,
          b: rgb.blue,
        };
      } catch (ex) {
        return { r: 0, g: 0, b: 0 };
      }
    }

    return { r: 0, g: 0, b: 0 };
  };

  // The following functions will return the active state or if its not set yet, the default value coming from the
  // database. Why wouldn't we set the state to the default value? Because that would send us into an inifite loop where
  // when the state changes, useProduct hook will rerun and hence fetch the product again and that means setting the
  // state again and we go into an infinite loop.
  const getSelectedAttributeOrInitialValue = () =>
    selectedAttribute || getAttributeLabel(editingVariant?.attributeLabel);
  const getColorOrInitialValue = () => color || getAttributeLabelColor(editingVariant);

  const { getFirstErrorMessage, getInputProps, hasErrors, isDirty, submitForm } = useReactoForm({
    async onSubmit(formData) {
      if (
        getSelectedAttributeOrInitialValue()?.value === "color" &&
        Object.keys(getColorOrInitialValue()).length === 0
      ) {
        return enqueueSnackbar("You need to select a color first", {
          variant: "error",
        });
      }

      const formValues = { ...formSchema.clean(formData) };

      switch (getSelectedAttributeOrInitialValue()?.value) {
        case "color":
          formValues.attributeLabel = "color";
          formValues.optionTitle = rgb2hex(
            `rgb(${getColorOrInitialValue().r},${getColorOrInitialValue().g},${
              getColorOrInitialValue().b
            })`
          ).hex;

          break;

        case "size":
          formValues.attributeLabel = "size";

          break;

        case "other":
          const otherAttributeLabel =
            formValues.otherAttributeLabel ||
            getOtherAttributeLabel(editingVariant?.attributeLabel);

          if (!otherAttributeLabel) {
            return enqueueSnackbar("You must set the Other Attribute Label", {
              variant: "error",
            });
          }

          formValues.attributeLabel = otherAttributeLabel;

          if (formValues?.otherAttributeLabel) {
            delete formValues.otherAttributeLabel;
          }

          break;

        default:
          return enqueueSnackbar("You must set an Attribute Label", {
            variant: "error",
          });
      }

      setIsSubmitting(true);

      await onUpdateProductVariant({
        variantId: editingVariant._id,
        variant: formValues,
      });

      refreshToolbar();

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
    value: editingVariant,
  });

  const originCountryInputProps = getInputProps("originCountry", muiOptions);

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={t("admin.productAdmin.details")} />
      <CardContent>
        <form
          onSubmit={event => {
            event.preventDefault();
            submitForm();
          }}
        >
          <div
            style={{
              marginBottom: getSelectedAttributeOrInitialValue()?.value === "other" ? null : "32px",
            }}
          >
            <div style={{ paddingBottom: "8px" }}>Attribute Label</div>
            <Select
              fullWidth
              options={attributeLabelOptions}
              onSelection={setSelectedAttribute}
              placeholder={"Choose an attribute "}
              value={getSelectedAttributeOrInitialValue()}
            />

            {getSelectedAttributeOrInitialValue()?.value === "other" ? (
              <>
                <div style={{ paddingBottom: "8px", paddingTop: "8px" }}>Label Name</div>
                <TextField
                  className={classes.textField}
                  error={hasErrors(["otherAttributeLabel"])}
                  fullWidth
                  helperText={getFirstErrorMessage(["otherAttributeLabel"]) || ""}
                  placeholder={"Example: Weight"}
                  {...getInputProps("otherAttributeLabel", muiOptions)}
                  value={
                    getInputProps("otherAttributeLabel", muiOptions).value ||
                    getOtherAttributeLabel(editingVariant?.attributeLabel)
                  }
                />
              </>
            ) : (
              ""
            )}

            {getSelectedAttributeOrInitialValue()?.value === "color" ? (
              <>
                <div style={{ paddingBottom: "8px", paddingTop: "8px" }}>Color</div>
                <ColorPicker initialColor={getColorOrInitialValue()} setColor={setColor} />
              </>
            ) : (
              ""
            )}
          </div>

          {getSelectedAttributeOrInitialValue()?.value !== "color" ? (
            <TextField
              className={classes.textField}
              error={hasErrors(["optionTitle"])}
              fullWidth
              helperText={getFirstErrorMessage(["optionTitle"]) || t("admin.helpText.optionTitle")}
              label={t("productVariant.optionTitle")}
              placeholder={t("admin.productVariant.optionTitlePlaceholder")}
              {...getInputProps("optionTitle", muiOptions)}
            />
          ) : (
            ""
          )}

          <TextField
            className={classes.textField}
            error={hasErrors(["title"])}
            fullWidth
            helperText={getFirstErrorMessage(["title"]) || t("admin.helpText.title")}
            label={"Full Title"}
            placeholder={t("admin.productVariant.titlePlaceholder")}
            {...getInputProps("title", muiOptions)}
          />

          <TextField
            className={classes.textField}
            error={hasErrors(["sku"])}
            fullWidth
            helperText={getFirstErrorMessage(["sku"]) || "Stock-Keeping Unit number of the variant"}
            label={"SKU"}
            placeholder={"Example: 8120725247"}
            {...getInputProps("sku", muiOptions)}
          />

          <Box paddingBottom={4}>
            <Divider />
          </Box>

          <TextField
            className={classes.textField}
            error={hasErrors(["originCountry"])}
            fullWidth
            helperText={getFirstErrorMessage(["originCountry"])}
            label={t("productDetailEdit.originCountry")}
            onKeyPress={event => {
              if (event.key === "Enter") submitForm();
            }}
            select
            {...originCountryInputProps}
            value={originCountryInputProps.value || ""}
          >
            {CountryOptions.map(countryOption => (
              <MenuItem key={countryOption.value} value={countryOption.value}>
                {countryOption.label}
              </MenuItem>
            ))}
          </TextField>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["width"])}
                fullWidth
                helperText={getFirstErrorMessage(["width"])}
                label={t("productVariant.width")}
                placeholder="0"
                {...getInputProps("width", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["length"])}
                fullWidth
                helperText={getFirstErrorMessage(["length"])}
                label={t("productVariant.length")}
                placeholder="0"
                {...getInputProps("length", muiOptions)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["height"])}
                fullWidth
                helperText={getFirstErrorMessage(["height"])}
                label={t("productVariant.height")}
                placeholder="0"
                {...getInputProps("height", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["weight"])}
                fullWidth
                helperText={getFirstErrorMessage(["v"])}
                label={t("productVariant.weight")}
                placeholder="0"
                {...getInputProps("weight", muiOptions)}
              />
            </Grid>
          </Grid>

          <Box textAlign="right">
            <Button
              color="primary"
              disabled={!product || (!isDirty && selectedAttribute === "") || isSubmitting}
              isWaiting={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t("app.saveChanges")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
});

export default VariantDetailForm;
