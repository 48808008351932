import RatesTable from "../components/RatesTable";
import Toolbar from "../../../package/src/Toolbar";

export default function DiscountsPage(props) {
  const shopId = props.selectedShopIds[0];

  return (
    <>
      <Toolbar title="Sale" />
      <RatesTable {...props} shopId={shopId} />
    </>
  );
}
