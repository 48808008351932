import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardContent, Box, IconButton } from "@material-ui/core";
import PlusIcon from "mdi-material-ui/Plus";
import Chip from "@reactioncommerce/catalyst/Chip";
import useProduct from "../hooks/useProduct";
import TagSelectorDialog from "../components/TagSelector/TagSelectorDialog";

/**
 * Tag form block component
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
function ProductTagForm({ refreshToolbar }) {
  const { handleDeleteProductTag, product, refetchProduct } = useProduct();
  const [isTagDialogOpen, setTagDialogOpen] = useState(false);
  const { t } = useTranslation();

  let content;

  const onDeleteTag = useCallback(
    async tag => {
      await handleDeleteProductTag({ tag });

      refreshToolbar();
    },
    [handleDeleteProductTag]
  );

  if (product && Array.isArray(product.tags.nodes)) {
    content = product.tags.nodes.map(tag => (
      <Box key={tag._id}>
        <Chip
          color="primary"
          label={tag.name}
          onDelete={() => {
            onDeleteTag(tag);
          }}
          style={{ marginRight: "4px" }}
        />
      </Box>
    ));
  }

  return (
    <Card style={{ overflow: "visible" }}>
      <CardHeader
        action={
          <IconButton onClick={() => setTagDialogOpen(true)} title="Add tags">
            <PlusIcon />
          </IconButton>
        }
        title={t("productDetail.tags")}
      />
      <CardContent>
        <Box display="flex">{content}</Box>
      </CardContent>
      {product && (
        <TagSelectorDialog
          refreshToolbar={refreshToolbar}
          isOpen={isTagDialogOpen}
          onSuccess={() => {
            refetchProduct();
          }}
          onClose={() => setTagDialogOpen(false)}
          productIds={[product._id]}
          shopIds={[product.shop._id]}
        />
      )}
    </Card>
  );
}

export default ProductTagForm;
