import gql from "graphql-tag";
import { SubscriptionPackage } from "./fragments";

export const all = gql`
query subscriptionPackages($first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: SubscriptionPackageSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput) {
  subscriptionPackages(first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ${SubscriptionPackage}
    }
    totalCount
  }
}
`;

export const one = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      ${SubscriptionPackage}
    }
  }
`;

export const subscriptionPackageOverrides = gql`
  query subscriptionPackageOverrides($id: ID!) {
    subscriptionPackageOverrides(id: $id) {
      baseRate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      additionalRatePerStandardShop {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      additionalRatePerPremiumShop {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;

export const types = gql`
  query subscriptionTypes {
    subscriptionTypes {
      nodes {
        _id
        name
      }
    }
  }
`;
