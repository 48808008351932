import React, { useState } from "react";
import { activityHistoryRecordsQuery } from "../graphql/queries";
import CRUDer from "../../../package/src/CRUDer";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "@reactioncommerce/catalyst";
import Toolbar from "../../../package/src/Toolbar";

const camelToSpaceCase = str => str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
const jsonFormat = str => JSON.stringify(JSON.parse(str), null, 2); // spacing level = 2

const ActivityHistoryPage = ({ viewer }) => {
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: viewer.type !== "godmode" ? [viewer.companyId] : [],
    selectedShopIds:
      viewer.type !== "godmode"
        ? [
            ...viewer.adminUIShops
              .filter(shop => shop.company._id === viewer.companyId)
              .map(shop => shop._id),
          ]
        : [],
  });

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Toolbar title="Activity History" />

      <MerchantSelectorWithData
        shouldShowShopName
        size={32}
        viewer={viewer}
        selectedCompanyIds={selectedMerchants.selectedCompanyIds}
        onMerchantSelectionChange={setSelectedMerchants}
      />

      <br />

      <CRUDer
        crud={{ state: "display" }}
        handlers={{}}
        strings={{
          displayer: {
            filterPlaceholder: "Filter by Activity name",
          },
        }}
        fields={{
          display: [
            { type: "dateTime", name: "createdAt", label: "Datetime", isSortable: true },
            { type: "string", name: "operationName", label: "Activity", isSortable: true },
            { type: "string", name: "company", label: "Merchant", isSortable: "companyId" },
            { type: "string", name: "account", label: "User", isSortable: "accountId" },
            { type: "string", name: "shop", label: "Shop", isSortable: "shopId" },
            { type: "textarea", name: "variables", label: "Request Variables" },
            { type: "textarea", name: "query", label: "Request Query" },
          ],
        }}
        apis={{
          readAll: {
            gql: activityHistoryRecordsQuery,
            formatInput: () => ({ companyIds: selectedMerchants.selectedCompanyIds }),
            formatOutput: output => ({
              nodes: output.activityHistoryRecords.nodes.map(node => ({
                ...node,
                operationName: camelToSpaceCase(node?.operationName),
                company: node?.company?.name,
                account: `${node?.account?.primaryEmailAddress} - ${node?.account?.name}`,
                shop: node?.shop?.name || "",
                variables: jsonFormat(node?.variables),
              })),
              totalCount: output.activityHistoryRecords.totalCount,
            }),
          },
          actions: [],
        }}
      ></CRUDer>
    </MuiThemeProvider>
  );
};

export default ActivityHistoryPage;
