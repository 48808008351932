import React from "react";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ProductMediaGallery from "../components/ProductMediaGallery";
import useProduct from "../hooks/useProduct";
import Typography from "@material-ui/core/Typography";

/**
 * Product media form block component
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
function ProductMediaForm({ refreshToolbar }) {
  const { product, shopIds } = useProduct();
  const { t } = useTranslation();

  if (!product) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={t("admin.productAdmin.mediaGallery")} />
      <CardContent>
        <Typography variant="body2" component="p">
          You can control the order in which the below images appear for shoppers. The lower the
          number, the higher the priority. The image with the lowest order will be treated as the
          featured image.
          <br />
          <span>
            <b>Note:</b> Variant images also appear here.
          </span>
        </Typography>
        <ProductMediaGallery
          editable={true}
          media={product.media}
          productId={product._id}
          shopIds={shopIds}
          refreshToolbar={refreshToolbar}
        />
      </CardContent>
    </Card>
  );
}

export default ProductMediaForm;
