import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import { TextField, Button } from "@reactioncommerce/catalyst";
import { useSnackbar } from "notistack";
import splitOrderItemMutation from "../graphql/mutations/splitOrderItem.js";

const styles = theme => ({
  extraEmphasisText: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  editableItem: {
    display: "flex",
  },
  itemQtyAction: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
    fontSize: "15px",
  },
  itemQtyValue: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
  itemQtyTextField: {
    width: 50,
  },
});

const OrderCardFulfillmentGroupItem = ({ item, order, classes }) => {
  const { price, productVendor, quantity, subtotal, title, variantTitle } = item;

  const { enqueueSnackbar } = useSnackbar();
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [splitOrderItem] = useMutation(splitOrderItemMutation, {
    onCompleted(message) {
      enqueueSnackbar("Successfully decreased quantity", { variant: "success" });
      order.hydrate(); // will cause a refetch of order and a re-render
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  });

  const save = async () => {
    setIsSaving(true);
    await splitOrderItem({
      variables: {
        input: {
          itemId: item._id,
          newItemQuantity: parseInt(itemQuantity),
          orderId: order._id,
        },
      },
    });
    setIsSaving(false);
    setEditMode(false);
  };

  return (
    <Grid container>
      <Grid item xs={6} md={6}>
        <Grid item xs={12} md={12}>
          <Typography paragraph variant="h4">
            {title}
          </Typography>
          <Typography variant="body2">{productVendor}</Typography>
          <Typography variant="body2">{variantTitle}</Typography>
          <div className={classes.editableItem}>
            <Typography variant="body2">Quantity:</Typography>
            {editMode ? (
              <div className={classes.itemQtyValue}>
                <TextField
                  className={classes.itemQtyTextField}
                  type="number"
                  placeholder="New Quantity"
                  value={itemQuantity}
                  onChange={e => setItemQuantity(e.target.value)}
                />
                <Button
                  className={classes.itemQtyAction}
                  isWaiting={isSaving}
                  variant="contained"
                  color="primary"
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className={classes.itemQtyValue}>
                <div>{itemQuantity}</div>
                <CreateIcon className={classes.itemQtyAction} onClick={() => setEditMode(true)} />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}>
        <Grid item xs={12} md={12}>
          <Typography paragraph variant="h4" align="right">
            {price.displayAmount}
          </Typography>
          <Typography variant="body1" align="right">
            Total({quantity}):&nbsp;{" "}
            <span className={classes.extraEmphasisText}>{subtotal.displayAmount}</span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { name: "OrderCardFulfillmentGroupItem" })(
  OrderCardFulfillmentGroupItem
);
