import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { makeStyles } from "@material-ui/core";
import TextField from "@reactioncommerce/catalyst/TextField";
import Button from "@reactioncommerce/catalyst/Button";
import Popover from "@material-ui/core/Popover";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  selectionPopup: {
    display: "inline-grid",
  },
}));

/**
 * @name DateRangeSelector
 * @param {dateRange.startDate} Date (NOT STRING)
 * @param {dateRange.endDate} Date (NOT STRING)
 */
const DateRangeSelector = ({ dateRange, onChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setDateRangeState([
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        key: "selection",
      },
    ]);
  }, [dateRange]);

  const id = isOpen ? "simple-popover" : undefined;
  const startDateReadable = dateRangeState[0]?.startDate
    ? moment(dateRangeState[0].startDate).format("MMMM Do YYYY")
    : "Start of time";
  const endDateReadable = dateRangeState[0]?.endDate
    ? moment(dateRangeState[0].endDate).format("MMMM Do YYYY")
    : "End of time";

  return (
    <>
      <TextField
        onClick={e => {
          setIsOpen(true);
          setAnchorEl(e.currentTarget);
        }}
        onChange={e => e.preventDefault()}
        placeholder="Date Range"
        value={`[${startDateReadable}] to [${endDateReadable}]`}
      />
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setIsOpen(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRange
          editableDateInputs={true}
          onChange={item => {
            // For the time-being, we are assuming that the endDate ends at the
            // end of the selected day
            item.selection.endDate = moment(item.selection.endDate)
              .add(23, "h")
              .add(59, "m")
              .add(59, "s")
              .toDate();

            setDateRangeState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          ranges={dateRangeState}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsOpen(false);
            onChange({
              startDate: dateRangeState[0].startDate,
              endDate: dateRangeState[0].endDate,
            });
          }}
        >
          Apply
        </Button>
      </Popover>
    </>
  );
};

DateRangeSelector.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.date,
    endDate: PropTypes.date,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRangeSelector;
