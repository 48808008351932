import React from "react";
import DiscountCodesTable from "../components/DiscountCodesTable";
import Toolbar from "../../../package/src/Toolbar";
import { constants } from "../constants.js";

const { couponCodePageTitle } = constants;

/**
 * @summary Renders discounts page
 * @param {Object} props Component props
 * @return {React.Node} React node
 */
export default function DiscountCodesPage(props) {
  const shopId = props.selectedShopIds[0];

  return (
    <>
      <Toolbar title={couponCodePageTitle} />
      <DiscountCodesTable {...props} shopId={shopId} />
    </>
  );
}
