import React, { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  Fab,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CloseIcon from "mdi-material-ui/Close";
import { useTranslation } from "react-i18next";
import FolderIcon from "mdi-material-ui/Folder";
import Badge from "@material-ui/core/Badge";
import ShopLogo from "../ShopLogo";
import UIContext from "../context/UIContext";

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.darkBlue500,
    "&:hover": {
      backgroundColor: theme.palette.colors.darkBlue600,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.colors.darkBlue500,
      },
    },
  },
  icon: {
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    marginRight: theme.spacing(2),
    color: theme.palette.colors.coolGrey300,
  },
  iconActive: {
    color: theme.palette.text.active,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listItemRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&$focusVisible": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.colors.darkBlue600,
    },
    "&$selected, &$selected:hover": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.colors.darkBlue600,
    },
    "&$selected $icon, &$selected:hover $icon": {
      color: theme.palette.text.active,
    },
  },
  listItemText: {
    paddingLeft: 0,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.5,
    letterSpacing: 0.5,
    color: theme.palette.colors.black15,
  },
  listItemButton: {
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.colors.darkBlue600,
    },
  },
  /* Pseudo-class applied to the `component`'s `focusVisibleClassName` prop if `button={true}`. */
  focusVisible: {},
  /* Pseudo-class applied to the root element if `selected={true}`. */
  selected: {},
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    paddingTop: "3px",
    paddingBottom: "3px",
    // TODO eventually figure out what theme color to use for this
    backgroundColor: "#3C4950",
  },
}));

/**
 * Navigation Drawer component
 * @returns {React.Component} NavigationDrawer component
 */
function NavigationDrawer({ routes: primaryRoutes, viewer }) {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch = useRouteMatch("/:any");
  const { t } = useTranslation();
  const { isMobile, isNavigationDrawerOpen, onCloseNavigationDrawer } = useContext(UIContext);
  const [collapsesOpen, setCollapsesOpen] = useState([]);

  let drawerProps = {
    classes: {
      paper: classes.drawerPaper,
    },
    open: true,
    variant: "persistent",
  };

  if (isMobile) {
    drawerProps = {
      variant: "temporary",
      anchor: "left",
      open: isNavigationDrawerOpen,
      onClose: onCloseNavigationDrawer,
      ModalProps: {
        keepMounted: true, // Better open performance on mobile.
      },
    };
  }

  const SimpleListItem = ({
    path,
    href,
    badgeContent,
    IconComponent,
    navigationItemLabel,
    isNested = false,
  }) => (
    <ListItem
      button
      className={isNested ? classes.nestedListItem : undefined}
      classes={{
        root: classes.listItemRoot,
        selected: classes.selected,
        button: classes.listItemButton,
      }}
      key={path}
      selected={
        routeMatch && ((href && href.startsWith(routeMatch.url)) || path.startsWith(routeMatch.url))
      }
      onClick={() => {
        history.push(href || path);
        onCloseNavigationDrawer();
      }}
    >
      <Badge badgeContent={badgeContent} color="primary">
        {IconComponent && (
          <ListItemIcon className={classes.icon}>
            <IconComponent />
          </ListItemIcon>
        )}
        <ListItemText disableTypography className={classes.listItemText}>
          {t(navigationItemLabel)}
        </ListItemText>
      </Badge>
    </ListItem>
  );

  const ComplexListItem = ({ label, IconComponent, simpleListItems, open, onCollapseClick }) => (
    <>
      <ListItem
        button
        className={classes.listItem}
        classes={{
          root: classes.listItemRoot,
          selected: classes.selected,
          button: classes.listItemButton,
        }}
        onClick={() => onCollapseClick(!open)}
      >
        <ListItemIcon className={classes.icon}>
          {(IconComponent && <IconComponent />) || <FolderIcon />}
        </ListItemIcon>
        <ListItemText disableTypography className={classes.listItemText}>
          {label}
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {simpleListItems}
        </List>
      </Collapse>
    </>
  );

  // Form the list item that will be rendered. Some will be
  // simple buttons (i.e. <SimpleListItem/>),
  // others will be a collapse containing multiple buttons (
  // i.e. A <ComplexListItem> which contains multiple <SimpleListItem/>).
  const listItemsToRender = [];
  const alreadyRenderedCollapses = [];

  for (let route of primaryRoutes) {
    const { IconComponent, href, path, navigationItemLabel, collapse = null } = route;

    // If the route specifies "route.badge", we read viewer[route.badge]
    // to determine the value to place inside the Badge
    let badgeContent = 0;
    if (route?.badge) {
      badgeContent = route.badge in viewer ? viewer[route.badge] : 0;
    }

    let numberOfRoutesUnderSameCollapse = 0;
    if (route?.collapse?.title) {
      numberOfRoutesUnderSameCollapse = primaryRoutes.filter(
        el => el?.collapse?.title === route.collapse.title
      ).length;
    }

    // Check if this route has a `collapse` property AND there are more than 1
    // route with this `collapse.title`.
    // If so -> group all routes with the same collapse.title together
    // in a <Collapse>.
    // If not -> render it as a simple <ListItem>.
    if (!collapse || numberOfRoutesUnderSameCollapse === 1) {
      listItemsToRender.push(
        <SimpleListItem
          key={path}
          path={path}
          href={href}
          badgeContent={badgeContent}
          IconComponent={IconComponent}
          navigationItemLabel={navigationItemLabel}
        />
      );
    } else {
      // If this collapse was already tackled and rendered (i.e. this item
      // was already rendered within this collapse) --> skip it
      if (!alreadyRenderedCollapses.includes(collapse.title)) {
        // Add the collapse we just rendered so we don't re-render it again
        alreadyRenderedCollapses.push(collapse.title);

        const subListItemsToRender = [];

        for (let subRoute of primaryRoutes) {
          let subRouteBadgeContent = 0;
          if (subRoute?.badge) {
            subRouteBadgeContent = subRoute.badge in viewer ? viewer[subRoute.badge] : 0;
          }

          if (subRoute?.collapse?.title === collapse.title) {
            subListItemsToRender.push(
              <SimpleListItem
                key={subRoute.path}
                path={subRoute.path}
                href={subRoute.href}
                badgeContent={subRouteBadgeContent}
                IconComponent={subRoute.IconComponent}
                navigationItemLabel={subRoute.navigationItemLabel}
                isNested={true}
              />
            );
          }
        }

        listItemsToRender.push(
          <ComplexListItem
            key={collapse.title}
            label={collapse.title}
            IconComponent={collapse.IconComponent}
            simpleListItems={subListItemsToRender}
            open={collapsesOpen[collapse.title]}
            onCollapseClick={open =>
              setCollapsesOpen(oldState => ({
                ...oldState,
                [collapse.title]: open,
              }))
            }
          />
        );
      }
    }
  }

  return (
    <Drawer {...drawerProps}>
      <AppBar color="secondary" elevation={0} position="sticky">
        <Toolbar className={classes.toolbar}>
          <Box flex={1} marginRight={2}>
            <ShopLogo className={classes.shopLogo} shouldShowShopName size={32} />
          </Box>

          <Hidden mdUp>
            <Fab
              classes={{ root: classes.closeButton }}
              onClick={onCloseNavigationDrawer}
              size="small"
            >
              <CloseIcon />
            </Fab>
          </Hidden>
        </Toolbar>
      </AppBar>
      <List disablePadding>{listItemsToRender}</List>
    </Drawer>
  );
}

export default NavigationDrawer;
